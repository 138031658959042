import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { sitesSlice } from './sites'
import { filtersSlice } from '@/storage/filters'
import { loadingSlice } from '@/storage/loading'
import { activitySlice } from '@/pages/Activity/slices/activities'
import { authSlice } from '@/storage/auth'
import { notificationsSlice } from '@/pages/Notifications/slices/notifications'
import { toastSlice } from '@/components/toast/store'
import { analyticsSlice } from '@/pages/Analytics/slices/analytics'
import { commentSlice } from '@/pages/GlobalReviewComments/slices/comments'
import { adminSlice } from '@/pages/Admin/slices/admin'
import { localeSlice } from '@/storage/locale'
import { exploreSlice } from '@/pages/Explore/slices/explore'

const reducer = combineReducers({
  form: formSlice.reducer,
  initiatives: initiativesSlice.reducer,
  sites: sitesSlice.reducer,
  filters: filtersSlice.reducer,
  loading: loadingSlice.reducer,
  activity: activitySlice.reducer,
  auth: authSlice.reducer,
  toast: toastSlice.reducer,
  notifications: notificationsSlice.reducer,
  analytics: analyticsSlice.reducer,
  comments: commentSlice.reducer,
  admin: adminSlice.reducer,
  locale: localeSlice.reducer,
  explore: exploreSlice.reducer
})
export const store = configureStore({
  reducer
})

export type RootState = ReturnType<typeof store.getState>
