import React from 'react'
import { Form, Formik } from 'formik'
import Button from '@ingka/button'

import { ReportForm } from './ReportForm'
import styles from '../styles/NewReport.module.scss'
import { FormProvider } from '@/pages/ReportForm/providers/FormProvider'
import { DetailsModal } from '@/pages/ReportForm/components/DetailsModal'
import { DraftReportSchema, NewReportSchema } from '@/pages/ReportForm/utils/validation'
import { submitData } from '@/pages/ReportForm/utils/submit'
import { InitiativeFormType } from '@/types/initiative'
import { SUBMIT_TYPES } from '@/pages/ReportForm/constants/types'
import { useNavigate } from 'react-router-dom'
import { useHeaderSettings } from '@/components/header/provider'
import { initialValues } from '@/pages/ReportForm/utils/mappers'
import { useCanAccessPage } from '@/pages/ReportForm/utils/conditions'
import { ConfirmDelete } from '@/pages/ReportForm/components/Fields/ConfirmDelete'
import { DynamicTranslations } from '@/components/translations/Translations'

export const NewReport: React.FC = () => {
  const [type, setType] = React.useState<string>(SUBMIT_TYPES[0])
  const navigate = useNavigate()

  useHeaderSettings(false)
  useCanAccessPage()

  const afterSubmit = () => {
    navigate('/')
  }

  return (
    <FormProvider>
      <div className={styles.NewReportWrapper}>
        <div className={styles.FormBackground} />
        <div className={styles.FormWrapper}>
          <Formik<InitiativeFormType>
            initialValues={initialValues}
            validationSchema={type === SUBMIT_TYPES[0] ? DraftReportSchema : NewReportSchema}
            onSubmit={submitData(type, afterSubmit)}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form>
                <ReportForm />
                <div className={styles.SaveButtons}>
                  <Button
                    disabled={isSubmitting}
                    onClick={e => {
                      setType(SUBMIT_TYPES[0])
                      setTimeout(() => handleSubmit(e), 0)
                    }}
                  >
                    <DynamicTranslations id="form.save_draft" />
                  </Button>
                  <Button
                    data-testid="submit_form"
                    disabled={isSubmitting}
                    type="primary"
                    onClick={e => {
                      setType(SUBMIT_TYPES[1])
                      setTimeout(() => handleSubmit(e), 0)
                    }}
                  >
                    <DynamicTranslations id="form.submit_initiative" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DetailsModal />
      <ConfirmDelete />
    </FormProvider>
  )
}
