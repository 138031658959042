import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserData } from '@/types/user'

type StoreType = {
  user: UserData | null
  error: string | null
}

const initialState = {
  user: null,
  error: null
} as StoreType

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserData>) {
      state.user = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    reset() {
      return initialState
    }
  }
})
