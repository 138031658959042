import { UserData } from '@/types/user'
import { UsersFiltersType } from '@/pages/Admin/slices/admin'
import { ALL_OPTION } from '@/constants/common'

export const searchUsers = (data: UserData[], filters: UsersFiltersType): UserData[] => {
  if (!filters.search && filters.country.value === ALL_OPTION.value && filters.role.value === ALL_OPTION.value)
    return data

  return data.filter(item => {
    const search = !filters.search || item.name.toLowerCase().search(filters.search.toLowerCase()) !== -1
    const country =
      filters.country.value === ALL_OPTION.value || item.country?.some(country => filters.country.value === country)
    const role = filters.role.value === ALL_OPTION.value || item.role === filters.role.value

    return search && country && role
  })
}
