import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { InitiativesStatusesType } from '@/types/initiative'

type StoreType = {
  initiatives: InitiativesListItemType[]
  details: InitiativesListItemType | null
  previewDetails: InitiativesListItemType | null
  search: string
  toRejectId: string | null
  toDeleteId: string | null
  toCommentId: string | null
  toRecallId: string | null
  commentWithApprove: boolean
  toChange: { name: string; value: string } | null
  initiativesLeftCount: number
}

const initialState = {
  initiatives: [],
  details: null,
  previewDetails: null,
  search: '',
  toRejectId: null,
  toDeleteId: null,
  toChange: null,
  toCommentId: null,
  toRecallId: null,
  commentWithApprove: false,
  initiativesLeftCount: 0
} as StoreType

export const initiativesSlice = createSlice({
  name: 'initiatives',
  initialState,
  reducers: {
    setInitiatives(state, action: PayloadAction<InitiativesListItemType[]>) {
      state.initiatives = action.payload
      if (state.previewDetails) {
        return
      }

      const details = state.initiatives.find(item => item.id === state.details?.id)

      if (!details) state.details = null
      else state.details = details

      if (!state.details) state.details = action.payload[0]
    },
    setInitiativeStatus(state, action: PayloadAction<{ id: string; status: InitiativesStatusesType }>) {
      if (state.details) state.details.status = action.payload.status
      const index = state.initiatives.findIndex(item => item.id === action.payload.id)

      if (index !== -1) state.initiatives[index].status = action.payload.status
    },
    changeDetails(state, action: PayloadAction<InitiativesListItemType | null>) {
      state.details = action.payload
      state.previewDetails = null
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload
    },
    setToReject(state, action: PayloadAction<string | null>) {
      state.toRejectId = action.payload
    },
    setPreviewDetails(state, action: PayloadAction<InitiativesListItemType | null>) {
      state.previewDetails = action.payload
      state.details = action.payload
    },
    setToDelete(state, action: PayloadAction<string | null>) {
      state.toDeleteId = action.payload
    },
    setToRecall(state, action: PayloadAction<string | null>) {
      state.toRecallId = action.payload
    },
    setToComment(state, action: PayloadAction<{ id: string; commentWithApprove: boolean } | null>) {
      if (!action.payload) {
        state.toCommentId = null
        state.commentWithApprove = false
      } else {
        state.toCommentId = action.payload.id
        state.commentWithApprove = action.payload.commentWithApprove
      }
    },
    setToChange(state, action: PayloadAction<{ name: string; value: string } | null>) {
      state.toChange = action.payload
    },
    setInitiativesLeftCount(state, action: PayloadAction<number>) {
      state.initiativesLeftCount = action.payload
    },
    reset() {
      return initialState
    }
  }
})
