import { store } from '@/storage/store'
import { setComment } from '@/pages/GlobalReviewComments/services'
import { FormikHelpers } from 'formik/dist/types'

export const submitComment = (values: { comment: string }, helpers: FormikHelpers<{ comment: string }>) => {
  const user_id = store.getState().auth.user?.id
  const initiativeId = store.getState().initiatives.details?.id

  if (!user_id || !initiativeId) return

  const data = {
    user_id,
    comment: values.comment,
    created_at: new Date()
  }

  setComment(initiativeId, data)
  helpers.resetForm()
}
