import React from 'react'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import Text from '@ingka/text'
import styles from '../../styles/Confirmation.module.scss'
import { Form, Formik } from 'formik'
import { TextArea } from '@/components/form/textarea/TextArea'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { handleRejectInitiative } from '@/pages/Initiatives/utils/review'
import { DynamicTranslations, useTranslation } from '@/components/translations/Translations'

export const Confirmation: React.FC<{ receiver: string; status: string }> = props => {
  const { receiver, status } = props

  const title = useTranslation('initiatives.confirmation.reject.title')
  const label = useTranslation('initiatives.confirmation.reject.label')

  const toRejectId = useSelector((state: RootState) => state.initiatives.toRejectId)
  const header = <ModalHeader ariaCloseTxt="Close confirmation" />

  const handleClose = () => store.dispatch(initiativesSlice.actions.setToReject(null))

  return (
    <Modal visible={Boolean(toRejectId)} handleCloseBtn={handleClose}>
      <Prompt className={styles.ConfirmationModal} title={title} footer={null} header={header}>
        <Text className={styles.ModalText} bodySize="l">
          By rejecting this initiative, the {receiver} will receive a notification with your comment and the status of
          the initiative will be changed to &quot;{status}&quot;. The {receiver} will have to fix the information in the
          initiative and resubmit it for review.
        </Text>
        <Formik initialValues={{ comment: '' }} onSubmit={handleRejectInitiative}>
          <Form>
            <TextArea label={label} name="comment" />
            <div className={styles.ButtonHolder}>
              <Button onClick={handleClose}>
                <DynamicTranslations id="initiatives.confirmation.reject.cancel" />
              </Button>
              <Button type="primary" htmlType="submit">
                <DynamicTranslations id="initiatives.confirmation.reject.reject" />
              </Button>
            </div>
          </Form>
        </Formik>
      </Prompt>
    </Modal>
  )
}
