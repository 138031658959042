import React from 'react'
import styles from '../styles/Admin.module.scss'
import { UsersSearch } from '@/pages/Admin/components/UsersSearch'
import { RootState, store } from '@/storage/store'
import { adminSlice } from '@/pages/Admin/slices/admin'
import { Form, Formik } from 'formik'
import { ALL_OPTION, USER_ROLES_OPTIONS } from '@/constants/common'
import { FilterField } from '@/components/header/FilterField'
import { useSelector } from 'react-redux'
import Button from '@ingka/button'

const initialValues = {
  search: '',
  country: ALL_OPTION,
  role: ALL_OPTION
}

export const Filters = () => {
  const countries = useSelector((state: RootState) => state.sites.countries)

  const handleClear = (reset: () => void) => () => {
    store.dispatch(adminSlice.actions.setFilters(initialValues))
    reset()
  }

  return (
    <div className={styles.Filters}>
      <Formik
        initialValues={initialValues}
        onSubmit={filters => {
          store.dispatch(adminSlice.actions.setFilters(filters))
        }}
      >
        {formProps => (
          <Form>
            <UsersSearch />
            <FilterField name="role" id="country_users" label="Role" list={USER_ROLES_OPTIONS} withLabel />
            <FilterField name="country" id="country_users" label="Country" list={countries} withLabel />
            <Button small text="Clear" onClick={handleClear(formProps.resetForm)} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
