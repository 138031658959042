import React from 'react'

import { AuthProvider } from './providers/AuthProvider'
import { Router } from './router/Router'

import '@ingka/text/style.scss'
import '@ingka/svg-icon/style.scss'
import '@ingka/button/style.scss'
import '@ingka/search/style.scss'
import '@ingka/focus/style.scss'
import '@ingka/forms/style.scss'
import '@ingka/helper-text/style.scss'
import '@ingka/modal/style.scss'
import '@ingka/carousel/style.scss'
import '@ingka/tabs/style.scss'
import '@ingka/loading/style.scss'
import '@ingka/tooltip/style.scss'
import '@ingka/toast/style.scss'
import '@ingka/hyperlink/style.scss'
import '@ingka/inline-message/style.scss'
import '@ingka/switch/style.scss'
import '@ingka/expander/dist/style.css'
import '@ingka/accordion/dist/style.css'

import { Provider } from 'react-redux'
import { store } from '@/storage/store'
import { ToastContainer } from '@/components/toast/ToastContainer'
import { NotificationsProvider } from '@/pages/Notifications/providers/NotificationsProvider'

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <NotificationsProvider />
        <ToastContainer />
        <Router />
      </AuthProvider>
    </Provider>
  )
}

export default App
