import React from 'react'
import Tabs, { Tab, TabPanel } from '@ingka/tabs'
import { InfoTab } from '@/pages/Initiatives/components/Details/InfoTab'
import styles from '../../styles/Initiatives.module.scss'
import { ActivityTab } from '@/pages/Activity/components/Activity'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { USER_ROLES } from '@/constants/common'
import { GlobalReviewComments } from '@/pages/GlobalReviewComments/components/GlobalReviewComments'
import { useTranslation } from '@/components/translations/Translations'

const TabsInfoImpl = () => {
  const activityCount = useSelector((state: RootState) => state.activity.list.length)
  const commentsCount = useSelector((state: RootState) => state.comments.list.length)
  const role = useSelector((state: RootState) => state.auth.user?.role)

  const infoTabLabel = useTranslation('initiatives.details.information')
  const activityTabLabel = useTranslation('initiatives.details.activity')

  const tabs = [
    <Tab key="info" tabPanelId="info" text={infoTabLabel} />,
    <Tab key="activity" tabPanelId="activity" text={`${activityTabLabel} (${activityCount})`} />
  ]

  if (role === USER_ROLES.globalManager)
    tabs.push(<Tab key="comments" tabPanelId="comments" text={`Global review comments (${commentsCount})`} />)

  const panels = [
    <TabPanel key="info" tabPanelId="info" className={styles.Panel}>
      <InfoTab />
    </TabPanel>,
    <TabPanel key="activity" tabPanelId="activity" className={styles.Panel}>
      <ActivityTab />
    </TabPanel>,
    <TabPanel key="comments" tabPanelId="comments" className={styles.Panel}>
      <GlobalReviewComments />
    </TabPanel>
  ]

  return <Tabs className={styles.Tabs} tabs={tabs} tabPanels={panels} />
}

export const TabsInfo = React.memo(TabsInfoImpl)
