import React from 'react'
import clx from 'classnames'

import styles from '../styles/Explore.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'
import { StatusTag } from '@/pages/Initiatives/components/Status'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { Loader } from '@/components/loader/Loader'
import { EXPLORE_LOADING } from '@/pages/Explore/constants/filters'
import { Show } from '@/components/conditions/Show'
import { InitiativesPlaceholder } from '@/components/placeholder/InitiativesPlaceholder'
import { getIngkaFinancialYear } from '@/utils/dates'
import { handleToggleDetails } from '@/pages/Explore/utils/details'

export const Table = () => {
  const initiatives = useSelector((state: RootState) => state.explore.initiatives)

  return (
    <div className={styles.Table}>
      <TableHead />
      <Loader name={EXPLORE_LOADING}>
        <div className={styles.TableBody}>
          <Show when={initiatives.length !== 0}>
            {initiatives.map(item => {
              return (
                <div key={`explore-${item.id}`} className={styles.TableRow}>
                  <div className={styles.TableRowCellName} onClick={handleToggleDetails(item)}>
                    {item.title}
                  </div>
                  <div>{item.type_of_initiative?.join(', ')}</div>
                  <div>{item.people_supported_count}</div>
                  <div>{item.spent}</div>
                  <div>{item.level}</div>
                  <div>{item.duration}</div>
                  <div className={styles.TableRowCellRight}>
                    <StatusTag className={styles.Status} status={item.status} />
                  </div>
                </div>
              )
            })}
          </Show>
          <InitiativesPlaceholder
            show={initiatives.length === 0}
            title="explore.placeholder.title"
            body="explore.placeholder.body"
          />
        </div>
      </Loader>
    </div>
  )
}

const TableHead = () => {
  const fyFilter = useSelector((state: RootState) => state.explore.filters.fy)

  const isOldData = React.useMemo(
    () => getIngkaFinancialYear(new Date(fyFilter?.value || '')).getFullYear() === 2024,
    [fyFilter]
  )

  return (
    <div className={styles.TableHead}>
      <div className={styles.TableHeadCell}>
        <DynamicTranslations id="explore.table.head.cell1" />
      </div>
      <div className={styles.TableHeadCell}>
        <DynamicTranslations id="explore.table.head.cell2" />
      </div>
      <div className={styles.TableHeadCell}>
        <DynamicTranslations id="explore.table.head.cell3" />
      </div>
      <Show when={isOldData}>
        <div className={styles.TableHeadCell}>
          <DynamicTranslations id="explore.table.head.cell4_old" />
        </div>
        <div className={styles.TableHeadCell}>
          <DynamicTranslations id="explore.table.head.cell5_old" />
        </div>
        <div className={styles.TableHeadCell}>
          <DynamicTranslations id="explore.table.head.cell6_old" />
        </div>
      </Show>
      <Show when={!isOldData}>
        <div className={styles.TableHeadCell}>
          <DynamicTranslations id="explore.table.head.cell4" />
        </div>
        <div className={styles.TableHeadCell}>
          <DynamicTranslations id="explore.table.head.cell5" />
        </div>
        <div className={styles.TableHeadCell}>
          <DynamicTranslations id="explore.table.head.cell6" />
        </div>
      </Show>
      <div className={clx(styles.TableHeadCell, styles.Right)}>
        <DynamicTranslations id="explore.table.head.cell7" />
      </div>
    </div>
  )
}
