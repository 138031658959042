import React from 'react'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import Text from '@ingka/text'
import styles from '../../styles/Confirmation.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { handleToggleRecallConfirmation } from '@/pages/Initiatives/utils/review'
import { handleRecall } from '@/pages/Initiatives/utils/review'
import { DynamicTranslations } from '@/components/translations/Translations'

export const RecallConfirmation: React.FC = () => {
  const toRecallId = useSelector((state: RootState) => state.initiatives.toRecallId)
  const header = <ModalHeader ariaCloseTxt="Close confirmation" />

  const footer = (
    <ModalFooter>
      <Button onClick={handleToggleRecallConfirmation(null)}>Cancel</Button>
      <Button type="primary" onClick={handleRecall}>
        <DynamicTranslations id="recall.confirmation.recall" />
      </Button>
    </ModalFooter>
  )

  return (
    <Modal visible={Boolean(toRecallId)} handleCloseBtn={handleToggleRecallConfirmation(null)}>
      <Prompt title={<DynamicTranslations id="recall.confirmation.title" />} footer={footer} header={header}>
        <Text className={styles.ModalText} bodySize="l">
          <DynamicTranslations id="recall.confirmation.body" />
        </Text>
      </Prompt>
    </Modal>
  )
}
