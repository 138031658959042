import { ActivityVariantsType } from '@/types/activity'
import { ImpactType, ImpactTypeFY24 } from '@/types/analytics'
import { LanguagesType } from '@/types/translations'

export const ALL_OPTION = {
  id: 'all',
  label: 'All',
  value: 'all'
}

export const FUNCTIONS_CODES = {
  centers: 'Ingka Centres',
  customerFulfillment: 'Customer Fulfillment',
  retail: 'Retail',
  supportUnits: 'Support units'
}

export const MANAGER_FUNCTION_CODES = [
  FUNCTIONS_CODES.customerFulfillment,
  FUNCTIONS_CODES.retail,
  FUNCTIONS_CODES.supportUnits
]

export const USER_ROLES = {
  countryManager: 'country_manager',
  user: 'user',
  globalManager: 'global_manager'
}

export const USER_ROLES_OPTIONS = [
  {
    id: USER_ROLES.user,
    label: 'Data Provider',
    value: USER_ROLES.user
  },
  {
    id: USER_ROLES.countryManager,
    label: 'Country Manager',
    value: USER_ROLES.countryManager
  },
  {
    id: USER_ROLES.globalManager,
    label: 'Global Manager',
    value: USER_ROLES.globalManager
  }
]

export const ACTIVITY_TYPES: { [key in string]: ActivityVariantsType } = {
  comment: 'comment',
  recall: 'recall',
  status: 'status',
  change: 'change'
}

export const NOTIFICATION_TYPES = {
  submitted: 'submitted',
  rejected: 'rejected',
  commented: 'commented',
  change: 'change'
}

export const IMPACT_TYPES_FY24: { [key in ImpactTypeFY24]: ImpactTypeFY24 } = {
  improve: 'improve',
  transform: 'transform',
  connect: 'connect'
}

export const IMPACT_TYPES: { [key in ImpactType]: ImpactType } = {
  strong: 'strong',
  light: 'light'
}

export const LANGUAGES_LIST: LanguagesType[] = ['en', 'de']

export const LANGUAGES_OPTIONS = [
  {
    id: `language-en`,
    label: 'English',
    value: 'en'
  },
  {
    id: 'language-de',
    label: 'Deutsch',
    value: 'de'
  }
]
