import React from 'react'
import Text from '@ingka/text'
import clx from 'classnames'

import { Option } from '@/types/common'
import styles from './List.module.scss'
import { Show } from '@/components/conditions/Show'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  options: Option[]
  onClick: (option: Option) => () => void
  className?: string
}

export const List: React.FC<Props> = props => {
  const { options, onClick, className } = props

  return (
    <div className={clx(styles.DropdownList, className)}>
      <div className={styles.DropdownListWrapper}>
        {options.map(item => (
          <div key={item.id} className={styles.Item} onClick={onClick(item)}>
            <Text bodySize="m">
              <DynamicTranslations id={item.label} />
            </Text>
          </div>
        ))}
        <Show when={options.length === 0}>
          <div className={styles.None}>
            <Text bodySize="m">
              <DynamicTranslations id="none" />
            </Text>
          </div>
        </Show>
      </div>
    </div>
  )
}
