import { changeSeenNotification } from '@/pages/Notifications/services'
import { store } from '@/storage/store'

export const checkAllAsSeen = () => {
  const list = store.getState().notifications.list
  Promise.all(
    list
      .filter(item => !item.seen)
      .map(async item => {
        return changeSeenNotification(item.id)
      })
  )
}
