import React from 'react'
import Button from '@ingka/button'
import Text from '@ingka/text'

import Logo from '@/images/INGKA_logo.png'

import styles from '../styles/NewReport.module.scss'
import { Link } from 'react-router-dom'
import { DynamicTranslations } from '@/components/translations/Translations'

export const FormHeadInfo: React.FC = () => {
  return (
    <>
      <Link to={'/'}>
        <img className={styles.Logo} src={Logo} alt="logo" />
      </Link>
      <Text headingSize="l" tagName="h1">
        <DynamicTranslations id="form.head.title" />
      </Text>
      <Text bodySize="xl">
        <DynamicTranslations id="form.head.sub_title1" /> <br />
        <DynamicTranslations id="form.head.sub_title2" /> <br />
        <DynamicTranslations id="form.head.sub_title3" />
      </Text>
      <div className={styles.GuideButtons}>
        <Button
          href="https://iweof.sharepoint.com/:b:/r/teams/o365g_retailsustainabilitymatrix_issemal/Shared%20Documents/General/04%20Fair%20%26%20Equal/2.%20Social%20Impact%20IKEA%20x%20Neighborhoods/4.%20Social%20Impact%20Performance/Guiding%20material/Guideline%20Reporting%20Social%20Impact%20in%20the%20local%20Neighborhoods%20latest.pdf?csf=1&web=1&e=NiXYhp"
          target="_blank"
        >
          <DynamicTranslations id="form.head.guideline" />
        </Button>
        <Button
          href="https://iweof.sharepoint.com/:b:/t/o365g_retailsustainabilitymatrix_issemal/EXr__6oS6YVOinTSa3001OgBFQtALZV0z3OfWWuOa7jHDQ?e=zrnDW4"
          target="_blank"
        >
          <DynamicTranslations id="form.head.quick_guideline" />
        </Button>
        <Button
          className={styles.Last}
          href="https://iweof.sharepoint.com/:b:/r/teams/o365g_retailsustainabilitymatrix_issemal/Shared%20Documents/General/04%20Fair%20%26%20Equal/2.%20Social%20Impact%20IKEA%20x%20Neighborhoods/4.%20Social%20Impact%20Performance/Guiding%20material/User%20Guide_Social%20Impact%20Reporting%20Tool.pdf?csf=1&web=1&e=Nwl59m"
          target="_blank"
        >
          <DynamicTranslations id="form.head.toolkit" />
        </Button>
      </div>
    </>
  )
}
