import React from 'react'
import { RootState } from '@/storage/store'
import { USER_ROLES } from '@/constants/common'
import { useSelector } from 'react-redux'
import { UserData } from '@/types/user'
import { useNavigate } from 'react-router-dom'

const canAccessPage = (user?: UserData) => {
  const role = user?.role

  return role === USER_ROLES.countryManager || role === USER_ROLES.user
}

export const useCanAccessPage = () => {
  const user = useSelector((state: RootState) => state.auth.user)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user) return

    if (!canAccessPage(user)) navigate('/')
  }, [])
}
