import React from 'react'
import { subscribeForComments } from '@/pages/GlobalReviewComments/services'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { Unsubscribe } from '@firebase/firestore'
import { mapToList } from '@/pages/GlobalReviewComments/utils/mapper'
import { commentSlice } from '@/pages/GlobalReviewComments/slices/comments'
import { Loader } from '@/components/loader/Loader'
import { loadingSlice } from '@/storage/loading'

export const CommentsProvider: React.FC<React.PropsWithChildren> = props => {
  const initiativeId = useSelector((state: RootState) => state.initiatives.details?.id)
  const unsub = React.useRef<Unsubscribe | null>(null)

  React.useEffect(() => {
    if (initiativeId) {
      store.dispatch(loadingSlice.actions.setLoading({ name: 'COMMENTS_LOAD', value: true }))
      unsub.current = subscribeForComments(initiativeId, list => {
        const mapped = mapToList(list)
        store.dispatch(commentSlice.actions.setComments(mapped))
        store.dispatch(loadingSlice.actions.setLoading({ name: 'COMMENTS_LOAD', value: false }))
      })
    }

    return () => {
      unsub.current && unsub.current()
    }
  }, [initiativeId])

  return <Loader name="COMMENTS_LOAD">{props.children}</Loader>
}
