import React from 'react'

import styles from '../styles/Notification.module.scss'
import { NotificationChangesType, NotificationType, NotificationTypes } from '@/types/notifications'
import { UserPhoto } from '@/components/avatar/UserPhoto'
import { getPhotoPlaceholder } from '@/utils/getPhotoPlaceholder'
import Text from '@ingka/text'
import { NOTIFICATION_TYPES } from '@/constants/common'
import { Show } from '@/components/conditions/Show'
import { STATUS_TO_LABEL } from '@/constants/statuses'
import { InitiativesStatusesType } from '@/types/initiative'
import { useSearchParams } from 'react-router-dom'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  notification: NotificationType
  onClick: () => void
}

const FIELD_LABELS: { [key in string]: string } = {
  duration: '(Duration of change)',
  level: '(Level of change)'
}

export const NotificationItem: React.FC<Props> = props => {
  const {
    notification: { initiative_id, user, type, seen, initiative, created_at, status, changes },
    onClick
  } = props
  const photoPlaceholder = getPhotoPlaceholder(user?.name)
  const [, setSearchParams] = useSearchParams()

  const handleClick = () => {
    setSearchParams({ preview_id: initiative_id })
    onClick()
  }

  return (
    <div className={styles.NotificationItem} onClick={handleClick}>
      <div className={styles.Head}>
        <UserPhoto small placeholder={photoPlaceholder} photoUrl={user?.photo_url} />
        <Text headingSize="xs" title={user?.name} className={styles.UserName}>
          {user?.name}
        </Text>
        <Label type={type} changes={changes} />
        <Show when={!seen}>
          <div className={styles.Indicator} />
        </Show>
      </div>
      <Body title={initiative.title} type={type} status={status} change={changes && changes[0]} />
      <div className={styles.Date}>{created_at}</div>
    </div>
  )
}

const Label: React.FC<{ type: NotificationTypes; changes?: NotificationChangesType[] }> = props => {
  const { type, changes } = props
  if (type === NOTIFICATION_TYPES.change && changes)
    return (
      <Text bodySize="m" className={styles.TypeDesc}>
        <DynamicTranslations id="initiatives.activity.updated" /> {FIELD_LABELS[changes[0].name]}:
      </Text>
    )

  const labels = {
    [NOTIFICATION_TYPES.rejected]: <DynamicTranslations id="initiatives.activity.rejected" />,
    [NOTIFICATION_TYPES.submitted]: <DynamicTranslations id="initiatives.activity.submitted" />,
    [NOTIFICATION_TYPES.commented]: <DynamicTranslations id="initiatives.comments.new" />
  }

  return (
    <Text bodySize="m" className={styles.TypeDesc}>
      {labels[type]}
    </Text>
  )
}

const Body: React.FC<{
  title: string
  type: NotificationTypes
  status: InitiativesStatusesType
  change?: NotificationChangesType
}> = props => {
  const { title, type, status, change } = props

  return (
    <Text bodySize="m" className={styles.Description}>
      {'"'}
      {title}
      {'"'}
      <Show when={type === NOTIFICATION_TYPES.submitted}>
        <>
          {' '}
          to {'"'}
          {STATUS_TO_LABEL[status]}
          {'"'}
        </>
      </Show>
      <Show when={Boolean(change)}>
        <br />
        {change?.before} -{'>'} {change?.after}
      </Show>
    </Text>
  )
}
