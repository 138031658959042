import React from 'react'
import styles from './Header.module.scss'
import LogoImg from '@/images/INGKA_logo.png'
import { HeaderContext, HeaderProvider } from '@/components/header/provider'
import { Show } from '@/components/conditions/Show'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import Button from '@ingka/button'
import MenuIcon from '@ingka/ssr-icon/paths/menu'
import { Sidebar } from '@/components/header/Sidebar'
import { Link } from 'react-router-dom'
import { UserPhoto } from '@/components/avatar/UserPhoto'
import { getPhotoPlaceholder } from '@/utils/getPhotoPlaceholder'
import { NotificationModal } from '@/pages/Notifications/components/NotificationModal'
import { NotificationButton } from '@/components/header/NotificationButton'
import SpeechBubbleIcon from '@ingka/ssr-icon/paths/speech-bubble'
import { Filters } from '@/components/header/Filters'
import { LanguageSelector } from '@/components/header/LanguageSelector'

export const Header: React.FC<React.PropsWithChildren> = props => {
  return (
    <HeaderProvider>
      <HeaderBody />
      <NotificationModal />
      {props.children}
    </HeaderProvider>
  )
}

const HeaderBody = () => {
  const [open, setOpen] = React.useState(false)
  const { state } = React.useContext(HeaderContext)
  const user = useSelector((state: RootState) => state.auth.user)

  const photoPlaceholder = getPhotoPlaceholder(user?.name)

  return (
    <Show when={state.showHeader}>
      <Sidebar open={open} handleClose={() => setOpen(false)} />
      <div className={styles.Header}>
        <div className={styles.LogoBlock}>
          <Button type="tertiary" ssrIcon={MenuIcon} onClick={() => setOpen(true)} small iconOnly />

          <Link to={'/'}>
            <img src={LogoImg} alt="logo" />
          </Link>
        </div>
        <Show when={state.showFilters}>
          <Filters />
        </Show>
        <Show when={!state.showFilters}>
          <div />
        </Show>
        <div className={styles.RightSide}>
          <Show when={false}>
            <LanguageSelector />
          </Show>
          <Button
            iconOnly
            ssrIcon={SpeechBubbleIcon}
            type="tertiary"
            href="mailto:o365g_socialimpactreportingtool_imssemal@ingka.com"
          />
          <NotificationButton />
          <UserPhoto placeholder={photoPlaceholder} photoUrl={user?.photo_url} />
        </div>
      </div>
    </Show>
  )
}
