import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { useFormikContext } from 'formik'
import { ExploreFiltersType } from '@/types/filters'
import { RadioGroup } from '@/components/form/radio/RadioGroup'
import { AccordionItem } from '@ingka/accordion'
import { isEmpty } from 'lodash'

type Props = {
  id: string
  title: React.ReactElement
}

export const UnitsField: React.FC<Props> = props => {
  const { id, title } = props

  const sites = useSelector((state: RootState) => state.sites.sites)
  const { values } = useFormikContext<ExploreFiltersType>()

  const filteredSites = React.useMemo(() => {
    return sites
      .filter(item => {
        const funcCondition = values.function_code
          ? values.function_code.some(code => code.value === item.function_code)
          : true

        return item.country_code === values.country_code?.value && funcCondition
      })
      .map(item => ({
        id: item.sustain_site_id,
        value: item.site_name,
        label: item.site_name
      }))
  }, [sites, values.country_code, values.function_code])

  if (!values.country_code || isEmpty(filteredSites)) return null

  return (
    <AccordionItem id={id} title={title}>
      <RadioGroup name={id} list={filteredSites} limitedPreview={5} />
    </AccordionItem>
  )
}
