import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import styles from '../../styles/Initiatives.module.scss'
import SSRIcon from '@ingka/ssr-icon'
import Text from '@ingka/text'
import { isEmpty } from 'lodash'
import { getIcon } from '@/utils/files'

const AttachmentsImpl = () => {
  const attachments = useSelector((state: RootState) => state.initiatives.details?.attachments)

  if (isEmpty(attachments)) return <>-</>

  return (
    <div className={styles.Attachments}>
      {attachments?.map(item => {
        return (
          <a href={item.fileUrl} target="_blank" key={item.fileUrl} className={styles.Item} rel="noreferrer">
            <SSRIcon paths={getIcon(item.fileType)} />
            <Text bodySize="s">{item.fileName}</Text>
          </a>
        )
      })}
    </div>
  )
}

export const Attachments = React.memo(AttachmentsImpl)
