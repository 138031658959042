import React from 'react'
import styles from '../styles/Initiatives.module.scss'
import Text from '@ingka/text'
import Button from '@ingka/button'
import PlusIcon from '@ingka/ssr-icon/paths/plus'
import { useHeaderSettings } from '@/components/header/provider'

export const Placeholder = () => {
  useHeaderSettings(true, false)

  return (
    <div className={styles.Placeholder}>
      <svg
        className={styles.PlaceholderImage}
        width="259"
        height="197"
        viewBox="0 0 259 197"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="68.2305" y="0.5" width="186.324" height="56.0763" rx="8.5" stroke="#DFDFDF" />
        <rect x="80.6387" y="12.9084" width="64.7519" height="11.9084" rx="4" fill="#DFDFDF" />
        <rect x="80.6387" y="32.2595" width="44.6565" height="11.9084" rx="4" fill="#F5F5F5" />
        <rect x="0.5" y="70.4619" width="186.324" height="56.0763" rx="8.5" stroke="#DFDFDF" />
        <rect x="12.9082" y="82.8701" width="64.7519" height="11.9084" rx="4" fill="#DFDFDF" />
        <rect x="12.9082" y="102.221" width="44.6565" height="11.9084" rx="4" fill="#F5F5F5" />
        <rect x="71.9492" y="140.424" width="186.324" height="56.0763" rx="8.5" stroke="#DFDFDF" />
        <rect x="84.3574" y="152.832" width="64.7519" height="11.9084" rx="4" fill="#DFDFDF" />
        <rect x="84.3574" y="172.183" width="44.6565" height="11.9084" rx="4" fill="#F5F5F5" />
      </svg>
      <Text className={styles.PlaceholderHead} headingSize="m">
        Start by creating your first initiative
      </Text>
      <Text className={styles.PlaceholderContent} bodySize="l">
        There are no initiatives yet. Try to create your first.
      </Text>
      <Button href={'/edit'} ssrIcon={PlusIcon} type="primary" small>
        Add new initiative
      </Button>
    </div>
  )
}
