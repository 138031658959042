import React from 'react'
import styles from '../../styles/Initiatives.module.scss'

type Props = {
  comment?: string
}

const pattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/

export const CommentInfo: React.FC<Props> = props => {
  const { comment } = props

  if (!comment) return <>-</>

  const commentParts = React.useMemo(() => {
    return comment
      .replace('\n', ' ')
      .split(' ')
      .map((item, index) => {
        if (pattern.test(item)) {
          return {
            id: `${item}${index}`,
            content: (
              <a href={item} rel="noreferrer" target="_blank" className={styles.Link}>
                {item}
              </a>
            )
          }
        }

        return { id: `${item}${index}`, content: item }
      })
  }, [comment])

  return (
    <div>
      {commentParts.map(item => (
        <React.Fragment key={item.id}>{item.content} </React.Fragment>
      ))}
    </div>
  )
}
