import Button from '@ingka/button'
import React from 'react'
import Text from '@ingka/text'
import styles from './Login.module.scss'

type Props = {
  onClick: () => void
}

export const Login: React.FC<Props> = props => {
  return (
    <div className={styles.LoginWrap}>
      <div className={styles.LoginBox}>
        <Text headingSize="l" tagName="h1">
          Report Social Impact Initiative
        </Text>
        <Text bodySize="xl">
          Reporting tool to collect the information and positive social impact of the initiatives that Ingka / IKEA is
          creating in the local neighbourhoods.
        </Text>
        <Button className={styles.LoginAction} onClick={props.onClick}>
          Sign In
        </Button>
      </div>
    </div>
  )
}
