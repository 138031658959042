import React from 'react'
import styles from '@/pages/Activity/styles/ActivityTab.module.scss'
import { CommentsProvider } from '@/pages/GlobalReviewComments/providers/CommentsProvider'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { UserPhoto } from '@/components/avatar/UserPhoto'
import Text from '@ingka/text'
import { Show } from '@/components/conditions/Show'
import { getPhotoPlaceholder } from '@/utils/getPhotoPlaceholder'
import { Form, Formik } from 'formik'
import Button from '@ingka/button'
import { submitComment } from '@/pages/GlobalReviewComments/utils/submit'
import { InputField } from '@/components/form/input/InputField'
import { DynamicTranslations } from '@/components/translations/Translations'

const GlobalReviewCommentsImpl = () => {
  const list = useSelector((state: RootState) => state.comments.list)
  return (
    <div className={styles.Wrapper}>
      <Formik initialValues={{ comment: '' }} onSubmit={submitComment}>
        <Form>
          <InputField id="comment" type="text" className={styles.TextBox} name="comment" label="" />
          <Button type="emphasised" small htmlType="submit">
            <DynamicTranslations id="initiatives.comments.send" />
          </Button>
        </Form>
      </Formik>
      <div className={styles.ActivityTab}>
        {list.map(item => {
          const photoPlaceholder = getPhotoPlaceholder(item.user.name)

          return (
            <div key={`comment-${item.id}`} className={styles.ActivityItem}>
              <div className={styles.BaseInfoWrap}>
                <UserPhoto placeholder={photoPlaceholder} photoUrl={item.user.photo_url} />
                <div className={styles.BaseInfo}>
                  <div className={styles.UserName}>{item.user.name}</div>
                  <Text bodySize="m" className={styles.Description}>
                    <DynamicTranslations id="initiatives.comments.added" />
                  </Text>
                  <Text bodySize="s" className={styles.Date}>
                    {item.created_at}
                  </Text>
                  <Show when={Boolean(item.comment)}>
                    <Text className={styles.CommentInfo} bodySize="m">
                      {item.comment}
                    </Text>
                  </Show>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const GlobalReviewComments = () => {
  return (
    <CommentsProvider>
      <GlobalReviewCommentsImpl />
    </CommentsProvider>
  )
}
