import React from 'react'
import clx from 'classnames'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import styles from '../../styles/Confirmation.module.scss'
import { Form, Formik } from 'formik'
import { TextArea } from '@/components/form/textarea/TextArea'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { handleCommentInitiative } from '@/pages/Initiatives/utils/comment'
import { CommentConfirmationSchema } from '@/pages/Initiatives/utils/validation'
import { DynamicTranslations } from '@/components/translations/Translations'

export const CommentConfirmation: React.FC = () => {
  const toCommentId = useSelector((state: RootState) => state.initiatives.toCommentId)
  const header = <ModalHeader ariaCloseTxt="Close confirmation" />

  const handleClose = () => store.dispatch(initiativesSlice.actions.setToComment(null))

  return (
    <Modal visible={Boolean(toCommentId)} handleCloseBtn={handleClose}>
      <Prompt
        className={clx(styles.ConfirmationModal, styles.Comment)}
        title={<DynamicTranslations id="initiatives.confirmation.comment.title" />}
        footer={null}
        header={header}
      >
        <Formik
          initialValues={{ comment: '' }}
          validationSchema={CommentConfirmationSchema}
          onSubmit={handleCommentInitiative}
        >
          {({ isSubmitting }) => (
            <Form>
              <TextArea
                label={<DynamicTranslations id="initiatives.confirmation.comment.placeholder" />}
                name="comment"
                characterLimit={200}
              />
              <div className={styles.ButtonFull}>
                <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                  <DynamicTranslations id="initiatives.comments.send" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Prompt>
    </Modal>
  )
}
