import { collection, DocumentSnapshot, getDocs, orderBy, query, QueryDocumentSnapshot } from 'firebase/firestore'
import { InitiativeBase, InitiativeVersion } from '@/store'

export async function getLatestVersion<T>(snapshot: QueryDocumentSnapshot | DocumentSnapshot): Promise<T> {
  const versions = await getDocs(query(collection(snapshot.ref, 'versions'), orderBy('created', 'desc')))
  const latestVersion = versions.docs.map(x => x.data())[0] as InitiativeVersion
  return {
    id: snapshot.id,
    ...(snapshot.data() as InitiativeBase),
    ...latestVersion
  } as T
}
