import React from 'react'
import { useFormikContext } from 'formik'
import { store } from '@/storage/store'
import { exploreSlice } from '@/pages/Explore/slices/explore'
import { ExploreFiltersType } from '@/types/filters'
import { isEmpty, isNil } from 'lodash'
import { loadingSlice } from '@/storage/loading'
import { EXPLORE_LOADING } from '@/pages/Explore/constants/filters'

export const FilterObserver = () => {
  const { values } = useFormikContext()
  const oldValues = React.useRef<unknown>()

  React.useEffect(() => {
    const mapped = Object.keys(values as ExploreFiltersType).reduce((result, key) => {
      const value = (values as ExploreFiltersType)[key as keyof ExploreFiltersType]

      if (isNil(value) || isEmpty(value)) return result

      return {
        ...result,
        [key]: value
      }
    }, {} as ExploreFiltersType)

    if (oldValues.current) {
      store.dispatch(exploreSlice.actions.setFilter(mapped))
      store.dispatch(loadingSlice.actions.setLoading({ name: EXPLORE_LOADING, value: true }))
    }

    oldValues.current = mapped
  }, [values])

  return null
}
