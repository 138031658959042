import React from 'react'
import Tabs, { Tab, TabPanel } from '@ingka/tabs'
import styles from '../styles/Analytics.module.scss'
import { AnalysisChartCardFY24 } from '@/pages/Analytics/components/FY24Logic/AnalysisChartCardFY24'
import { ReportingStatusChartCard } from '@/pages/Analytics/components/ReportingStatusChartCard'
import { PPITable } from '@/pages/Analytics/components/FY24Logic/Table/PPITable'
import { PSTable } from '@/pages/Analytics/components/FY24Logic/Table/PSTable'
import { DynamicTranslations } from '@/components/translations/Translations'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { AnalysisChart } from '@/pages/Analytics/components/AnalysisChart'
import InlineMessage from '@ingka/inline-message'
import { StrongAndLight } from '@/pages/Analytics/components/Table/StrongAndLight'
import { Strong } from '@/pages/Analytics/components/Table/Strong'

export const ContentTabs = () => {
  const fy = useSelector((state: RootState) => state.filters.filters.fy)

  if (new Date(fy?.value || '').getFullYear() === 2023) return <ContentFY24 />

  return <Content />
}

const ContentFY24 = () => {
  const tabs = [
    <Tab key="impacted-tab" tabPanelId="impacted" text={<DynamicTranslations id="data_reporting.tabs.ppp" />} />,
    <Tab
      key="supported-tab"
      tabPanelId="supported"
      text={<DynamicTranslations id="data_reporting.tabs.people_supported" />}
    />
  ]

  const panels = [
    <TabPanel key="impacted" tabPanelId="impacted">
      <div className={styles.ChartsBlock}>
        <AnalysisChartCardFY24 />
        <ReportingStatusChartCard />
      </div>
      <PPITable />
    </TabPanel>,
    <TabPanel key="supported" tabPanelId="supported">
      <div className={styles.ChartsBlock}>
        <AnalysisChartCardFY24 withConnect />
        <ReportingStatusChartCard />
      </div>
      <PSTable />
    </TabPanel>
  ]

  return <Tabs className={styles.Tabs} tabs={tabs} tabPanels={panels} />
}

const Content = () => {
  const tabs = [
    <Tab key="kpi-tab" tabPanelId="kpi" text={<DynamicTranslations id="data_reporting.tabs.kpi_people_supported" />} />,
    <Tab key="pi-tab" tabPanelId="pi" text={<DynamicTranslations id="data_reporting.tabs.pi_total" />} />
  ]

  const panels = [
    <TabPanel key="kpi" tabPanelId="kpi">
      <InlineMessage
        className={styles.Inline}
        body={<DynamicTranslations id="data_reporting.tabs.kpi_people_supported.description" />}
      />
      <div className={styles.ChartsBlock}>
        <AnalysisChart />
        <ReportingStatusChartCard />
      </div>
      <Strong />
    </TabPanel>,
    <TabPanel key="pi" tabPanelId="pi">
      <InlineMessage
        className={styles.Inline}
        body={<DynamicTranslations id="data_reporting.tabs.pi_total.description" />}
      />
      <div className={styles.ChartsBlock}>
        <AnalysisChart withLight />
        <ReportingStatusChartCard />
      </div>
      <StrongAndLight />
    </TabPanel>
  ]

  return <Tabs className={styles.Tabs} tabs={tabs} tabPanels={panels} />
}
