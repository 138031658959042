import React from 'react'
import Text from '@ingka/text'

import styles from '../styles/Initiatives.module.scss'
import { InitiativesList } from '@/pages/Initiatives/components/List/InitiativesList'
import { INITIATIVES_LOADER, InitiativesProvider } from '@/pages/Initiatives/providers/Initiatives'
import { ListSearch } from '@/pages/Initiatives/components/List/ListSearch'
import { Loader } from '@/components/loader/Loader'
import { Confirmation } from '@/pages/Initiatives/components/Details/Confirmation'
import { GlobalDetails } from '@/pages/Initiatives/components/Details/GlobalDetails'
import { InitiativesLeft } from '@/pages/Initiatives/components/InitiativesLeft'
import { RecallConfirmation } from '@/pages/Initiatives/components/Details/RecallConfirmation'
import { DynamicTranslations } from '@/components/translations/Translations'

export const GlobalInitiatives = () => {
  return (
    <InitiativesProvider>
      <div className={styles.InitiativesPage}>
        <div className={styles.InitiativeList}>
          <div className={styles.Head}>
            <Text headingSize="l">
              <DynamicTranslations id="initiatives" />
            </Text>
          </div>
          <ListSearch />
          <InitiativesLeft />
          <Loader name={INITIATIVES_LOADER}>
            <InitiativesList />
          </Loader>
        </div>
        <div className={styles.Divider} />
        <GlobalDetails />
      </div>
      <Confirmation receiver="Country Reviewer" status="Country Review" />
      <RecallConfirmation />
    </InitiativesProvider>
  )
}
