import React from 'react'
import styles from '@/pages/ReportForm/styles/NewReport.module.scss'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { store } from '@/storage/store'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  id: string
}

export const FormLabel: React.FC<Props> = props => {
  const { id } = props
  const GetDetails = (
    <span onClick={() => store.dispatch(formSlice.actions.changeModal(id))} className={styles.GetDetails}>
      <DynamicTranslations id="form.label.details" />
    </span>
  )

  switch (id) {
    case FIELD_NAME.siteOrCountry:
      return (
        <>
          <DynamicTranslations id="form.label.siteOrCountry" /> {GetDetails}
        </>
      )
    case FIELD_NAME.people_supported_count:
      return (
        <>
          <DynamicTranslations id="form.label.people_supported_count" /> {GetDetails}
        </>
      )
    case FIELD_NAME.spent:
      return (
        <>
          <DynamicTranslations id="form.label.spent" /> {GetDetails}
        </>
      )
    case FIELD_NAME.coworkers_count:
      return (
        <>
          <DynamicTranslations id="form.label.coworkers_count" /> {GetDetails}
        </>
      )
    case FIELD_NAME.coworker_hours:
      return (
        <>
          <DynamicTranslations id="form.label.coworker_hours" /> {GetDetails}
        </>
      )
    case FIELD_NAME.customers_count:
      return (
        <>
          <DynamicTranslations id="form.label.customers_count" /> {GetDetails}
        </>
      )
    case FIELD_NAME.main_movement_supported:
      return (
        <>
          <DynamicTranslations id="form.label.main_movement_supported" /> {GetDetails}
        </>
      )
    case FIELD_NAME.movement_supported:
      return (
        <>
          <DynamicTranslations id="form.label.movement_supported" />
        </>
      )
    case FIELD_NAME.level:
      return (
        <>
          <DynamicTranslations id="form.label.level" /> {GetDetails}
        </>
      )
    case FIELD_NAME.duration:
      return (
        <>
          <DynamicTranslations id="form.label.duration" /> {GetDetails}
        </>
      )
    default:
      return null
  }
}
