import React from 'react'
import Modal, { Sheets, ModalHeader } from '@ingka/modal'
import { HeaderContext } from '@/components/header/provider'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { NotificationItem } from '@/pages/Notifications/components/NotificationItem'
import { checkAllAsSeen } from '@/pages/Notifications/utils/checkAllAsSeen'
import { Show } from '@/components/conditions/Show'
import { isEmpty } from 'lodash'
import { Placeholder } from '@/pages/Notifications/components/Placeholder'

export const NotificationModal = () => {
  const {
    state: { showNotifications },
    toggleNotifications
  } = React.useContext(HeaderContext)
  const list = useSelector((state: RootState) => state.notifications.list)

  const handleClose = () => {
    checkAllAsSeen()
    toggleNotifications && toggleNotifications(false)
  }

  const header = <ModalHeader title="Notifications" />

  return (
    <Modal visible={showNotifications} handleCloseBtn={handleClose}>
      <Sheets footer={null} header={header}>
        {list.map(item => (
          <NotificationItem key={`not-${item.id}`} notification={item} onClick={handleClose} />
        ))}
        <Show when={isEmpty(list)}>
          <Placeholder />
        </Show>
      </Sheets>
    </Modal>
  )
}
