import { InitiativeDBType } from '@/types/initiative'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { format } from 'date-fns'
import { FiltersBEType, FiltersType } from '@/types/filters'
import { omit, orderBy } from 'lodash'
import { MANAGER_STATUSES } from '@/constants/statuses'
import { Role } from '@/types/user'
import { FUNCTIONS_CODES, USER_ROLES } from '@/constants/common'
import { getIngkaFinancialYear } from '@/utils/dates'

export const mapToList = (
  data: InitiativeDBType[],
  isManager?: boolean,
  canCreateInitiative?: boolean
): InitiativesListItemType[] => {
  return data
    .filter(
      item =>
        (isManager && !canCreateInitiative ? MANAGER_STATUSES.some(status => item.status === status) : true) &&
        !item.deleted
    )
    .map(item => {
      const initiative = {
        ...item,
        report_period: item.report_period && format(item.report_period.toDate(), 'MMMM yyyy'),
        updated_at: item.updated_at && format(item.updated_at.toDate(), 'MMMM yyyy')
      }
      return omit(initiative, 'created_at')
    }) as InitiativesListItemType[]
}

export const mapToListWithCreateInitiativePermission = (
  data: InitiativeDBType[],
  filters: FiltersBEType,
  userId: string | null,
  countryCode: string[] | null,
  isManager: boolean,
  centersOnly: boolean
): InitiativesListItemType[] => {
  return mapToList(
    orderBy(data, item => -item.updated_at?.toDate()),
    isManager,
    true
  ).filter(item => {
    if (item.user_id === userId && checkByFilters(item, filters)) {
      return true
    }

    if (
      countryCode?.some(code => code === item.country_code) &&
      MANAGER_STATUSES.some(status => item.status === status) &&
      checkByFilters(item, filters)
    ) {
      return true
    }

    return centersOnly && MANAGER_STATUSES.some(status => item.status === status) && checkByFilters(item, filters)
  })
}

const checkByFilters = (item: InitiativesListItemType, filters: FiltersBEType) => {
  return Object.entries(filters).every(([filterKey, filterValue]) => {
    if (!filterValue) return true

    switch (filterKey) {
      case 'report_period':
        return format(filterValue as Date, 'MMMM yyyy') === item.report_period

      default:
        return filterValue === item[filterKey as keyof InitiativesListItemType]
    }
  })
}

export const mapFromFilters = (data: FiltersType): FiltersBEType => {
  return Object.keys(data).reduce((result, current) => {
    if (data[current as keyof FiltersBEType]?.value === 'all') return result

    if (current === 'report_period') {
      return {
        ...result,
        [current]: new Date(data[current as keyof FiltersBEType]?.value || '')
      }
    }

    if (current === 'fy') {
      return {
        ...result,
        [current]: getIngkaFinancialYear(new Date(data[current as keyof FiltersBEType]?.value || '')).getUTCFullYear()
      }
    }

    return {
      ...result,
      [current]: data[current as keyof FiltersBEType]?.value
    }
  }, {}) as FiltersBEType
}

export const mapInitiativesToCount = (
  data: InitiativeDBType[],
  userId: string,
  countryCodes: string[],
  role: Role,
  isCentres: boolean,
  fy: number
): number => {
  const filteredByFY = data.filter(item => item.fy === fy)
  if (role === USER_ROLES.globalManager) return filteredByFY.length
  if (isCentres) {
    return filteredByFY.filter(item => item.function_code === FUNCTIONS_CODES.centers).length
  }

  return filteredByFY.filter(
    item =>
      item.function_code !== FUNCTIONS_CODES.centers &&
      (item.user_id === userId || countryCodes.some(code => code === item.country_code))
  ).length
}
