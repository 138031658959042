import React from 'react'
import Text from '@ingka/text'

import styles from '../styles/Initiatives.module.scss'
import { InitiativesList } from '@/pages/Initiatives/components/List/InitiativesList'
import { INITIATIVES_LOADER, InitiativesProvider } from '@/pages/Initiatives/providers/Initiatives'
import { ListSearch } from '@/pages/Initiatives/components/List/ListSearch'
import { Loader } from '@/components/loader/Loader'
import { CountryDetails } from '@/pages/Initiatives/components/Details/CountryDetails'
import { Confirmation } from '@/pages/Initiatives/components/Details/Confirmation'
import Button from '@ingka/button'
import { Show } from '@/components/conditions/Show'
import { useNavigate } from 'react-router-dom'
import { useCanCreateInitiative } from '@/utils/permissions'
import { DeleteConfirmation } from '@/pages/Initiatives/components/Details/DeleteConfirmation'
import { ChangeConfirmation } from '@/pages/Initiatives/components/Details/ChangeConfirmation'
import { InitiativesLeft } from '@/pages/Initiatives/components/InitiativesLeft'
import { DynamicTranslations } from '@/components/translations/Translations'
import { CommentConfirmation } from '@/pages/Initiatives/components/Details/CommentConfirmation'
import { RecallConfirmation } from '@/pages/Initiatives/components/Details/RecallConfirmation'

export const CountryInitiatives = () => {
  const navigate = useNavigate()

  const canCreateInitiative = useCanCreateInitiative()

  return (
    <InitiativesProvider>
      <div className={styles.InitiativesPage}>
        <div className={styles.InitiativeList}>
          <div className={styles.Head}>
            <Text headingSize="l">
              <DynamicTranslations id="initiatives" />
            </Text>
            <Show when={canCreateInitiative}>
              <Button type="primary" small onClick={() => navigate('/edit')}>
                <DynamicTranslations id="initiatives.add_new_initiative" />
              </Button>
            </Show>
          </div>
          <ListSearch />
          <InitiativesLeft />
          <Loader name={INITIATIVES_LOADER}>
            <InitiativesList />
          </Loader>
        </div>
        <div className={styles.Divider} />
        <CountryDetails />
      </div>
      <Confirmation receiver="Data Provider" status="Rejected" />
      <DeleteConfirmation />
      <ChangeConfirmation />
      <CommentConfirmation />
      <RecallConfirmation />
    </InitiativesProvider>
  )
}
