import React from 'react'
import { getUsers } from '@/pages/Admin/services'
import { adminSlice } from '@/pages/Admin/slices/admin'
import { store } from '@/storage/store'

export const Admin: React.FC<React.PropsWithChildren> = props => {
  const { children } = props

  React.useEffect(() => {
    getUsers(users => {
      store.dispatch(adminSlice.actions.setUser(users))
    })
  }, [])

  return <>{children}</>
}
