import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import FormField, { FormFieldProps } from '@ingka/form-field'
import Checkbox from '@ingka/checkbox'
import Text from '@ingka/text'
import clx from 'classnames'

import { Option } from '@/types/common'

import styles from './CheckboxGroup.module.scss'
import { Show } from '@/components/conditions/Show'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = FieldHookConfig<Option[]> &
  FormFieldProps & {
    list: Option[]
    label?: string | React.ReactElement
    additionalElement?: React.ReactElement
    withTranslations?: boolean
    limitedPreview?: number
  }

export const CheckboxGroup: React.FC<Props> = props => {
  const { list, label, fieldHelper, className, additionalElement, limitedPreview, withTranslations } = props
  const [field, meta, helpers] = useField(props)

  const helper = fieldHelper && {
    ...fieldHelper,
    msg: <span className={styles.CheckboxHelper}>{fieldHelper?.msg}</span>
  }

  return (
    <FormField
      data-field-error={Boolean(meta.error)}
      className={clx(styles.CheckboxGroup, className)}
      valid={!meta.error}
      shouldValidate={Boolean(meta.touched && meta.error)}
      validation={{
        id: `validation-${field.name}`,
        msg: meta.error,
        type: 'error'
      }}
    >
      <Show when={Boolean(label)}>
        <Text className={styles.Label} bodySize="m">
          {label}
        </Text>
      </Show>
      <Show when={Boolean(helper)}>
        <Text id={helper?.id} className={styles.CheckboxHelper} bodySize="s">
          {helper?.msg}
        </Text>
      </Show>
      <List
        {...field}
        withTranslations={withTranslations}
        limitedPreview={limitedPreview}
        setValue={helpers.setValue}
        list={list}
      />
      {additionalElement || <div />}
    </FormField>
  )
}

type ListProps = {
  list: Option[]
  setValue: (value: Option[]) => void
  value: Option[]
  limitedPreview?: number
  withTranslations?: boolean
}

const isChecked = (options: Option[], id: string) => {
  return Boolean(options.find((item: Option) => item.id === id))
}

const List: React.FC<ListProps> = props => {
  const { list, value = [], setValue, limitedPreview, withTranslations } = props
  const [openMore, setOpenMore] = React.useState(false)

  const handleChange = (option: Option) => () => {
    if (isChecked(value, option.id)) {
      setValue(value.filter((item: Option) => item.id !== option.id))
    } else {
      setValue([...value, option])
    }
  }

  const handleToggleMore = () => {
    setOpenMore(!openMore)
  }

  if (limitedPreview && list.length > limitedPreview) {
    const preview = list.slice(0, limitedPreview)
    const expandable = list.slice(limitedPreview, list.length)

    return (
      <fieldset>
        {preview.map(option => {
          return (
            <Checkbox
              label={withTranslations ? <DynamicTranslations id={option.label} /> : option.label}
              key={option.id}
              id={option.id}
              checked={isChecked(value, option.id)}
              value={option.value}
              disabled={option.disabled}
              onChange={handleChange(option)}
            />
          )
        })}
        <Show when={openMore}>
          {expandable.map(option => {
            return (
              <Checkbox
                label={withTranslations ? <DynamicTranslations id={option.label} /> : option.label}
                key={option.id}
                id={option.id}
                checked={isChecked(value, option.id)}
                value={option.value}
                disabled={option.disabled}
                onChange={handleChange(option)}
              />
            )
          })}
        </Show>
        <div onClick={handleToggleMore} className={styles.More}>
          <Show when={!openMore}>
            +{limitedPreview} <DynamicTranslations id="more" />
          </Show>
          <Show when={openMore}>
            <DynamicTranslations id="less" />
          </Show>
        </div>
      </fieldset>
    )
  }

  return (
    <fieldset>
      {list.map(option => {
        return (
          <Checkbox
            label={withTranslations ? <DynamicTranslations id={option.label} /> : option.label}
            key={option.id}
            id={option.id}
            checked={isChecked(value, option.id)}
            value={option.value}
            disabled={option.disabled}
            onChange={handleChange(option)}
          />
        )
      })}
    </fieldset>
  )
}
