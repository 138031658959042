import React, { useContext } from 'react'
import { ReducerAction } from '@/types/common'

type ContextType = {
  state: StoreType
  toggleHeader?: (value: Partial<StoreType>) => void
  toggleNotifications?: (value: boolean) => void
}

type StoreType = {
  showHeader: boolean
  showFilters: boolean
  showNotifications: boolean
}

const initial = {
  showHeader: true,
  showFilters: true,
  showNotifications: false
}

export const HeaderContext = React.createContext<ContextType>({ state: initial })

const reducer: React.Reducer<StoreType, ReducerAction<Partial<StoreType> | boolean>> = (state = initial, action) => {
  const { type, payload } = action

  switch (type) {
    case 'toggle_header':
      return {
        ...state,
        ...(payload as StoreType)
      }

    case 'toggle_notifications':
      return {
        ...state,
        showNotifications: payload as boolean
      }

    default:
      return state
  }
}

export const HeaderProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props
  const [state, dispatch] = React.useReducer(reducer, initial)

  const toggleHeader = (value: Partial<StoreType>) => {
    dispatch({ type: 'toggle_header', payload: value })
  }

  const toggleNotifications = (value: boolean) => {
    dispatch({ type: 'toggle_notifications', payload: value })
  }

  return (
    <HeaderContext.Provider value={{ state, toggleHeader, toggleNotifications }}>{children}</HeaderContext.Provider>
  )
}

export const useHeaderSettings = (showHeader = true, showFilters = true) => {
  const { toggleHeader } = useContext(HeaderContext)

  React.useEffect(() => {
    if (!toggleHeader) return
    toggleHeader({ showHeader, showFilters })

    return () => {
      toggleHeader(initial)
    }
  }, [])
}
