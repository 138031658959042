import { collection, query, where, onSnapshot, orderBy, limit, getDoc, updateDoc, doc } from '@firebase/firestore'
import { db } from '@/store'
import { UserData } from '@/types/user'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { NotificationDBType } from '@/types/notifications'
import { InitiativeDBType } from '@/types/initiative'

export const notificationsSubscribe = (userId: string, callback: (items: NotificationDBType[]) => void) => {
  const notificationsQuery = query(
    collection(db, 'notifications'),
    where('user_id', '==', userId),
    orderBy('created_at', 'desc'),
    limit(20)
  )
  onSnapshot(notificationsQuery, async querySnapshot => {
    const notifications: Promise<NotificationDBType>[] = []

    querySnapshot.forEach(item => {
      notifications.push(getLatestVersion<NotificationDBType>(item))
    })

    const items = await Promise.all(notifications)
    const itemsWithRelations = await Promise.all(
      items.map(async item => {
        const user = await getDoc(doc(db, 'users', item.created_by))
        const initiative = await getDoc(doc(db, 'initiatives', item.initiative_id))
        return {
          ...item,
          user: (await user.data()) as UserData,
          initiative: (await initiative.data()) as InitiativeDBType
        }
      })
    )

    callback(itemsWithRelations)
  })
}

export const changeSeenNotification = async (notificationId: string) => {
  return updateDoc(doc(db, 'notifications', notificationId), { seen: true })
}
