import { ActivityType } from '@/types/activity'
import { setActivity } from '@/pages/Activity/services'

type ActivityProperties = Omit<ActivityType, 'date'>

export const trackActivity = (activity: ActivityProperties) => {
  const date = new Date()

  return setActivity({ ...activity, date })
}
