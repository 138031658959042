import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AUTH_LOAD, SITE_DATA_LOAD } from '@/constants/loaders'

type StoreType = {
  [key in string]?: boolean
}

const initialState = {
  [AUTH_LOAD]: true,
  [SITE_DATA_LOAD]: true
} as StoreType

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<{ name: string; value: boolean }>) {
      state[action.payload.name] = action.payload.value
    }
  }
})
