import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db } from '@/store'
import { UserData } from '@/types/user'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { ActivityDBType, ActivityType } from '@/types/activity'
import { onSnapshot } from '@firebase/firestore'

export const setActivity = async (activity: ActivityType): Promise<ActivityDBType> => {
  const reference = await addDoc(collection(db, 'activities'), activity)
  const data = await getDoc(reference)
  return getLatestVersion(data)
}

export const getActivities = async (initiativeId: string): Promise<ActivityDBType[]> => {
  const result = await getDocs(
    query(collection(db, 'activities'), where('initiative_id', '==', initiativeId), orderBy('date', 'desc'))
  )

  return Promise.all(
    result.docs.map(async item => {
      const unpackedItem = await getLatestVersion<ActivityDBType>(item)
      const user = await getDoc(doc(collection(db, 'users'), unpackedItem.user_id))
      return {
        ...unpackedItem,
        user: user.data() as UserData
      }
    })
  )
}

export const subscribeForActivities = (initiativeId: string, callback: (items: ActivityDBType[]) => void) => {
  const dataQuery = query(
    collection(db, 'activities'),
    where('initiative_id', '==', initiativeId),
    orderBy('date', 'desc')
  )

  return onSnapshot(dataQuery, async snapshot => {
    const results: Promise<ActivityDBType>[] = []

    snapshot.forEach(item => {
      results.push(getLatestVersion<ActivityDBType>(item))
    })

    const items = await Promise.all(results)
    const itemsWithRelation = await Promise.all(
      items.map(async item => {
        const user = await getDoc(doc(db, 'users', item.user_id))
        return {
          ...item,
          user: (await user.data()) as UserData
        }
      })
    )
    callback(itemsWithRelation)
  })
}
