import React from 'react'
import { InitiativeItem } from '@/pages/Initiatives/components/List/InitiativeItem'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { searchInitiative } from '@/pages/Initiatives/utils/search'
import styles from '../../styles/Initiatives.module.scss'
import { InitiativesPlaceholder } from '@/components/placeholder/InitiativesPlaceholder'
import { isEmpty } from 'lodash'

export const InitiativesListImpl = () => {
  const { initiatives, search } = useSelector((state: RootState) => state.initiatives)

  const data = searchInitiative(initiatives, search)

  return (
    <div className={styles.List}>
      <InitiativesPlaceholder show={isEmpty(data)} />
      {data.map(item => (
        <InitiativeItem key={`init-${item.id}`} initiative={item} />
      ))}
    </div>
  )
}

export const InitiativesList = React.memo(InitiativesListImpl)
