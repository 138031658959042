import React from 'react'
import styles from './InitiativesPlaceholder.module.scss'

import NoResultsImg from '@/assets/images/no results.svg'
import NoInitiativesImg from '@/assets/images/no initiatives.svg'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { USER_ROLES } from '@/constants/common'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  show: boolean
  title?: string
  body?: string
}

export const InitiativesPlaceholder: React.FC<Props> = props => {
  const { show, title, body } = props
  const role = useSelector((state: RootState) => state.auth.user?.role)

  if (!show) return null
  const isUserRole = role === USER_ROLES.user || !role
  const headText = isUserRole ? 'No results found' : 'No initiatives left for review'
  const bodyText = isUserRole
    ? 'We could not find what you are searching for. Please, try again'
    : 'You have reviewed all initiatives'

  return (
    <div className={styles.InitiativePlaceholderWrap}>
      <img src={isUserRole ? NoResultsImg : NoInitiativesImg} alt="no_res" />
      <Text headingSize="m">
        <DynamicTranslations id={title || headText} />
      </Text>
      <Text bodySize="l">
        <DynamicTranslations id={body || bodyText} />
      </Text>
    </div>
  )
}
