import React from 'react'
import { getFirebaseUser, initializeWithUserData, openSignInPopup } from '@/store'
import { RootState, store } from '@/storage/store'
import { loadingSlice } from '@/storage/loading'
import { Loader } from '@/components/loader/Loader'
import { AUTH_LOAD } from '@/constants/loaders'
import { Login } from '@/components/auth/Login'
import { useSelector } from 'react-redux'
import { authSlice } from '@/storage/auth'

export const AuthProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props
  const user = useSelector((state: RootState) => state.auth.user)

  React.useEffect(() => {
    initializeWithUserData()
      .then(user => {
        store.dispatch(authSlice.actions.setUser(user))
      })
      .catch(err => {
        store.dispatch(authSlice.actions.setError(err))
      })
      .finally(() => {
        store.dispatch(loadingSlice.actions.setLoading({ name: AUTH_LOAD, value: false }))
      })
  }, [])

  const handleAuth = async () => {
    store.dispatch(loadingSlice.actions.setLoading({ name: AUTH_LOAD, value: true }))

    const result = await openSignInPopup()
    const user = await getFirebaseUser(result.user)
    store.dispatch(authSlice.actions.setUser(user))

    store.dispatch(loadingSlice.actions.setLoading({ name: AUTH_LOAD, value: false }))
  }

  return <Loader name={AUTH_LOAD}>{!user ? <Login onClick={handleAuth} /> : children}</Loader>
}
