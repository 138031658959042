import React from 'react'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import Text from '@ingka/text'
import styles from '../../styles/Confirmation.module.scss'
import { Form, Formik } from 'formik'
import { TextArea } from '@/components/form/textarea/TextArea'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { changeInitiativeFields } from '@/pages/Initiatives/utils/edit'
import { ChangeModalSchema } from '@/pages/Initiatives/utils/validation'

const Fields: { [key in string]: string } = {
  level: 'Level of change',
  duration: 'Duration of change'
}

export const ChangeConfirmation: React.FC = () => {
  const toChange = useSelector((state: RootState) => state.initiatives.toChange)

  const header = <ModalHeader ariaCloseTxt="Close confirmation" />

  const handleClose = () => store.dispatch(initiativesSlice.actions.setToChange(null))

  return (
    <Modal visible={Boolean(toChange)} handleCloseBtn={handleClose}>
      <Prompt
        className={styles.ConfirmationModal}
        title={`Update field: ${Fields[toChange?.name || '']}`}
        footer={null}
        header={header}
      >
        <Text className={styles.ModalText} bodySize="l">
          Please provide a brief comment explaining the reason for updating the {`'${Fields[toChange?.name || '']}'`}{' '}
          field to {`'${toChange?.value}'`}
        </Text>
        <Formik initialValues={{ comment: '' }} validationSchema={ChangeModalSchema} onSubmit={changeInitiativeFields}>
          <Form>
            <TextArea label="Enter your comment here..." name="comment" />
            <div className={styles.ButtonHolder}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Confirm Update
              </Button>
            </div>
          </Form>
        </Formik>
      </Prompt>
    </Modal>
  )
}
