import React from 'react'
import styles from '../styles/Privacy.module.scss'
import Text from '@ingka/text'
import Hyperlink from '@ingka/hyperlink'
import { DynamicTranslations } from '@/components/translations/Translations'

export const PrivacyPolicy = () => {
  return (
    <div className={styles.PrivacyWrap}>
      <div className={styles.Privacy}>
        <Text className={styles.Head} headingSize="m">
          <DynamicTranslations id="privacy_policy.title" />
        </Text>
        <Text className={styles.Block} bodySize="m">
          <DynamicTranslations id="privacy_policy.p1" />
        </Text>
        <div className={styles.Block}>
          <Text headingSize="s">
            <DynamicTranslations id="privacy_policy.p2_head" />
          </Text>
          <Text bodySize="m">
            <DynamicTranslations id="privacy_policy.p2" />
          </Text>
        </div>
        <div className={styles.Block}>
          <Text headingSize="s">
            <DynamicTranslations id="privacy_policy.p3_head" />
          </Text>
          <Text bodySize="m">
            <DynamicTranslations id="privacy_policy.p3" />
          </Text>
        </div>
        <div className={styles.Block}>
          <Text headingSize="s">
            <DynamicTranslations id="privacy_policy.p4_head" />
          </Text>
          <Text bodySize="m">
            <DynamicTranslations id="privacy_policy.p4" />
          </Text>
        </div>
        <div className={styles.Block}>
          <Text headingSize="s">
            <DynamicTranslations id="privacy_policy.p5_head" />
          </Text>
          <Text bodySize="m">
            <DynamicTranslations id="privacy_policy.p5" />
          </Text>
        </div>
        <div className={styles.Block}>
          <Text headingSize="s">
            <DynamicTranslations id="privacy_policy.p6_head" />
          </Text>
          <Text bodySize="m">
            <DynamicTranslations id="privacy_policy.p6" />
          </Text>
        </div>
        <div className={styles.Block}>
          <Text bodySize="m">
            <DynamicTranslations id="privacy_policy.footer1" />{' '}
            <Hyperlink
              text="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx"
              url="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx"
              target="_blank"
            />{' '}
            <DynamicTranslations id="privacy_policy.footer2" />
          </Text>
        </div>
      </div>
    </div>
  )
}
