import React from 'react'
import { getSiteList } from '@/pages/ReportForm/services'
import { store } from '@/storage/store'
import { sitesSlice } from '@/storage/sites'
import { mapFromSitesToCountries } from '@/utils/countries'
import { mapFromSitesToFunctionCode } from '@/utils/functionCodes'
import { loadingSlice } from '@/storage/loading'
import { Loader } from '@/components/loader/Loader'
import { SITE_DATA_LOAD } from '@/constants/loaders'

export const SitesProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props

  React.useEffect(() => {
    store.dispatch(loadingSlice.actions.setLoading({ name: SITE_DATA_LOAD, value: true }))
    ;(async () => {
      const list = await getSiteList()
      const countries = mapFromSitesToCountries(list)
      const functionCodes = mapFromSitesToFunctionCode(list)
      store.dispatch(sitesSlice.actions.setSites(list))
      store.dispatch(sitesSlice.actions.setCountries(countries))
      store.dispatch(sitesSlice.actions.setFunctionCodes(functionCodes))

      store.dispatch(loadingSlice.actions.setLoading({ name: SITE_DATA_LOAD, value: false }))
    })()
  }, [])

  return <Loader name="SITE_DATA_LOAD">{children}</Loader>
}
