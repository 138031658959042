import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { QuickDataCard, QuickDataCardFY24 } from '@/pages/Analytics/components/QuickDataCard'
import {
  getBudgetSpent,
  getChangeMakers,
  getHoursSpent,
  getPositivelyImpacted
} from '@/pages/Analytics/utils/dataHandlers'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'

export const QuickInfoBlock = () => {
  const fy = useSelector((state: RootState) => state.filters.filters.fy)

  if (new Date(fy?.value || '').getFullYear() === 2023) {
    return (
      <div className={styles.QuickInfoBlockFY24}>
        <QuickDataCardFY24
          title="data_reporting.social_impact.title"
          subTitle="data_reporting.social_impact.sub_title_fy24"
          handler={getPositivelyImpacted}
        />
        <QuickDataCardFY24
          title="data_reporting.change_maker.title"
          subTitle="data_reporting.change_maker.sub_title_fy24"
          handler={getChangeMakers}
        />
      </div>
    )
  }

  return (
    <div className={styles.QuickInfoBlock}>
      <QuickDataCard
        title="data_reporting.social_impact.title"
        subTitle="data_reporting.social_impact.sub_title"
        handler={getPositivelyImpacted}
      />
      <QuickDataCard
        title="data_reporting.change_maker.title"
        subTitle="data_reporting.change_maker.sub_title"
        handler={getChangeMakers}
      />
      <QuickDataCard
        title="data_reporting.coworker_hours.title"
        subTitle="data_reporting.coworker_hours.sub_title"
        handler={getHoursSpent}
      />
      <QuickDataCard
        title="data_reporting.spent.title"
        subTitle="data_reporting.spent.sub_title"
        handler={getBudgetSpent}
      />
    </div>
  )
}
