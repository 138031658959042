import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { store } from '@/storage/store'
import { mapToExport } from '@/pages/Explore/utils/mapper'

export const exportData = () => {
  const data = store.getState().explore.initiatives
  const mappedData = mapToExport(data)

  const worksheet = XLSX.utils.json_to_sheet(mappedData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
  saveAs(blob, `initiatives.xlsx`)
}
