import React from 'react'
import Text from '@ingka/text'
import { DynamicTranslations } from '@/components/translations/Translations'

export const Description: React.FC = () => {
  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.description.title" />
      </Text>
      <Text bodySize="l">
        <DynamicTranslations id="guide.description.body" />
      </Text>
    </>
  )
}
