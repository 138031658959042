import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { InitiativeAttachmentType, InitiativeFormType } from '@/types/initiative'
import { SiteOption } from '@/types/siteList'

export type AttachmentFileType = {
  id: string
  progress: number
  fileName: string
  fileType: string
  size: number
  fileSize: number
  fileUrl?: string
  error?: string
}

type StoreType = {
  sites: SiteOption[]
  modalType: string | null
  initiative?: InitiativeFormType
  isLoading: boolean
  confirmSubmit: boolean
  attachments: AttachmentFileType[]
  attachmentToDelete: number | null
  userId?: string
  rejectedByGlobal?: boolean
}

const initialState = {
  attachments: [],
  sites: [],
  modalType: null,
  isLoading: true,
  confirmSubmit: false,
  attachmentToDelete: null
} as StoreType

export const formSlice = createSlice({
  name: 'reportForm',
  initialState,
  reducers: {
    setSites(state, action: PayloadAction<SiteOption[]>) {
      state.sites = action.payload
    },
    changeModal(state, action: PayloadAction<string | null>) {
      state.modalType = action.payload
    },
    setInitiative(
      state,
      action: PayloadAction<{
        initiative: InitiativeFormType
        sites: SiteOption[]
        userId?: string
        rejectedByGlobal?: boolean
      }>
    ) {
      state.initiative = action.payload.initiative
      state.sites = action.payload.sites
      state.userId = action.payload.userId
      state.rejectedByGlobal = action.payload.rejectedByGlobal
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setConfirmSubmit(state, action: PayloadAction<boolean>) {
      state.confirmSubmit = action.payload
    },
    setAttachments(state, action: PayloadAction<InitiativeAttachmentType[]>) {
      state.attachments = action.payload.map(item => ({ ...item, progress: 100, size: 0 }))
    },
    addFileToAttachments(state, action: PayloadAction<AttachmentFileType>) {
      state.attachments.push(action.payload)
    },
    changeAttachment(state, action: PayloadAction<{ index: number; file: AttachmentFileType }>) {
      state.attachments[action.payload.index] = action.payload.file
    },
    removeAttachment(state, action: PayloadAction<number>) {
      state.attachments = state.attachments.filter((item, index) => index !== action.payload)
    },
    setAttachmentToDelete(state, action: PayloadAction<number | null>) {
      state.attachmentToDelete = action.payload
    },
    reset() {
      return initialState
    }
  }
})
