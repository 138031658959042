import { collection, getDocs, addDoc, getDoc, doc } from 'firebase/firestore'
import { db } from '@/store'
import { SiteType } from '@/types/siteList'
import { InitiativeDBType, InitiativeType } from '@/types/initiative'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { changeUpdatedAt } from '@/utils/db'
import { updateDoc } from '@firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from '@firebase/storage'
import { firebaseStorage } from '@/storage/firebase'
import { store } from '@/storage/store'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { showToast } from '@/components/toast/ToastContainer'
import { v4 as uuidv4 } from 'uuid'

export const getSiteList = async (): Promise<SiteType[]> => {
  const query = await getDocs(collection(db, 'site_list'))
  return query.docs.map(x => x.data()) as SiteType[]
}

export const setInitiative = async (initiative: InitiativeType): Promise<InitiativeDBType> => {
  const reference = await addDoc(collection(db, 'initiatives'), { ...initiative, created_at: new Date() })
  await changeUpdatedAt(reference)
  const data = await getDoc(reference)

  return getLatestVersion(data)
}

export const editInitiative = async (id: string, data: Partial<InitiativeType>): Promise<InitiativeDBType> => {
  const docRef = doc(db, 'initiatives', id)
  await updateDoc(docRef, data)
  await changeUpdatedAt(docRef)
  const initiativeEdited = await getDoc(docRef)

  return getLatestVersion(initiativeEdited)
}

export async function getInitiative(id: string): Promise<InitiativeDBType> {
  const initiative = await getDoc(doc(db, 'initiatives', id))
  return getLatestVersion(initiative)
}

export const uploadFile = async (file: File, index: number) => {
  const userId = store.getState().auth.user?.id

  const attachmentData = {
    id: uuidv4(),
    progress: 0,
    fileName: file.name,
    fileType: file.type,
    size: 0,
    fileSize: file.size
  }

  store.dispatch(formSlice.actions.addFileToAttachments(attachmentData))

  const storageRef = ref(firebaseStorage, `docs/${userId}/${attachmentData.id}${file.name}`)

  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      store.dispatch(
        formSlice.actions.changeAttachment({
          index,
          file: {
            ...attachmentData,
            size: snapshot.bytesTransferred,
            progress
          }
        })
      )
    },
    error => {
      showToast('File is not uploaded')
      console.error(error)
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(downloadUrl => {
        store.dispatch(
          formSlice.actions.changeAttachment({
            index,
            file: {
              ...attachmentData,
              progress: 100,
              fileUrl: downloadUrl
            }
          })
        )
      })
    }
  )
}

export const removeFileFromStorage = async (id: string, fileName: string) => {
  const userId = store.getState().auth.user?.id
  const fileRef = ref(firebaseStorage, `docs/${userId}/${id}${fileName}`)

  await deleteObject(fileRef).catch(error => showToast(error))
}
