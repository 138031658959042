import { ActivityDBType, ActivityItemType } from '@/types/activity'
import { format } from 'date-fns'

export const mapToActivityList = (data: ActivityDBType[]): ActivityItemType[] => {
  return data.map(item => {
    return {
      ...item,
      date: item.date && format(item.date.toDate(), 'd MMMM yyyy HH:mm')
    } as unknown as ActivityItemType
  })
}
