import { ALL_OPTION, IMPACT_TYPES, IMPACT_TYPES_FY24 } from '@/constants/common'
import { Option } from '@/types/common'
import { SiteType } from '@/types/siteList'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import {
  DURATION_ITEMS,
  DURATION_ITEMS_FY24,
  LEVEL_ITEMS,
  LEVEL_ITEMS_FY24
} from '@/pages/ReportForm/constants/options'

export const getImpactTypeFY24 = (initiative: InitiativesListItemType) => {
  if (initiative.duration === DURATION_ITEMS_FY24.short && initiative.level === LEVEL_ITEMS_FY24.limited)
    return IMPACT_TYPES_FY24.connect
  if (initiative.duration === DURATION_ITEMS_FY24.medium && initiative.level === LEVEL_ITEMS_FY24.limited)
    return IMPACT_TYPES_FY24.connect
  if (initiative.duration === DURATION_ITEMS_FY24.short && initiative.level === LEVEL_ITEMS_FY24.substantive)
    return IMPACT_TYPES_FY24.connect
  if (initiative.duration === DURATION_ITEMS_FY24.long && initiative.level === LEVEL_ITEMS_FY24.significant)
    return IMPACT_TYPES_FY24.transform

  return IMPACT_TYPES_FY24.improve
}

export const getImpactType = (initiative: InitiativesListItemType) => {
  if (initiative.duration === DURATION_ITEMS.short && initiative.level === LEVEL_ITEMS.limited)
    return IMPACT_TYPES.light

  return IMPACT_TYPES.strong
}

export const getAnalysisSeries = (data: InitiativesListItemType[], withConnect?: boolean) => {
  const improve = data.filter(item => getImpactTypeFY24(item) === IMPACT_TYPES_FY24.improve).length
  const transform = data.filter(item => getImpactTypeFY24(item) === IMPACT_TYPES_FY24.transform).length

  const series = [
    {
      name: 'data_reporting.analysis.improve',
      color: '#489CE3',
      value: improve
    },
    {
      name: 'data_reporting.analysis.transform',
      color: '#F26A2F',
      value: transform
    }
  ]

  if (withConnect) {
    const connect = data.filter(item => getImpactTypeFY24(item) === IMPACT_TYPES_FY24.connect).length

    series.push({
      name: 'data_reporting.analysis.connect',
      color: '#E52F7E',
      value: connect
    })
  }

  return series
}

export const getAnalysisNewSeries = (data: InitiativesListItemType[], withConnect?: boolean) => {
  const strong = data.filter(item => getImpactType(item) === IMPACT_TYPES.strong).length

  const series = [
    {
      name: 'data_reporting.analysis.strong',
      color: '#489CE3',
      value: strong
    }
  ]

  if (withConnect) {
    const light = data.filter(item => getImpactType(item) === IMPACT_TYPES.light).length

    series.push({
      name: 'data_reporting.analysis.light',
      color: '#F26A2F',
      value: light
    })
  }

  return series
}

type Filters = {
  report_period?: Option
  status?: Option
  country_code?: Option
  function_code?: Option
}

export const getReportingStatusCountries = (data: InitiativesListItemType[], countries: Option[]) => {
  const reportedCount = data.reduce((result, current) => {
    if (result.find(item => item === current.country_code)) return result
    return [...result, current.country_code] as string[]
  }, [] as string[]).length
  const notReportedCount = countries.length - reportedCount

  return [
    {
      name: 'data_reporting.reporting_status.countries_reported',
      color: '#003E72',
      value: reportedCount
    },
    {
      name: 'data_reporting.reporting_status.countries_not_reported',
      color: '#CCC',
      value: notReportedCount
    }
  ]
}

export const getReportedStatusUnits = (data: InitiativesListItemType[], sites: SiteType[], filters: Filters) => {
  const reportedCount = data.reduce((result, current) => {
    if (!current.site) return result
    if (result.find(item => item === current.site)) return result
    return [...result, current.site]
  }, [] as string[]).length
  const notReportedCount =
    sites.filter(item => {
      const byCountry =
        filters.country_code?.value === ALL_OPTION.value ? true : item.country_code === filters.country_code?.value
      const byFunctionCode =
        filters.function_code?.value === ALL_OPTION.value ? true : item.function_code === filters.function_code?.value
      return byCountry && byFunctionCode
    }).length - reportedCount

  return [
    {
      name: 'data_reporting.reporting_status.units_reported',
      color: '#003E72',
      value: reportedCount
    },
    {
      name: 'data_reporting.reporting_status.units_not_reported',
      color: '#CCC',
      value: notReportedCount
    }
  ]
}
