import React, { CSSProperties } from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const Coworkers: React.FC = () => {
  const gridColumns: CSSProperties = { gridTemplateColumns: '1fr 2fr 2fr' }

  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.coworker.title" />
      </Text>
      <Text bodySize="l" style={{ marginBottom: '20px' }}>
        <DynamicTranslations id="guide.coworker.body1" withTags />
      </Text>
      <div className={styles.Table}>
        <DynamicTranslations
          id="guide.coworker.table_head"
          className={styles.TableRowHead}
          withTags
          style={gridColumns}
        />
        <DynamicTranslations id="guide.coworker.table_body" className={styles.TableRow} withTags style={gridColumns} />
      </div>
    </>
  )
}
