import React from 'react'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import Text from '@ingka/text'
import styles from '@/pages/Initiatives/styles/Confirmation.module.scss'
import { useFormikContext } from 'formik'
import { TextArea } from '@/components/form/textarea/TextArea'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { editData } from '@/pages/ReportForm/utils/submit'
import { useNavigate, useParams } from 'react-router-dom'
import { InitiativeFormType } from '@/types/initiative'
import { DynamicTranslations } from '@/components/translations/Translations'

export const SubmitConfirmation: React.FC<{ type: string }> = props => {
  const { type } = props
  const confirmSubmit = useSelector((state: RootState) => state.form.confirmSubmit)
  const isOwner = useSelector((state: RootState) => state.form.userId === state.auth.user?.id || !state.form.userId)
  const header = <ModalHeader ariaCloseTxt="Close confirmation" />
  const { values, ...rest } = useFormikContext<InitiativeFormType>()
  const { initiativeId } = useParams()
  const navigate = useNavigate()

  const afterSubmit = () => {
    navigate('/')
  }

  const handleClose = () => {
    rest.setSubmitting(false)
    store.dispatch(formSlice.actions.setConfirmSubmit(false))
  }
  const handleSubmit = () => {
    if (!values.submitReason) {
      const helpers = rest.getFieldHelpers('submitReason')
      helpers.setError('Field is required')
      return
    }
    editData(String(initiativeId), type, afterSubmit)(values, rest)
  }

  return (
    <Modal visible={confirmSubmit} handleCloseBtn={handleClose}>
      <Prompt
        className={styles.ConfirmationModal}
        title={<DynamicTranslations id={isOwner ? 'form.re_submit_confirm.title' : 'form.submit_confirm.title'} />}
        footer={null}
        header={header}
      >
        <Text className={styles.ModalText} bodySize="l">
          <DynamicTranslations id="form.submit_confirm.description" />
        </Text>
        <TextArea label="Enter your comment here..." name="submitReason" />
        <div className={styles.ButtonHolder}>
          <Button onClick={handleClose}>
            <DynamicTranslations id="cancel" />
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            <DynamicTranslations id={isOwner ? 'confirm_re_submit' : 'confirm_submit'} />
          </Button>
        </div>
      </Prompt>
    </Modal>
  )
}
