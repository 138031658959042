import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ActivityItemType } from '@/types/activity'

type StoreType = {
  list: ActivityItemType[]
}

const initialState = {
  list: []
} as StoreType

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivities(state, action: PayloadAction<ActivityItemType[]>) {
      state.list = action.payload
    },
    reset() {
      return initialState
    }
  }
})
