import React from 'react'
import clx from 'classnames'
import InputFieldIngka, { InputFieldProps } from '@ingka/input-field'
import FormField, { FormFieldProps } from '@ingka/form-field'
import { useField, FieldHookConfig } from 'formik'
import styles from './Input.module.scss'

type Props = FieldHookConfig<string> &
  FormFieldProps &
  InputFieldProps & {
    id: string
    type: string
  }

export const InputField: React.FC<Props> = props => {
  const { id, type, label, fieldHelper, className } = props
  const [field, meta] = useField(props)

  return (
    <FormField
      data-field-error={Boolean(meta.error)}
      fieldHelper={fieldHelper}
      className={clx(styles.Field, className)}
      valid={!meta.error}
      shouldValidate={Boolean(meta.touched && meta.error)}
      validation={{
        id: `validation-${field.name}`,
        msg: meta.error,
        type: 'error'
      }}
    >
      <InputFieldIngka {...field} value={field.value || ''} id={id} type={type} label={label} />
    </FormField>
  )
}
