import React from 'react'
import { notificationsSubscribe } from '@/pages/Notifications/services'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { notificationsSlice } from '@/pages/Notifications/slices/notifications'
import { mapToList } from '@/pages/Notifications/utils/mapper'

export const NotificationsProvider = () => {
  const user = useSelector((state: RootState) => state.auth.user)

  React.useEffect(() => {
    if (!user) return

    notificationsSubscribe(user.id, notifications => {
      const mapped = mapToList(notifications)
      store.dispatch(notificationsSlice.actions.setNotifications(mapped))
    })
  }, [user?.id])

  return null
}
