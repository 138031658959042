import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CommentItemType } from '@/types/comments'

type StoreType = {
  list: CommentItemType[]
}

const initialState = {
  list: []
} as StoreType

export const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments(state, action: PayloadAction<CommentItemType[]>) {
      state.list = action.payload
    },
    reset() {
      return initialState
    }
  }
})
