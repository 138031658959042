import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserData } from '@/types/user'
import { Option } from '@/types/common'
import { ALL_OPTION } from '@/constants/common'

export type UsersFiltersType = {
  search: string
  country: Option
  role: Option
}

type StoreType = {
  users: UserData[]
  filters: UsersFiltersType
}

const initialState = {
  users: [],
  filters: {
    search: '',
    country: ALL_OPTION,
    role: ALL_OPTION
  }
} as StoreType

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserData[]>) {
      state.users = action.payload
    },
    setFilters(state, action: PayloadAction<UsersFiltersType>) {
      state.filters = action.payload
    },
    reset() {
      return initialState
    }
  }
})
