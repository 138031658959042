import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AnalyticsRecordItemType } from '@/types/analytics'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'

type StoreType = {
  data: AnalyticsRecordItemType[]
  initiatives: InitiativesListItemType[]
  showCountriesModal: boolean
}

const initialState = {
  data: [],
  initiatives: [],
  showCountriesModal: false
} as StoreType

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<AnalyticsRecordItemType[]>) {
      state.data = action.payload
    },
    setInitiatives(state, action: PayloadAction<InitiativesListItemType[]>) {
      state.initiatives = action.payload
    },
    toggleCountriesModal(state, action: PayloadAction<boolean>) {
      state.showCountriesModal = action.payload
    },
    reset() {
      return initialState
    }
  }
})
