import React from 'react'
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import Text from '@ingka/text'
import styles from '../../styles/Confirmation.module.scss'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { handleDeleteInitiative, handleToggleDeleteConfirmation } from '@/pages/Initiatives/utils/remove'
import { useSearchParams } from 'react-router-dom'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { DynamicTranslations, useTranslation } from '@/components/translations/Translations'

export const DeleteConfirmation: React.FC = () => {
  const toDeleteId = useSelector((state: RootState) => state.initiatives.toDeleteId)
  const header = <ModalHeader ariaCloseTxt="Close confirmation" />
  const [searchParams, setSearchParams] = useSearchParams()

  const title = useTranslation('initiatives.confirmation.delete.title')

  const onDelete = () => {
    if (searchParams.get('preview_id')) {
      setSearchParams({})
      store.dispatch(initiativesSlice.actions.setPreviewDetails(null))
    }

    handleDeleteInitiative()
  }

  const footer = (
    <ModalFooter>
      <Button onClick={handleToggleDeleteConfirmation(null)}>
        <DynamicTranslations id="initiatives.confirmation.reject.cancel" />
      </Button>
      <Button type="primary" onClick={onDelete}>
        <DynamicTranslations id="initiatives.confirmation.delete.delete" />
      </Button>
    </ModalFooter>
  )

  return (
    <Modal visible={Boolean(toDeleteId)} handleCloseBtn={handleToggleDeleteConfirmation(null)}>
      <Prompt title={title} footer={footer} header={header}>
        <Text className={styles.ModalText} bodySize="l">
          <DynamicTranslations id="initiatives.confirmation.delete.body" />
        </Text>
      </Prompt>
    </Modal>
  )
}
