import React from 'react'
import styles from '../styles/ActivityTab.module.scss'
import Text from '@ingka/text'
import { STATUSES } from '@/constants/statuses'
import { StatusTag } from '@/pages/Initiatives/components/Status'
import { ActivityProvider } from '@/pages/Activity/providers/ActivityProvider'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { getPhotoPlaceholder } from '@/utils/getPhotoPlaceholder'
import { Show } from '@/components/conditions/Show'
import { UserPhoto } from '@/components/avatar/UserPhoto'
import { ActivityType } from '@/types/activity'
import { ACTIVITY_TYPES } from '@/constants/common'
import { DynamicTranslations } from '@/components/translations/Translations'

const getDescription = (activity: ActivityType) => {
  const { type, comment, status, changes } = activity

  if (type === ACTIVITY_TYPES.recall) return <DynamicTranslations id="initiatives.activity.recall" />

  if (type === ACTIVITY_TYPES.comment) return <DynamicTranslations id="initiatives.comments.added" />

  if (type === ACTIVITY_TYPES.change && changes)
    return (
      <>
        <DynamicTranslations id="initiatives.activity.updated" /> {getLabel('names', changes[0].name)}:
      </>
    )

  if (status === STATUSES[0] && Boolean(comment))
    return <DynamicTranslations id="initiatives.activity.rejected_with_comment" />
  if (status === STATUSES[0]) return <DynamicTranslations id="initiatives.activity.rejected" />

  return <DynamicTranslations id="initiatives.activity.submitted" />
}

const getLabel = (prefix: string, field?: string) => {
  if (!field) return <></>
  return <DynamicTranslations id={`fields.${prefix}.${field.toLowerCase()}`} />
}

const ActivityTabImpl = () => {
  const activities = useSelector((state: RootState) => state.activity.list)

  return (
    <div className={styles.ActivityTab}>
      {activities.map(item => {
        const photoPlaceholder = getPhotoPlaceholder(item.user.name)

        return (
          <div key={`activity-${item.id}`} className={styles.ActivityItem}>
            <div className={styles.BaseInfoWrap}>
              <UserPhoto placeholder={photoPlaceholder} photoUrl={item.user.photo_url} />
              <div className={styles.BaseInfo}>
                <div className={styles.UserName}>{item.user.name}</div>
                <Text bodySize="m" className={styles.Description}>
                  {getDescription(item)}
                </Text>
                <Show when={Boolean(item.status)}>
                  <StatusTag status={item.status as 'rejected'} />
                </Show>
                <Text bodySize="s" className={styles.Date}>
                  {item.date}
                </Text>
                <Show when={Boolean(item.changes)}>
                  <Text className={styles.CommentInfo} bodySize="m">
                    {getLabel('values', item.changes && item.changes[0].before)} -{'>'}{' '}
                    {getLabel('values', item.changes && item.changes[0].after)}
                  </Text>
                </Show>
                <Show when={Boolean(item.comment)}>
                  <Text className={styles.CommentInfo} bodySize="m">
                    {item.comment}
                  </Text>
                </Show>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const ActivityTab = () => {
  return (
    <ActivityProvider>
      <ActivityTabImpl />
    </ActivityProvider>
  )
}
