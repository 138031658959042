import React from 'react'
import Toast from '@ingka/toast'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { toastSlice } from '@/components/toast/store'

export const ToastContainer = () => {
  const { show, message } = useSelector((state: RootState) => state.toast)

  return (
    <Toast
      text={message || ''}
      isOpen={show}
      onCloseRequest={() => {
        store.dispatch(toastSlice.actions.setToast({ show: false }))
      }}
      ariaLabelCloseBtn="Dismiss notification"
    />
  )
}

export const showToast = (message: string) => {
  if (!store.getState().toast.show) {
    store.dispatch(toastSlice.actions.setToast({ message, show: true }))
    return
  }

  store.dispatch(toastSlice.actions.reset())
  setTimeout(() => {
    store.dispatch(toastSlice.actions.setToast({ message, show: true }))
  }, 300)
}
