import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { NotificationType } from '@/types/notifications'

type StoreType = {
  list: NotificationType[]
}

const initialState = {
  list: []
} as StoreType

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<NotificationType[]>) {
      state.list = action.payload
    },
    reset() {
      return initialState
    }
  }
})
