import React from 'react'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { DynamicTranslations } from '@/components/translations/Translations'
import { TYPE_OF_INITIATIVE_OPTIONS } from '@/pages/ReportForm/constants/options'
import { CheckboxGroup } from '@/components/form/checkbox/CheckboxGroup'
import { Show } from '@/components/conditions/Show'
import { InputField } from '@/components/form/input/InputField'
import { useFormikContext } from 'formik'
import { InitiativeFormType } from '@/types/initiative'

export const TypeOfInitiative = () => {
  const { values } = useFormikContext<InitiativeFormType>()

  const showCondition = React.useMemo(() => {
    return Boolean((values.type_of_initiative || []).find(item => item.value === TYPE_OF_INITIATIVE_OPTIONS[2].value))
  }, [values.type_of_initiative])

  return (
    <>
      <CheckboxGroup
        name={FIELD_NAME.type_of_initiative}
        label={<DynamicTranslations id="form.field.type_of_initiative.label" />}
        list={TYPE_OF_INITIATIVE_OPTIONS}
        fieldHelper={{
          id: 'type_of_other-hlp',
          msg: <DynamicTranslations id="form.field.type_of_initiative.helper" />
        }}
      />
      <Show when={showCondition}>
        <InputField
          id={FIELD_NAME.participants_of_skills_development}
          type="number"
          name={FIELD_NAME.participants_of_skills_development}
          label={<DynamicTranslations id="form.field.participants_of_skills_development.label" />}
          fieldHelper={{
            id: 'participants_of_skills_development-hlp',
            msg: <DynamicTranslations id="form.field.participants_of_skills_development.helper" />
          }}
        />
      </Show>
    </>
  )
}
