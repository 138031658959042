import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import SSRIcon from '@ingka/ssr-icon'
import ChevronUpSmallIcon from '@ingka/ssr-icon/paths/chevron-up-small'
import ChevronDownSmallIcon from '@ingka/ssr-icon/paths/chevron-down-small'
import Text from '@ingka/text'
import clx from 'classnames'
import { TableDataType } from '@/pages/Analytics/utils/table'
import { isEmpty } from 'lodash'
import { Show } from '@/components/conditions/Show'
import { DynamicTranslations } from '@/components/translations/Translations'
import { formatNumber } from '@/utils/format'

type RowItemProps = React.PropsWithChildren & {
  title: string
  peopleSupported: number
  coworkersEngaged: number
  customerCitizensEngaged: number
  hours: number
  spent: number
  showIcon: boolean
}

export const RowItemWrap: React.FC<RowItemProps> = props => {
  const { children, title, peopleSupported, coworkersEngaged, customerCitizensEngaged, showIcon, hours, spent } = props

  const [show, setShow] = React.useState(false)

  return (
    <div className={styles.TableRowWrap}>
      <div className={styles.TableRow}>
        <div className={styles.RowItemToggle} onClick={() => setShow(!show)}>
          <Show when={showIcon}>
            <div className={styles.RowItemToggleIcon}>
              <SSRIcon paths={show ? ChevronUpSmallIcon : ChevronDownSmallIcon} />
            </div>
          </Show>
          <Show when={!showIcon}>
            <div className={styles.ToggleIconHolder} />
          </Show>
          <DynamicTranslations id={title} />
        </div>
        <div />
        <div className={styles.Right}>
          <Text headingSize="xs">{formatNumber(peopleSupported)}</Text>
        </div>
        <div className={styles.Right}>
          <Text headingSize="xs">{formatNumber(coworkersEngaged)}</Text>
        </div>
        <div className={styles.Right}>
          <Text headingSize="xs">{formatNumber(customerCitizensEngaged)}</Text>
        </div>
        <div className={styles.Right}>
          <Text headingSize="xs">{formatNumber(hours)}</Text>
        </div>
        <div className={styles.Right}>
          <Text headingSize="xs">{formatNumber(spent)}</Text>
        </div>
      </div>
      <div className={clx(styles.ExpandableBlock, show ? styles.Show : null)}>{children}</div>
    </div>
  )
}

type Props = {
  title: string
  tableData: TableDataType
}

export const RowItem: React.FC<Props> = props => {
  const { title, tableData } = props

  return (
    <RowItemWrap
      title={title}
      peopleSupported={tableData.total.participants_count}
      coworkersEngaged={tableData.total.coworkers_engaged_count}
      customerCitizensEngaged={tableData.total.customers_engaged_count}
      hours={tableData.total.hours}
      spent={tableData.total.spent}
      showIcon={!isEmpty(tableData.data)}
    >
      {tableData.data.map(item => {
        return (
          <div className={styles.ExpandableBlockItem} key={`${title}-item-${item.label}`}>
            <div />
            <Text bodySize="m" className={clx(styles.Cell, item.isHighlighted ? styles.Highlighted : null)}>
              <DynamicTranslations id={item.label} />
            </Text>
            <Text bodySize="m" className={styles.Right}>
              {formatNumber(item.participants_count)}
            </Text>
            <Text bodySize="m" className={styles.Right}>
              {formatNumber(item.coworkers_engaged_count)}
            </Text>
            <Text bodySize="m" className={styles.Right}>
              {formatNumber(item.customers_engaged_count)}
            </Text>
            <Text bodySize="m" className={styles.Right}>
              {formatNumber(item.hours)}
            </Text>
            <Text bodySize="m" className={styles.Right}>
              {formatNumber(item.spent)}
            </Text>
          </div>
        )
      })}
    </RowItemWrap>
  )
}
