import { ImpactType, ImpactTypeFY24 } from '@/types/analytics'
import { cloneDeep, sortBy } from 'lodash'
import { Option } from '@/types/common'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { getImpactType, getImpactTypeFY24 } from '@/pages/Analytics/utils/donut'

type TableDataListType = {
  label: string
  isHighlighted: boolean

  participants_count: number
  coworkers_engaged_count: number
  customers_engaged_count: number
  change_makers_count: number
  hours: number
  spent: number

  country_code: string
  site: string
  function_code: string
}

export type TableDataType = {
  total: {
    participants_count: number
    coworkers_engaged_count: number
    customers_engaged_count: number
    change_makers_count: number
    hours: number
    spent: number
  }
  data: TableDataListType[]
}

export const getTableDataFY24 = (
  data: InitiativesListItemType[],
  impactType: ImpactTypeFY24,
  countries: Option[],
  showByUnit: boolean
) => {
  const copy = cloneDeep(data)
  const filtered = sortBy(
    copy.filter(item => getImpactTypeFY24(item) === impactType),
    'site'
  )

  return mapToTableData(filtered, countries, showByUnit)
}

export const getTableData = (
  data: InitiativesListItemType[],
  impactType: ImpactType,
  countries: Option[],
  showByUnit: boolean
) => {
  const copy = cloneDeep(data)
  const filtered = sortBy(
    copy.filter(item => getImpactType(item) === impactType),
    'site'
  )

  return mapToTableData(filtered, countries, showByUnit)
}

export const mapToTableData = (
  data: InitiativesListItemType[],
  countries: Option[],
  showByUnit: boolean
): TableDataType => {
  const aggregatedData = data.reduce((result: TableDataListType[], current: InitiativesListItemType) => {
    const index = result.findIndex(item => {
      if (showByUnit) {
        return item.site === current.site && item.function_code === current.function_code
      }
      return item.country_code === current.country_code
    })

    if (index !== -1) {
      result[index].participants_count += current.people_supported_count || 0
      result[index].coworkers_engaged_count += current.coworkers_count || 0
      result[index].customers_engaged_count += current.customers_count || 0
      result[index].change_makers_count += (current.coworkers_count || 0) + (current.customers_count || 0)
      result[index].hours += current.coworker_hours || 0
      result[index].spent += current.spent || 0

      return result
    }

    const country = countries.find(item => item.value === current.country_code)

    const label = showByUnit ? current.site || `${country?.label} - ${current.function_code}` : country?.label

    return [
      ...result,
      {
        participants_count: current.people_supported_count || 0,
        coworkers_engaged_count: current.coworkers_count || 0,
        customers_engaged_count: current.customers_count || 0,
        change_makers_count: (current.coworkers_count || 0) + (current.customers_count || 0),
        hours: current.coworker_hours || 0,
        spent: current.spent || 0,
        label,
        isHighlighted: showByUnit ? !current.site : false,
        site: current.site,
        country_code: current.country_code,
        function_code: current.function_code
      }
    ] as TableDataListType[]
  }, []) as TableDataListType[]

  const total = aggregatedData.reduce(
    (result, current) => {
      return {
        participants_count: result.participants_count + current.participants_count,
        coworkers_engaged_count: result.coworkers_engaged_count + current.coworkers_engaged_count,
        customers_engaged_count: result.customers_engaged_count + current.customers_engaged_count,
        change_makers_count: result.change_makers_count + current.change_makers_count,
        hours: result.hours + current.hours,
        spent: result.spent + current.spent
      }
    },
    {
      participants_count: 0,
      coworkers_engaged_count: 0,
      customers_engaged_count: 0,
      change_makers_count: 0,
      hours: 0,
      spent: 0
    }
  )

  return {
    data: aggregatedData,
    total
  }
}
