import React from 'react'
import Text from '@ingka/text'
import SelectCountryImg from '@/assets/images/select_country_field.png'
import SelectSiteImg from '@/assets/images/select_site_field.png'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const CountryUnit: React.FC = () => {
  return (
    <div className={styles.Content}>
      <Text tagName="h1" headingSize="m">
        <DynamicTranslations id="guide.country.title" />
      </Text>
      <Text bodySize="m">
        <DynamicTranslations id="guide.country.body1" withTags />
      </Text>
      <img src={SelectCountryImg} alt="select_country" />
      <img src={SelectSiteImg} alt="select_site" />
      <Text bodySize="m" className={styles.ContentText}>
        <DynamicTranslations id="guide.country.body2" withTags />
      </Text>
      <Text bodySize="m" className={styles.ContentText}>
        <DynamicTranslations id="guide.country.body3" withTags />
      </Text>
      <Text bodySize="m" className={styles.ContentText}>
        <DynamicTranslations id="guide.country.body4" withTags />
      </Text>
    </div>
  )
}
