import React from 'react'
import clx from 'classnames'
import styles from '../styles/Initiatives.module.scss'
import { InitiativesStatusesType } from '@/types/initiative'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  status: InitiativesStatusesType
  className?: string
}

const STYLES = {
  rejected: styles.rejected,
  global_review: styles.global,
  submitted: styles.country,
  draft: styles.draft,
  ready_for_push: styles.ready_for_push,
  pushed: styles.pushed
}

export const StatusTag: React.FC<Props> = props => {
  const { status, className } = props

  return (
    <div className={clx(styles.Status, STYLES[status], className)}>
      <DynamicTranslations id={`status.${status}`} />
    </div>
  )
}
