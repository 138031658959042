import { editInitiative } from '@/pages/Initiatives/services'
import { store } from '@/storage/store'
import { trackActivity } from '@/pages/Activity/utils/trackActivity'
import { ACTIVITY_TYPES } from '@/constants/common'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'

export const setToChangeField = (name: string) => (value: string) => {
  store.dispatch(initiativesSlice.actions.setToChange({ name, value }))
}

export const changeInitiativeFields = async (values: { comment: string }) => {
  const { name: fieldName, value } = store.getState().initiatives.toChange || {}
  const user = store.getState().auth.user
  const details = store.getState().initiatives.details

  if (!user || !details || !fieldName || !value) return

  const updated_by = store.getState().auth.user?.id
  editInitiative(details.id, { [fieldName]: value, updated_by })

  await trackActivity({
    type: ACTIVITY_TYPES.change,
    user_id: user.id,
    initiative_id: details.id,
    comment: values.comment,
    changes: [
      {
        name: fieldName,
        before: details[fieldName as keyof InitiativesListItemType] as string,
        after: value
      }
    ]
  })
  store.dispatch(initiativesSlice.actions.setToChange(null))
}
