import React, { useState } from 'react'
import { FieldHookConfig, useField } from 'formik'
import FormField, { FormFieldProps } from '@ingka/form-field'
import SearchIcon from '@ingka/ssr-icon/paths/magnifying-glass'

import { Option } from '@/types/common'

import { List } from './List'
import styles from '@/components/form/checkbox/CheckboxGroup.module.scss'
import { Show } from '@/components/conditions/Show'
import { useOnClickOutside } from '@/components/hooks/outside'
import InputFieldIngka, { InputFieldProps } from '@ingka/input-field'

type Props = FieldHookConfig<Option> &
  FormFieldProps &
  Omit<InputFieldProps, 'list' | 'type'> & {
    list: Option[]
    id: string
  }

export const SearchCommon: React.FC<Props> = props => {
  const { list, fieldHelper, placeholder, id, label, disabled } = props
  const [field, meta, helpers] = useField(props)
  const [search, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    setOpen(false)
  })

  React.useEffect(() => {
    setSearchValue(field.value?.label)
  }, [field.value?.label])

  const onChoose = (option: Option) => () => {
    helpers.setValue(option)
    setSearchValue(option.label)
    setOpen(false)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const options = search ? list.filter(item => item.label.toUpperCase().indexOf(search.toUpperCase()) > -1) : list

  return (
    <FormField
      data-field-error={Boolean(meta.error)}
      fieldHelper={fieldHelper}
      className={styles.CheckboxGroup}
      valid={!meta.error}
      shouldValidate={Boolean(meta.touched && meta.error)}
      validation={{
        id: `validation-${field.name}`,
        msg: meta.error,
        type: 'error'
      }}
    >
      <div ref={ref}>
        <InputFieldIngka
          label={label}
          id={id}
          type="text"
          value={search || ''}
          onChange={onChange}
          ssrIcon={SearchIcon}
          placeholder={placeholder}
          iconPosition="leading"
          onFocus={() => setOpen(true)}
          disabled={disabled}
        />
        <Show when={open}>
          <List options={options} onClick={onChoose} />
        </Show>
      </div>
    </FormField>
  )
}
