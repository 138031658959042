import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import Loading, { LoadingBall } from '@ingka/loading'
import styles from './Loader.module.scss'

type Props = {
  name: string
}

export const Loader: React.FC<React.PropsWithChildren<Props>> = props => {
  const isLoading = useSelector((state: RootState) => state.loading[props.name])

  if (!isLoading) return <>{props.children}</>

  return (
    <div className={styles.LoadingWrap}>
      <Loading text="Loading">
        <LoadingBall />
      </Loading>
    </div>
  )
}
