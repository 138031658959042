import { rejectInitiative, updateInitiativeStatus } from '@/pages/Initiatives/services'
import { store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { InitiativesStatusesType } from '@/types/initiative'
import {
  STATUS_TO_LABEL,
  STATUSES,
  STATUSES_OPTIONS,
  TOAST_OLD_STRUCTURE_INITIATIVE,
  TOAST_STATUS_CHANGED
} from '@/constants/statuses'
import { trackActivity } from '@/pages/Activity/utils/trackActivity'
import { ACTIVITY_TYPES, ALL_OPTION, USER_ROLES } from '@/constants/common'
import { showToast } from '@/components/toast/ToastContainer'
import { loadActivities } from '@/pages/Activity/providers/ActivityProvider'
import { filtersSlice } from '@/storage/filters'

export const handleApproveCountryInitiative = (id: string, statusToApprove: InitiativesStatusesType) => async () => {
  const user = store.getState().auth.user
  const details = store.getState().initiatives.details
  if (!user) return

  if (details && details.fy === 2024) {
    showToast(TOAST_OLD_STRUCTURE_INITIATIVE)
    return
  }

  try {
    await updateInitiativeStatus(id, statusToApprove, user.id)
    await trackActivity({
      type: ACTIVITY_TYPES.status,
      user_id: user.id,
      initiative_id: id,
      status: statusToApprove
    })

    if (statusToApprove === STATUSES[4]) {
      store.dispatch(initiativesSlice.actions.setInitiativeStatus({ id, status: STATUSES[4] }))
      loadActivities(id)
      showToast(`${TOAST_STATUS_CHANGED} ${STATUS_TO_LABEL[statusToApprove]}`)
      return
    }

    showToast(`${TOAST_STATUS_CHANGED} ${STATUS_TO_LABEL[statusToApprove]}`)
  } catch (e) {
    console.error(e)
  }
}

const RejectTo: { [key in string]: InitiativesStatusesType } = {
  [STATUSES[2]]: STATUSES[1],
  [STATUSES[4]]: STATUSES[1],
  [STATUSES[1]]: STATUSES[0]
}

export const handleRejectInitiative = async (values: { comment: string }) => {
  const id = store.getState().initiatives.toRejectId
  const user = store.getState().auth.user
  if (!id || !user) return
  const initiative = store.getState().initiatives.initiatives.find(item => item.id === id)
  const status = RejectTo[initiative?.status || ''] || STATUSES[0]

  try {
    await rejectInitiative(id, status, user.id, user.role)

    await trackActivity({
      type: ACTIVITY_TYPES.status,
      user_id: user.id,
      initiative_id: id,
      comment: values.comment,
      status
    })

    store.dispatch(initiativesSlice.actions.setToReject(null))
    showToast(`${TOAST_STATUS_CHANGED} ${STATUS_TO_LABEL[status]}`)
  } catch (e) {
    console.error(e)
  }
}

export const applyQuickFilter = () => {
  const role = store.getState().auth.user?.role
  if (role === USER_ROLES.countryManager) {
    store.dispatch(
      filtersSlice.actions.setFilter({
        report_period: ALL_OPTION,
        country_code: ALL_OPTION,
        function_code: ALL_OPTION,
        status: STATUSES_OPTIONS[1]
      })
    )
  }

  if (role === USER_ROLES.globalManager) {
    store.dispatch(
      filtersSlice.actions.setFilter({
        report_period: ALL_OPTION,
        country_code: ALL_OPTION,
        function_code: ALL_OPTION,
        status: STATUSES_OPTIONS[2]
      })
    )
  }

  store.dispatch(initiativesSlice.actions.setSearch(''))
}

export const resetQuickFilter = () => {
  store.dispatch(
    filtersSlice.actions.setFilter({
      report_period: ALL_OPTION,
      country_code: ALL_OPTION,
      function_code: ALL_OPTION,
      status: ALL_OPTION
    })
  )
  store.dispatch(initiativesSlice.actions.setSearch(''))
}

export const handleToggleRecallConfirmation = (id: string | null) => () => {
  store.dispatch(initiativesSlice.actions.setToRecall(id))
}

export const handleRecall = async () => {
  const initiative = store.getState().initiatives.details
  const user = store.getState().auth.user

  if (!initiative || !user) return

  try {
    const newStatus =
      initiative.status === STATUSES[2] || user.is_centres
        ? STATUSES[1] // For country reviewer AND centres only
        : STATUSES[2] // For global reviewer

    await updateInitiativeStatus(initiative.id, newStatus, user.id)

    await trackActivity({
      type: ACTIVITY_TYPES.recall,
      user_id: user.id,
      initiative_id: initiative.id,
      status: newStatus
    })

    store.dispatch(initiativesSlice.actions.setToRecall(null))
    showToast(`${TOAST_STATUS_CHANGED} ${STATUS_TO_LABEL[newStatus]}`)
  } catch (e) {
    console.error(e)
  }
}
