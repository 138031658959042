import React from 'react'
import Modal, { Sheets } from '@ingka/modal'
import styles from './Header.module.scss'
import Button from '@ingka/button'
import CrossIcon from '@ingka/ssr-icon/paths/cross'
import LogoImg from '@/images/INGKA_logo.png'
import Text from '@ingka/text'
import { Link } from 'react-router-dom'
import Tooltip from '@ingka/tooltip'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  open: boolean
  handleClose: () => void
}

export const Sidebar: React.FC<Props> = props => {
  const { open, handleClose } = props

  return (
    <Modal visible={open} handleCloseBtn={handleClose}>
      <Sheets footer={null} className={styles.SidebarBody} alignment="left">
        <div className={styles.SidebarHead} id="sidebar">
          <Tooltip tooltipText="Close sidebar" position="bottom" boundaryContainerID="sidebar">
            <Button type="tertiary" ssrIcon={CrossIcon} small iconOnly onClick={handleClose} />
          </Tooltip>
          <Link to={'/'}>
            <img className={styles.Logo} src={LogoImg} alt="logo" />
          </Link>
        </div>
        <div className={styles.List}>
          <Text headingSize="m" tagName="h2" onClick={handleClose}>
            <Link to={'/'}>
              <DynamicTranslations id="sidebar.initiatives" />
            </Link>
          </Text>
          <Text headingSize="m" tagName="h2" onClick={handleClose}>
            <Link to={'/data-reporting'}>
              <DynamicTranslations id="sidebar.data_reporting" />
            </Link>
          </Text>
          <Text headingSize="m" tagName="h2" onClick={handleClose}>
            <Link to={'/explore'}>
              <DynamicTranslations id="sidebar.explore" />
            </Link>
          </Text>
          <Text headingSize="m" tagName="h2" onClick={handleClose}>
            <Link to={'/privacy-policy'}>
              <DynamicTranslations id="sidebar.privacy_policy" />
            </Link>
          </Text>
        </div>
      </Sheets>
    </Modal>
  )
}
