import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type StoreType = {
  show: boolean
  message: string | null
}

const initialState = {
  message: null
} as StoreType

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast(state, action: PayloadAction<Partial<StoreType>>) {
      state.message = action.payload.message ?? state.message
      state.show = action.payload.show ?? state.show
    },
    reset() {
      return initialState
    }
  }
})
