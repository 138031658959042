import React from 'react'
import styles from '@/pages/Admin/styles/Admin.module.scss'
import listStyles from '@/components/form/dropdown/List.module.scss'
import Text from '@ingka/text'
import { UserData } from '@/types/user'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import Button from '@ingka/button'
import PencilIcon from '@ingka/ssr-icon/paths/pencil'
import { Show } from '@/components/conditions/Show'
import clx from 'classnames'
import { useOnClickOutside } from '@/components/hooks/outside'
import Checkbox from '@ingka/checkbox'
import { handleUpdateField, handleUpdateCountry } from '@/pages/Admin/utils/update'
import Switch from '@ingka/switch'
import Radio from '@ingka/radio-button'
import { USER_ROLES_OPTIONS } from '@/constants/common'

const ROLE_TO_LABEL = {
  user: 'Data Provider',
  country_manager: 'Country Reviewer',
  global_manager: 'Global Reviewer'
}

type Props = {
  user: UserData
}

export const UserItem: React.FC<Props> = props => {
  const { user } = props
  const countries = useSelector((state: RootState) => state.sites.countries)
  const [show, setShow] = React.useState(false)
  const [showRoleSelect, setShowRoleSelect] = React.useState(false)
  const ref = React.useRef(null)
  const roleRef = React.useRef(null)

  useOnClickOutside(ref, () => setShow(false))
  useOnClickOutside(roleRef, () => setShowRoleSelect(false))

  const isChecked = (code: string) => {
    return user.country?.some(item => code === item)
  }

  return (
    <div key={`users-${user.id}`} className={styles.UserItem}>
      <Text headingSize="s">{user.name}</Text>
      <div className={clx(styles.Counties, listStyles.DropdownList)}>
        <Text bodySize="m">{ROLE_TO_LABEL[user.role]}</Text>
        <Button iconOnly ssrIcon={PencilIcon} type="tertiary" small onClick={() => setShowRoleSelect(true)} />
        <Show when={showRoleSelect}>
          <div className={listStyles.DropdownListWrapper} ref={roleRef}>
            {USER_ROLES_OPTIONS.map(item => {
              return (
                <div
                  className={listStyles.Item}
                  key={`user-${user.id}-${item.id}`}
                  onClick={handleUpdateField(user, 'role', item.value)}
                >
                  {item.label}
                  <Radio
                    id={`user-${user.id}-${item.id}-radio`}
                    checked={user.role === item.value}
                    value={item.value}
                    readOnly
                  />
                </div>
              )
            })}
          </div>
        </Show>
      </div>
      <div className={clx(styles.Counties, listStyles.DropdownList)}>
        <Text bodySize="m" className={styles.CountriesLabels}>
          {user.country?.map(code => countries.find(item => item.value === code)?.label).join(', ')}
        </Text>
        <Button iconOnly ssrIcon={PencilIcon} type="tertiary" small onClick={() => setShow(true)} />
        <Show when={show}>
          <div className={listStyles.DropdownListWrapper} ref={ref}>
            {countries.map(item => {
              return (
                <div
                  className={listStyles.Item}
                  key={`user-${user.id}-${item.id}`}
                  onClick={handleUpdateCountry(user, item.value)}
                >
                  {item.label}
                  <Checkbox
                    id={`user-${user.id}-${item.id}-checkbox`}
                    checked={isChecked(item.value)}
                    value={item.value}
                    readOnly
                  />
                </div>
              )
            })}
          </div>
        </Show>
      </div>
      <Switch
        id={`users-${user.id}-cip`}
        label="Centres only"
        checked={user.is_centres}
        value=""
        onChange={handleUpdateField(user, 'is_centres', !user.is_centres)}
      />
    </div>
  )
}
