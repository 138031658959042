import { updateUser } from '@/pages/Admin/services'
import { UserData } from '@/types/user'

export const handleUpdateCountry = (user: UserData, countryCode: string) => () => {
  const country = user.country?.some(item => item === countryCode)
    ? user.country?.filter(item => item !== countryCode)
    : [...(user.country || []), countryCode]
  updateUser(user.id, { country })
}

export const handleUpdateField = (user: UserData, name: string, value: boolean | string) => () => {
  updateUser(user.id, { [name]: value })
}
