import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import clx from 'classnames'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { getTableData } from '@/pages/Analytics/utils/table'
import { IMPACT_TYPES } from '@/constants/common'
import { RowItem } from '@/pages/Analytics/components/FY24Logic/Table/RowItem'
import { DynamicTranslations } from '@/components/translations/Translations'
import { formatNumber } from '@/utils/format'

export const StrongAndLight = () => {
  const data = useSelector((state: RootState) => state.analytics.initiatives)
  const countries = useSelector((state: RootState) => state.sites.countries)
  const { country_code } = useSelector((state: RootState) => state.filters.filters)
  const isFilteredByCountry = country_code?.value !== 'all'

  const strongContributionData = getTableData(data, IMPACT_TYPES.strong, countries, isFilteredByCountry)
  const lightContributionData = getTableData(data, IMPACT_TYPES.light, countries, isFilteredByCountry)

  return (
    <div className={styles.ContentTable}>
      <div className={styles.TableHead}>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.table.head.initiative_type" />
        </div>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.table.head.country_unit" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.people_supported" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.coworkers" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.customers" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.hours" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.spent" />
        </div>
      </div>
      <RowItem title="data_reporting.analysis.strong" tableData={strongContributionData} />
      <RowItem title="data_reporting.analysis.light" tableData={lightContributionData} />
      <div className={styles.TableTotal}>
        <div className={styles.TableRow}>
          <Text headingSize="s" className={styles.TotalLabel}>
            Grand Total
          </Text>
          <div />
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(
              strongContributionData.total.participants_count + lightContributionData.total.participants_count
            )}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(
              strongContributionData.total.coworkers_engaged_count + lightContributionData.total.coworkers_engaged_count
            )}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(
              strongContributionData.total.customers_engaged_count + lightContributionData.total.customers_engaged_count
            )}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(strongContributionData.total.hours + lightContributionData.total.hours)}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(strongContributionData.total.spent + lightContributionData.total.spent)}
          </Text>
        </div>
      </div>
    </div>
  )
}
