import React from 'react'
import Text from '@ingka/text'
import CrossSmall from '@ingka/ssr-icon/paths/cross-small'
import CheckSmall from '@ingka/ssr-icon/paths/checkmark-small'

import { StatusTag } from '@/pages/Initiatives/components/Status'
import styles from '../../styles/Initiatives.module.scss'
import Button from '@ingka/button'
import { TabsInfo } from '@/pages/Initiatives/components/Details/TabsInfo'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { handleApproveCountryInitiative } from '@/pages/Initiatives/utils/review'
import clx from 'classnames'
import { Show } from '@/components/conditions/Show'
import { STATUSES } from '@/constants/statuses'
import { More } from '@/pages/Initiatives/components/Details/More'
import { DynamicTranslations } from '@/components/translations/Translations'

export const GlobalDetails: React.FC = () => {
  const { details } = useSelector((state: RootState) => state.initiatives)
  const [showDesc, setShowDesc] = React.useState(false)
  const [toggleAvailable, setShowToggle] = React.useState(false)
  const descRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setShowToggle(Number(descRef.current?.clientHeight) >= 88)
  }, [details])

  if (!details) return null

  const { status, title, description } = details

  const handleReject = () => store.dispatch(initiativesSlice.actions.setToReject(details.id))

  const handleToggleDesc = () => {
    setShowDesc(!showDesc)
  }

  return (
    <div className={styles.InitiativeDetails}>
      <div className={styles.Head}>
        <div className={styles.TitleSection}>
          <StatusTag status={status} />
          <Text headingSize="l">{title}</Text>
        </div>

        <div className={styles.Controls}>
          <Show when={status === STATUSES[2]}>
            <Button small ssrIcon={CrossSmall} onClick={handleReject}>
              <DynamicTranslations id="initiatives.details.reject" />
            </Button>
            <Button
              type="emphasised"
              small
              ssrIcon={CheckSmall}
              onClick={handleApproveCountryInitiative(details.id, STATUSES[4])}
            >
              <DynamicTranslations id="initiatives.details.approve" />
            </Button>
          </Show>
          <Show when={details.status === STATUSES[4]}>
            <More />
          </Show>
        </div>
      </div>
      <div className={styles.ScrollBlock}>
        <div ref={descRef} className={clx(styles.DescriptionBlock, showDesc ? styles.Show : styles.Hide)}>
          <Text bodySize="m">{description}</Text>
        </div>
        <Show when={toggleAvailable}>
          <Text bodySize="m" className={styles.Toggle} onClick={handleToggleDesc}>
            {showDesc ? 'Hide' : 'Show'}
          </Text>
        </Show>
        <TabsInfo />
      </div>
    </div>
  )
}
