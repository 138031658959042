import React from 'react'
import styles from './Header.module.scss'
import { HeaderContext } from '@/components/header/provider'
import Button from '@ingka/button'
import NotificationIcon from '@ingka/ssr-icon/paths/bell'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { Show } from '@/components/conditions/Show'
import { isEmpty } from 'lodash'

export const NotificationButton = () => {
  const { toggleNotifications } = React.useContext(HeaderContext)
  const list = useSelector((state: RootState) => state.notifications.list)

  return (
    <div className={styles.NotificationWrap}>
      <Button
        iconOnly
        ssrIcon={NotificationIcon}
        type="tertiary"
        onClick={() => toggleNotifications && toggleNotifications(true)}
      />
      <Show when={!isEmpty(list.filter(item => !item.seen))}>
        <div className={styles.Indicator} />
      </Show>
    </div>
  )
}
