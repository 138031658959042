import * as Yup from 'yup'

import { FIELD_NAME } from '../constants/types'
import { LEVEL_ITEMS, MAIN_MOVEMENT_OPTIONS, MOVEMENT_OPTIONS, TYPE_OF_INITIATIVE_OPTIONS } from '../constants/options'
import { Option } from '@/types/common'
import { isNil } from 'lodash'

const REQUIRED = 'Field is required'
const NUMBER = 'You should type number'
const INTEGER = 'Numbers with decimals are not allowed'
const MAX_SYMBOLS = 'You exceeded max number of symbols 400'
const MAX_SYMBOLS_TEXT_AREA = 'You exceeded max number of symbols 2500'
const MIN_SYMBOLS_DESCRIPTION = 'Minimum number of symbols is 140'

const COWORKER_ENGAGEMENT_ERROR =
  'You selected "Co-worker engagement" as type of initiative so please enter the number of coworkers engaged or unselect "Co-worker engagement" from the types of initiative'
const CUSTOMER_ENGAGEMENT_ERROR =
  'You selected "Customer/citizen engagement" as type of initiative so please enter the number of customers or citizens engaged or unselect "Customer/citizen engagement" from the types of initiative'
const AT_LEAST_15_IN_KIND =
  'can you please put 0 as number of people impacted since the inkind donation was below 15€ / beneficiary. To avoid any risk of social washing, we only count as people supported those from the initiative where the inkind donation amount is above 15€/ beneficiary. we can still keep the coworkers and/or customers as change makers'
const AT_LEAST_50_IN_KIND =
  'can you please put 0 as number of people impacted since the cash donation was below 50€ / beneficiary. To avoid any risk of social washing, we only count as people supported those from the initiative where the cash donation amount is above 50€/ beneficiary. we can still keep the coworkers and/or customers as change makers'
const PARTICIPANTS_OF_SKILLS_DEVELOPMENT = 'Number should be less then people supported'
const CUSTOM_LEVEL =
  'The level of change should be limited if the initiative is limited to one type of contribution such as only financial donation, only in-kind donation, only volunteering, or a single event. The level of change should be substantive if the initiativecombines various types of support, such as financial/cash donations + in-kind donations, or in-kind donation + volunteering, etc.'
const MOVEMENT_CUSTOM_CC =
  'You\'ve selected coworker engagement and/or customer engagement in the type of initiative so please add  "Enabler: Engaging the many people to participate" in the selected movements'
const MOVEMENT_CUSTOM_UPSKILLING =
  'You\'ve selected "Upskilling and reskilling for employment" and/or "Upskilling and reskilling for employment - Refugees Skills Development Program" and/or "Partnering with social enterprises as part of Value Chain (providing us with products or services)" in the type(s) of initiative so please add "Movement 3: Opening pathways to decent employment" in the selected movements'

export const INPUT_SYMBOLS_MAX = 400
export const TEXT_AREA_SYMBOLS_MAX = 2500
export const DESCRIPTION_MIN_SYMBOLS = 140

export const NewReportSchema = Yup.object().shape({
  [FIELD_NAME.country]: Yup.object().required(REQUIRED),
  [FIELD_NAME.siteOrCountry]: Yup.object().required(REQUIRED),
  [FIELD_NAME.title]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.description]: Yup.string()
    .min(DESCRIPTION_MIN_SYMBOLS, MIN_SYMBOLS_DESCRIPTION)
    .max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA)
    .required(REQUIRED),
  [FIELD_NAME.report_period]: Yup.string().required(REQUIRED),
  [FIELD_NAME.partner]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.people_impacted]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.people_impacted_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.people_supported_count]: Yup.number().integer(INTEGER).typeError(NUMBER).required(REQUIRED),
  [FIELD_NAME.main_movement_supported]: Yup.object()
    .required(REQUIRED)
    .test('movement_custom_coworker_customer', MOVEMENT_CUSTOM_CC, (value, context) => {
      return !(
        (value as Option)?.id !== MAIN_MOVEMENT_OPTIONS[5].id &&
        !(context.parent[FIELD_NAME.movement_supported] || []).some(
          (item: Option) => item.id === MOVEMENT_OPTIONS[5].id
        ) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[5].id || item.id === TYPE_OF_INITIATIVE_OPTIONS[6].id
        )
      )
    })
    .test('movement_custom_upskilling', MOVEMENT_CUSTOM_UPSKILLING, (value, context) => {
      return !(
        (value as Option)?.id !== MAIN_MOVEMENT_OPTIONS[2].id &&
        !(context.parent[FIELD_NAME.movement_supported] || []).some(
          (item: Option) => item.id === MOVEMENT_OPTIONS[2].id
        ) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) =>
            item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[3].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[4].id
        )
      )
    }),
  [FIELD_NAME.movement_supported]: Yup.array()
    .test('movement_custom_coworker_customer', MOVEMENT_CUSTOM_CC, (value, context) => {
      return !(
        context.parent[FIELD_NAME.main_movement_supported]?.id !== MAIN_MOVEMENT_OPTIONS[5].id &&
        !(value || []).some((item: Option) => item.id === MOVEMENT_OPTIONS[5].id) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[5].id || item.id === TYPE_OF_INITIATIVE_OPTIONS[6].id
        )
      )
    })
    .test('movement_custom_upskilling', MOVEMENT_CUSTOM_UPSKILLING, (value, context) => {
      return !(
        context.parent[FIELD_NAME.main_movement_supported]?.id !== MAIN_MOVEMENT_OPTIONS[2].id &&
        !(value || []).some((item: Option) => item.id === MOVEMENT_OPTIONS[2].id) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) =>
            item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[3].id ||
            item.id === TYPE_OF_INITIATIVE_OPTIONS[4].id
        )
      )
    }),
  [FIELD_NAME.type_of_initiative]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.type_of_initiative_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.participants_of_skills_development]: Yup.number()
    .typeError(NUMBER)
    .test('less-than-people_supported_count', PARTICIPANTS_OF_SKILLS_DEVELOPMENT, (value, context) => {
      return !(
        (Number(value) > context.parent[FIELD_NAME.people_supported_count] || isNil(value)) &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id
        )
      )
    }),
  [FIELD_NAME.spent]: Yup.number()
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('more-than-15', AT_LEAST_15_IN_KIND, (value, context) => {
      return !(
        value < context.parent[FIELD_NAME.people_supported_count] * 15 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[0].id
        )
      )
    })
    .test('more-than-50', AT_LEAST_50_IN_KIND, (value, context) => {
      return !(
        value < context.parent[FIELD_NAME.people_supported_count] * 50 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.id === TYPE_OF_INITIATIVE_OPTIONS[1].id
        )
      )
    }),
  [FIELD_NAME.level]: Yup.object()
    .required(REQUIRED)
    .test('level-custom', CUSTOM_LEVEL, (value, context) => {
      return !(
        ((value as Option)?.value === LEVEL_ITEMS.limited &&
          context.parent[FIELD_NAME.type_of_initiative].length > 1) ||
        ((value as Option)?.value === LEVEL_ITEMS.substantive &&
          context.parent[FIELD_NAME.type_of_initiative].length === 1)
      )
    }),
  [FIELD_NAME.duration]: Yup.object().required(REQUIRED),
  [FIELD_NAME.coworkers_count]: Yup.number()
    .integer(INTEGER)
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('dependent_type_coworker', COWORKER_ENGAGEMENT_ERROR, (value, context) => {
      return !(
        value <= 0 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.value === TYPE_OF_INITIATIVE_OPTIONS[5].value
        )
      )
    }),
  [FIELD_NAME.coworker_hours]: Yup.number().typeError(NUMBER).required(REQUIRED),
  [FIELD_NAME.customers_count]: Yup.number()
    .integer(INTEGER)
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('dependent_type_customer', CUSTOMER_ENGAGEMENT_ERROR, (value, context) => {
      return !(
        value <= 0 &&
        context.parent[FIELD_NAME.type_of_initiative].some(
          (item: Option) => item.value === TYPE_OF_INITIATIVE_OPTIONS[6].value
        )
      )
    })
})

export const DraftReportSchema = Yup.object().shape({
  [FIELD_NAME.country]: Yup.object().required(REQUIRED),
  [FIELD_NAME.siteOrCountry]: Yup.object().required(REQUIRED),
  [FIELD_NAME.title]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.report_period]: Yup.string().required(REQUIRED),
  [FIELD_NAME.description]: Yup.string()
    .min(DESCRIPTION_MIN_SYMBOLS, MIN_SYMBOLS_DESCRIPTION)
    .max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.partner]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS),
  [FIELD_NAME.people_supported_count]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.people_impacted_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.type_of_initiative_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.spent]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.coworkers_count]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.coworker_hours]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.customers_count]: Yup.number().nullable().typeError(NUMBER)
})
