import { groupBy, isNil } from 'lodash'
import { InitiativeDBType, InitiativeFormType, InitiativesStatusesType, InitiativeType } from '@/types/initiative'
import { SiteOption, SiteType } from '@/types/siteList'
import { Option } from '@/types/common'
import { PEOPLE_IMPACTED_OPTIONS, TYPE_OF_INITIATIVE_OPTIONS } from '@/pages/ReportForm/constants/options'
import { FIELD_NAME, FIELD_OPTIONS } from '@/pages/ReportForm/constants/types'
import { getCurrentFYOptions, getIngkaFinancialYear } from '@/utils/dates'
import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'
import { AttachmentFileType } from '@/pages/ReportForm/slices/form'

export const initialValues = {
  country: null,
  siteOrCountry: null,
  title: '',
  description: '',
  report_period: '',
  partner: '',
  people_impacted: [],
  people_impacted_comment: '',
  people_supported_count: null,
  main_movement_supported: null,
  movement_supported: [],
  type_of_initiative: [],
  type_of_initiative_comment: '',
  spent: null,
  level: null,
  duration: null,
  coworkers_count: null,
  coworker_hours: null,
  customers_count: null,
  comment: ''
}

export const mapDataToSubmit = (
  formData: InitiativeFormType,
  user_id: string,
  status: InitiativesStatusesType,
  attachments: AttachmentFileType[] = []
): InitiativeType => {
  const isCountry = formData?.siteOrCountry?.isCountry
  const siteOrCountry = {
    ...(!isCountry
      ? {
          site: formData?.siteOrCountry?.value,
          country_entry: ''
        }
      : { country_entry: formData?.siteOrCountry?.value, site: '' })
  }

  const withTypeComment = (formData.type_of_initiative || []).find(
    item => item.id === TYPE_OF_INITIATIVE_OPTIONS[TYPE_OF_INITIATIVE_OPTIONS.length - 1].id
  )
  const withTypeCommentPI = (formData.people_impacted || []).find(
    item => item.id === PEOPLE_IMPACTED_OPTIONS[PEOPLE_IMPACTED_OPTIONS.length - 1].id
  )

  const fy = getFYByReportingPeriod(formData.report_period)

  return {
    ...siteOrCountry,
    fy,
    title: formData.title || '',
    description: formData.description || '',
    report_period: formData.report_period ? new Date(formData.report_period) : null,
    partner: formData.partner || '',
    people_impacted: formData.people_impacted?.map(item => item.value || '') || [],
    ...(withTypeCommentPI ? { people_impacted_comment: formData.people_impacted_comment } : {}),
    people_supported_count: formData.people_supported_count ? formatNumber(formData.people_supported_count) : null,
    participants_of_skills_development:
      formData.participants_of_skills_development &&
      formData.type_of_initiative?.some(item => item.id === TYPE_OF_INITIATIVE_OPTIONS[2].id)
        ? formatNumber(formData.participants_of_skills_development)
        : null,
    main_movement_supported: formData.main_movement_supported?.value || '',
    movement_supported:
      formData.movement_supported
        ?.filter(item => item.value !== formData.main_movement_supported?.value)
        .map(item => item.value || '') || [],
    type_of_initiative: formData.type_of_initiative?.map(item => item.value || '') || [],
    ...(withTypeComment ? { type_of_initiative_comment: formData.type_of_initiative_comment } : {}),
    spent: formData.spent ? formatNumber(formData.spent) : null,
    level: formData.level?.value || '',
    duration: formData.duration?.value || '',
    coworkers_count: formData.coworkers_count ? formatNumber(formData.coworkers_count) : null,
    coworker_hours: formData.coworker_hours ? formatNumber(formData.coworker_hours) : null,
    customers_count: formData.customers_count ? formatNumber(formData.customers_count) : null,
    ...(formData.comment ? { comment: formData.comment } : {}),
    country_name: formData?.siteOrCountry?.country_name || '',
    country_code: formData?.siteOrCountry?.country_code || '',
    ...(formData?.siteOrCountry?.sustain_site_id
      ? { sustain_site_id: formData?.siteOrCountry?.sustain_site_id }
      : { sustain_site_id: '' }),
    function_code: formData?.siteOrCountry?.function_code || '',
    status,
    user_id,
    updated_by: user_id,
    attachments
  }
}

const getFYByReportingPeriod = (period?: string) => {
  if (!period) return 0
  return getIngkaFinancialYear(new Date(period)).getFullYear()
}

const formatNumber = (value?: string | number | null): number | null => {
  if (isNil(value)) return null
  return Number(value.toString().split(' ').join('')) || 0
}

export const mapToSiteOptions = (list: SiteType[]): SiteOption[] => {
  const grouped = groupBy(list, 'country_entry')
  return Object.keys(grouped).reduce((result: SiteOption[], current: string) => {
    const countryValue = {
      id: current,
      value: current,
      label: current,
      isCountry: true,
      country_name: grouped[current][0].country_name,
      country_code: grouped[current][0].country_code,
      function_code: grouped[current][0].function_code
    }

    const sites = grouped[current].map(item => ({
      id: item.sustain_site_id,
      value: item.site_name,
      label: item.site_name || `${item.country_name} ${item.function_code}`,
      country_name: item.country_name,
      country_code: item.country_code,
      sustain_site_id: item.sustain_site_id,
      function_code: item.function_code
    }))

    return [...result, countryValue, ...sites] as SiteOption[]
  }, [])
}

export const mapDataToForm = (
  initiative: InitiativeDBType,
  sitesOrCountryOptions: Option[],
  countryOptions: Option[]
): InitiativeFormType => {
  const periods = getCurrentFYOptions()

  const values = Object.keys(initiative).reduce((result, key) => {
    if (isNil(initiative[key as keyof InitiativeDBType]) || key === 'updated_at' || key === 'created_at') {
      return result
    }

    if ((key === 'site' || key === 'country_entry') && !initiative[key as keyof InitiativeDBType]) return result

    return {
      ...result,
      ...handleValues(
        key as keyof InitiativeDBType,
        initiative[key as keyof InitiativeDBType],
        sitesOrCountryOptions,
        periods
      ),
      country: countryOptions.find(item => item.value === initiative.country_code)
    }
  }, {})

  return {
    ...initialValues,
    ...values
  }
}

const handleValues = (
  key: keyof InitiativeDBType,
  value: unknown,
  sitesOrCountryOptions: Option[],
  periods: Option[]
) => {
  switch (key) {
    case FIELD_NAME.people_impacted:
    case FIELD_NAME.movement_supported:
    case FIELD_NAME.type_of_initiative:
      return {
        [key]: FIELD_OPTIONS[key].filter(option => (value as string[]).some(item => item === option.value))
      }

    case FIELD_NAME.people_supported_count:
    case FIELD_NAME.spent:
    case FIELD_NAME.coworkers_count:
    case FIELD_NAME.coworker_hours:
    case FIELD_NAME.customers_count:
    case FIELD_NAME.participants_of_skills_development:
      return { [key]: !isNil(value) ? (value as number).toString() : value }

    case FIELD_NAME.level:
    case FIELD_NAME.duration:
    case FIELD_NAME.main_movement_supported:
      return {
        [key]: FIELD_OPTIONS[key].find(option => value === option.value)
      }

    case FIELD_NAME.report_period:
      return {
        [key]: periods.find(option => format((value as Timestamp).toDate(), 'yyyy-MM-dd') === option.value)?.value
      }

    case 'site':
    case 'country_entry':
      return {
        siteOrCountry: sitesOrCountryOptions.find(item => item.value === value)
      }

    default:
      return { [key]: value }
  }
}
