import { NotificationDBType, NotificationType } from '@/types/notifications'
import { omit } from 'lodash'
import { format } from 'date-fns'

export const mapToList = (list: NotificationDBType[]): NotificationType[] => {
  return list.reduce<NotificationDBType[]>((result, current) => {
    if (!current.initiative) return result

    return [
      ...result,
      {
        ...omit(current, 'initiative.updated_at', 'initiative.report_period', 'initiative.created_at'),
        created_at: format(current.created_at.toDate(), 'd MMMM yy HH:mm')
      }
    ] as NotificationDBType[]
  }, [])
}
