import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SiteType } from '@/types/siteList'
import { Option } from '@/types/common'

type StoreType = {
  sites: SiteType[]
  countries: Option[]
  functionCodes: Option[]
}

const initialState = {
  sites: [],
  countries: [],
  functionCodes: []
} as StoreType

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSites(state, action: PayloadAction<SiteType[]>) {
      state.sites = action.payload
    },
    setCountries(state, action: PayloadAction<Option[]>) {
      state.countries = action.payload
    },
    setFunctionCodes(state, action: PayloadAction<Option[]>) {
      state.functionCodes = action.payload
    }
  }
})
