import { CommentDBType, CommentItemType } from '@/types/comments'
import { format } from 'date-fns'

export const mapToList = (data: CommentDBType[]): CommentItemType[] => {
  return data
    .sort((itemA, itemB) => Number(itemB.created_at.toDate()) - Number(itemA.created_at.toDate()))
    .map(item => {
      return { ...item, created_at: format(item.created_at.toDate(), 'd MMMM yyyy kk:mm') } as CommentItemType
    })
}
