import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import clx from 'classnames'
import TextAreaIngka, { TextAreaProps } from '@ingka/text-area'
import FormField, { FormFieldProps } from '@ingka/form-field'

import style from './TextArea.module.scss'
import { Show } from '@/components/conditions/Show'

type Props = FieldHookConfig<string> &
  TextAreaProps &
  FormFieldProps & {
    customHelperText?: string | React.ReactElement
  }

export const TextArea: React.FC<Props> = props => {
  const { id, label, fieldHelper, characterLimit, customHelperText, className, placeholder } = props
  const [field, meta] = useField(props)

  return (
    <FormField
      data-field-error={Boolean(meta.error)}
      fieldHelper={fieldHelper}
      characterLimit={characterLimit}
      valid={!meta.error}
      shouldValidate={Boolean(meta.touched && meta.error)}
      className={clx(style.Field, className)}
      validation={{
        id: `validation-${field.name}`,
        msg: meta.error,
        type: 'error'
      }}
    >
      <TextAreaIngka className={style.TextArea} {...field} id={id} label={label} placeholder={placeholder} />
      <Show when={Boolean(customHelperText)}>
        <div className={clx('helper-text', style.Helper)}>{customHelperText}</div>
      </Show>
    </FormField>
  )
}
