import React from 'react'
import { Loader } from '@/components/loader/Loader'
import { RootState, store } from '@/storage/store'
import { loadingSlice } from '@/storage/loading'
import { getActivities, subscribeForActivities } from '@/pages/Activity/services'
import { mapToActivityList } from '@/pages/Activity/utils/mapper'
import { activitySlice } from '@/pages/Activity/slices/activities'
import { useSelector } from 'react-redux'
import { Unsubscribe } from '@firebase/firestore'

export const ActivityProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props
  const initiativeId = useSelector((state: RootState) => state.initiatives.details?.id)
  const unsub = React.useRef<Unsubscribe | null>(null)

  React.useEffect(() => {
    if (!initiativeId) return
    if (unsub.current) unsub.current()

    loadActivities(initiativeId)
    unsub.current = subscribeForActivities(initiativeId, items => {
      const mapped = mapToActivityList(items)
      store.dispatch(activitySlice.actions.setActivities(mapped))
    })

    return () => {
      unsub.current && unsub.current()
    }
  }, [initiativeId])

  return <Loader name="ACTIVITIES_LOAD">{children}</Loader>
}

export const loadActivities = async (initiativeId: string) => {
  store.dispatch(loadingSlice.actions.setLoading({ name: 'ACTIVITIES_LOAD', value: true }))

  getActivities(initiativeId)
    .then(activities => {
      const mapped = mapToActivityList(activities)
      store.dispatch(activitySlice.actions.setActivities(mapped))
    })
    .finally(() => {
      store.dispatch(loadingSlice.actions.setLoading({ name: 'ACTIVITIES_LOAD', value: false }))
    })
}
