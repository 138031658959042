import React from 'react'
import styles from '../styles/Admin.module.scss'
import { Admin } from '@/pages/Admin/providers/Admin'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { UserItem } from '@/pages/Admin/components/UserItem'
import { Filters } from '@/pages/Admin/components/Filters'
import { useHeaderSettings } from '@/components/header/provider'
import { searchUsers } from '@/pages/Admin/utils/search'

export const AdminPageImpl = () => {
  const { users, filters } = useSelector((state: RootState) => state.admin)

  useHeaderSettings(true, false)

  const data = searchUsers(users, filters)

  return (
    <div className={styles.AdminPage}>
      <Filters />
      {data.map(user => {
        return <UserItem key={`user-${user.id}`} user={user} />
      })}
    </div>
  )
}

export const AdminPage = () => {
  return (
    <Admin>
      <AdminPageImpl />
    </Admin>
  )
}
