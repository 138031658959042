import { store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { STATUSES, TOAST_INITIATIVE_COMMENTED } from '@/constants/statuses'
import { trackActivity } from '@/pages/Activity/utils/trackActivity'
import { ACTIVITY_TYPES } from '@/constants/common'
import { showToast } from '@/components/toast/ToastContainer'
import { handleApproveCountryInitiative } from '@/pages/Initiatives/utils/review'
import { FormikHelpers } from 'formik/dist/types'

export const handleToggleLeaveComment = (id: string, commentWithApprove: boolean) => () => {
  store.dispatch(initiativesSlice.actions.setToComment({ id, commentWithApprove }))
}

export const handleCloseCommentPopup = () => {
  store.dispatch(initiativesSlice.actions.setToComment(null))
}

export const handleCommentInitiative = async (
  values: { comment: string },
  handlers: FormikHelpers<{ comment: string }>
) => {
  const id = store.getState().initiatives.toCommentId
  const commentWithApprove = store.getState().initiatives.commentWithApprove
  const user = store.getState().auth.user
  if (!id || !user) return

  try {
    if (commentWithApprove) {
      await handleApproveCountryInitiative(id, user.is_centres ? STATUSES[4] : STATUSES[2])()
    }

    await trackActivity({
      type: ACTIVITY_TYPES.comment,
      user_id: user.id,
      initiative_id: id,
      comment: values.comment
    })

    store.dispatch(initiativesSlice.actions.setToComment(null))
    showToast(TOAST_INITIATIVE_COMMENTED)
  } catch (e) {
    console.error(e)
  }

  handlers.setSubmitting(false)
}
