import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import Text from '@ingka/text'
import { DonutChart } from '@/pages/Analytics/components/DonutChart'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { getAnalysisSeries } from '@/pages/Analytics/utils/donut'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  withConnect?: boolean
}

export const AnalysisChartCardFY24: React.FC<Props> = props => {
  const { withConnect } = props
  const data = useSelector((state: RootState) => state.analytics.initiatives)
  const series = getAnalysisSeries(data, withConnect)
  const total = series.reduce((result, current) => result + current.value, 0)

  return (
    <div className={styles.ChartCard}>
      <Text className={styles.Head} headingSize="m">
        <DynamicTranslations id="data_reporting.analysis.title" />
      </Text>
      <div className={styles.ChartWrap}>
        <DonutChart values={series} title="data_reporting.analysis.initiatives" />
        <div className={styles.ChartInfo}>
          {series.map(item => {
            return (
              <div key={`info-card-${item.color}`} className={styles.InfoItem}>
                <div className={styles.InfoItemHead}>
                  <div className={styles.Indicator} style={{ backgroundColor: item.color }} />
                  <Text className={styles.Name} headingSize="s">
                    <DynamicTranslations id={item.name} />
                  </Text>
                </div>
                <Text headingSize="m">{Math.round((100 * item.value) / total) || 0}%</Text>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
