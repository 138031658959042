import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import FormField, { FormFieldProps } from '@ingka/form-field'
import Radio from '@ingka/radio-button'
import Text from '@ingka/text'

import { Option } from '@/types/common'

import styles from '../checkbox/CheckboxGroup.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'
import { Show } from '@/components/conditions/Show'

type Props = FieldHookConfig<Option> &
  FormFieldProps & {
    list: Option[]
    label?: string | React.ReactElement
    withTranslations?: boolean
    limitedPreview?: number
  }

export const RadioGroup: React.FC<Props> = props => {
  const { list, label, fieldHelper, withTranslations, limitedPreview } = props
  const [field, meta, helpers] = useField(props)

  const helper = fieldHelper && {
    ...fieldHelper,
    msg: <span className={styles.CheckboxHelper}>{fieldHelper?.msg}</span>
  }

  return (
    <FormField
      data-field-error={Boolean(meta.error)}
      fieldHelper={helper}
      className={styles.CheckboxGroup}
      valid={!meta.error}
      shouldValidate={Boolean(meta.touched && meta.error)}
      validation={{
        id: `validation-${field.name}`,
        msg: meta.error,
        type: 'error'
      }}
    >
      <Show when={Boolean(label)}>
        <Text className={styles.Label} bodySize="m">
          {label}
        </Text>
      </Show>
      <List
        {...field}
        limitedPreview={limitedPreview}
        withTranslations={withTranslations}
        setValue={helpers.setValue}
        list={list}
      />
    </FormField>
  )
}

type ListProps = {
  list: Option[]
  setValue: (value: Option) => void
  value: Option
  withTranslations?: boolean
  limitedPreview?: number
}

const List: React.FC<ListProps> = props => {
  const { list, value, setValue, withTranslations, limitedPreview } = props
  const [openMore, setOpenMore] = React.useState(false)

  const handleChange = (option: Option) => () => {
    setValue(option)
  }

  const handleToggleMore = () => {
    setOpenMore(!openMore)
  }

  if (limitedPreview && list.length > limitedPreview) {
    const preview = list.slice(0, limitedPreview)
    const expandable = list.slice(limitedPreview, list.length)

    return (
      <fieldset>
        {preview.map(option => {
          return (
            <Radio
              label={withTranslations ? <DynamicTranslations id={option.label} /> : option.label}
              key={option.id}
              id={option.id}
              name={option.id}
              value={option.value}
              checked={option.id === value?.id}
              disabled={option.disabled}
              onChange={handleChange(option)}
            />
          )
        })}
        <Show when={openMore}>
          {expandable.map(option => {
            return (
              <Radio
                label={withTranslations ? <DynamicTranslations id={option.label} /> : option.label}
                key={option.id}
                id={option.id}
                name={option.id}
                value={option.value}
                checked={option.id === value?.id}
                disabled={option.disabled}
                onChange={handleChange(option)}
              />
            )
          })}
        </Show>
        <div onClick={handleToggleMore} className={styles.More}>
          <Show when={!openMore}>
            +{limitedPreview} <DynamicTranslations id="more" />
          </Show>
          <Show when={openMore}>
            <DynamicTranslations id="less" />
          </Show>
        </div>
      </fieldset>
    )
  }

  return (
    <fieldset>
      {list.map(option => {
        return (
          <Radio
            label={withTranslations ? <DynamicTranslations id={option.label} /> : option.label}
            key={option.id}
            id={option.id}
            name={option.id}
            value={option.value}
            checked={option.id === value?.id}
            disabled={option.disabled}
            onChange={handleChange(option)}
          />
        )
      })}
    </fieldset>
  )
}
