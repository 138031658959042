import React from 'react'
import { useFormikContext } from 'formik'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { SearchCommon } from '@/components/form/dropdown/SearchCommon'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { InitiativeFormType } from '@/types/initiative'
import { FormLabel } from '@/pages/ReportForm/components/Labels'

export const Site = () => {
  const { values } = useFormikContext<InitiativeFormType>()
  const { sites } = useSelector((state: RootState) => state.form)

  const filteredSites = React.useMemo(() => {
    return sites.filter(item => item.country_code === values.country?.value)
  }, [sites, values.country])

  return (
    <SearchCommon
      label={<FormLabel id={FIELD_NAME.siteOrCountry} />}
      name={FIELD_NAME.siteOrCountry}
      id={FIELD_NAME.siteOrCountry}
      list={filteredSites}
      disabled={!values.country}
    />
  )
}
