import React from 'react'
import styles from './UserPhoto.module.scss'
import clx from 'classnames'

export const UserPhoto: React.FC<{ placeholder: string; photoUrl?: string | null; small?: boolean }> = props => {
  return props.photoUrl ? (
    <div
      className={clx(styles.UserPhoto, props.small ? styles.Small : null)}
      style={{ backgroundImage: `url("${props.photoUrl}")` }}
    />
  ) : (
    <div className={clx(styles.UserPhoto, props.small ? styles.Small : null)}>{props.placeholder}</div>
  )
}
