import React from 'react'
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from '@ingka/modal'
import Button from '@ingka/button'
import { Show } from '@/components/conditions/Show'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { Description } from '@/pages/ReportForm/components/ModalContent/Description'

import styles from '../styles/Modal.module.scss'
import { NumberPeopleSupported } from '@/pages/ReportForm/components/ModalContent/NumberPeopleSupported'
import { MovementSupported } from '@/pages/ReportForm/components/ModalContent/MovementSupported'
import { Spent } from '@/pages/ReportForm/components/ModalContent/Spent'
import { Level } from '@/pages/ReportForm/components/ModalContent/Level'
import { Duration } from '@/pages/ReportForm/components/ModalContent/Duration'
import { Coworkers } from '@/pages/ReportForm/components/ModalContent/Coworkers'
import { CoworkerHours } from '@/pages/ReportForm/components/ModalContent/CoworkerHours'
import { CustomersCount } from '@/pages/ReportForm/components/ModalContent/CustomersCount'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { CountryUnit } from '@/pages/ReportForm/components/ModalContent/CountryUnit'
import { DynamicTranslations } from '@/components/translations/Translations'

export const DetailsModal: React.FC = () => {
  const modalType = useSelector<RootState>(state => state.form.modalType)

  const header = <ModalHeader ariaCloseTxt="Close modal" />

  const footer = (
    <ModalFooter className={styles.DetailsModalFooter}>
      <Button
        type="primary"
        href="https://iweof.sharepoint.com/:b:/r/teams/o365g_retailsustainabilitymatrix_issemal/Shared%20Documents/General/04%20Fair%20%26%20Equal/2.%20Social%20Impact%20IKEA%20x%20Neighborhoods/4.%20Social%20Impact%20Performance/Guiding%20material/Guideline%20Reporting%20Social%20Impact%20in%20the%20local%20Neighborhoods%20latest.pdf?csf=1&web=1&e=NiXYhp"
        target="_blank"
      >
        <DynamicTranslations id="form.details_modal.go_to" />
      </Button>
    </ModalFooter>
  )

  const handleClose = () => {
    store.dispatch(formSlice.actions.changeModal(null))
  }

  return (
    <Modal visible={Boolean(modalType)} handleCloseBtn={handleClose} className={styles.Modal}>
      <Sheets
        footer={footer}
        header={header}
        className={styles.DetailsModalLarge}
        size={modalType === FIELD_NAME.siteOrCountry ? 'small' : 'large'}
      >
        <ModalBody>
          <Show when={modalType === FIELD_NAME.siteOrCountry}>
            <CountryUnit />
          </Show>
          <Show when={modalType === FIELD_NAME.description}>
            <Description />
          </Show>
          <Show when={modalType === FIELD_NAME.people_supported_count}>
            <NumberPeopleSupported />
          </Show>
          <Show when={modalType === FIELD_NAME.main_movement_supported}>
            <MovementSupported />
          </Show>
          <Show when={modalType === FIELD_NAME.spent}>
            <Spent />
          </Show>
          <Show when={modalType === FIELD_NAME.level}>
            <Level />
          </Show>
          <Show when={modalType === FIELD_NAME.duration}>
            <Duration />
          </Show>
          <Show when={modalType === FIELD_NAME.coworkers_count}>
            <Coworkers />
          </Show>
          <Show when={modalType === FIELD_NAME.coworker_hours}>
            <CoworkerHours />
          </Show>
          <Show when={modalType === FIELD_NAME.customers_count}>
            <CustomersCount />
          </Show>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
