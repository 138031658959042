import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LanguagesType } from '@/types/translations'
import { LANGUAGES_LIST } from '@/constants/common'

type StoreType = {
  language: LanguagesType
}

const initialState = {
  language: localStorage.getItem('language') || LANGUAGES_LIST[0]
} as StoreType

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<LanguagesType>) {
      state.language = action.payload
    }
  }
})
