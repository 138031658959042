import { addDoc, collection, doc, getDoc, query } from 'firebase/firestore'
import { db } from '@/store'
import { onSnapshot } from '@firebase/firestore'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { CommentDBType, CommentType } from '@/types/comments'
import { UserData } from '@/types/user'

export const subscribeForComments = (initiativeId: string, callback: (items: CommentDBType[]) => void) => {
  const dataQuery = query(collection(db, 'initiatives', initiativeId, 'comments'))

  return onSnapshot(dataQuery, async snapshot => {
    const results: Promise<CommentDBType>[] = []

    snapshot.forEach(item => {
      results.push(getLatestVersion<CommentDBType>(item))
    })

    const items = await Promise.all(results)
    const itemsWithRelation = await Promise.all(
      items.map(async item => {
        const user = await getDoc(doc(db, 'users', item.user_id))
        return {
          ...item,
          user: (await user.data()) as UserData
        }
      })
    )
    callback(itemsWithRelation)
  })
}

export const setComment = (initiativeId: string, data: CommentType) => {
  addDoc(collection(db, 'initiatives', initiativeId, 'comments'), data)
}
