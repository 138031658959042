import React from 'react'
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { handleToggleDetails } from '@/pages/Explore/utils/details'
import { InfoTab } from '@/pages/Initiatives/components/Details/InfoTab'
import { useTranslation } from '@/components/translations/Translations'
import Text from '@ingka/text'
import clx from 'classnames'
import styles from '@/pages/Initiatives/styles/Initiatives.module.scss'
import { Show } from '@/components/conditions/Show'

export const ExploreDetails = () => {
  const details = useSelector((state: RootState) => state.explore.details)
  const titleText = useTranslation('explore.details.title')
  const [showDesc, setShowDesc] = React.useState(false)
  const [toggleAvailable, setShowToggle] = React.useState(false)
  const descRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    setShowToggle(Number(descRef.current?.clientHeight) >= 88)
  }, [details])

  const header = <ModalHeader title={titleText} />

  const handleToggleDesc = () => {
    setShowDesc(!showDesc)
  }

  return (
    <Modal visible={Boolean(details)} handleCloseBtn={handleToggleDetails(null)}>
      <Sheets footer={null} header={header} size="medium">
        <ModalBody>
          <Text headingSize="l" style={{ marginBottom: 16 }}>
            {details?.title}
          </Text>
          <div ref={descRef} className={clx(styles.DescriptionBlock, showDesc ? styles.Show : styles.Hide)}>
            <Text bodySize="m">{details?.description}</Text>
          </div>
          <Show when={toggleAvailable}>
            <Text bodySize="m" className={styles.Toggle} onClick={handleToggleDesc}>
              {showDesc ? 'Hide' : 'Show'}
            </Text>
          </Show>
          <InfoTab detailsData={details} readonly />
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
