import * as Yup from 'yup'

const REQUIRED = 'Field is required'

export const ChangeModalSchema = Yup.object().shape({
  comment: Yup.string().required(REQUIRED)
})

export const CommentConfirmationSchema = Yup.object().shape({
  comment: Yup.string().required(REQUIRED).max(200)
})
