import React from 'react'
import { FieldHookConfig, useField, useFormikContext } from 'formik'
import SSRIcon from '@ingka/ssr-icon'
import ChevronDown from '@ingka/ssr-icon/paths/chevron-down'
import ChevronUp from '@ingka/ssr-icon/paths/chevron-up'
import FormField from '@ingka/form-field'
import Radio from '@ingka/radio-button'
import Text from '@ingka/text'

import { Option } from '@/types/common'
import Button, { ButtonProps } from '@ingka/button'
import styles from './FilterField.module.scss'
import listStyles from '@/components/form/dropdown/List.module.scss'
import { Show } from '@/components/conditions/Show'
import { useOnClickOutside } from '@/components/hooks/outside'
import { ALL_OPTION } from '@/constants/common'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = FieldHookConfig<Option> &
  ButtonProps & {
    label: string
    list: Option<string | number>[]
    withLabel?: boolean
    withoutAll?: boolean
  }

export const FilterField: React.FC<Props> = props => {
  const { label, list, withLabel, withoutAll } = props
  const [field, , helper] = useField(props)
  const { submitForm } = useFormikContext()
  const [open, setOpen] = React.useState(false)
  const ref = React.useRef(null)

  useOnClickOutside(ref, () => setOpen(false))

  const labelText = withLabel ? (
    <>
      <DynamicTranslations id={label} />: <DynamicTranslations id={field.value?.label || ''} />
    </>
  ) : (
    <DynamicTranslations id={field.value?.label || label} />
  )

  return (
    <FormField className={styles.FormField}>
      <div ref={ref}>
        <Button className={styles.FilterField} small type="tertiary" onClick={() => setOpen(true)}>
          {labelText} <SSRIcon paths={open ? ChevronUp : ChevronDown} />
        </Button>
        <Show when={open}>
          <ListWrap>
            {[...(withoutAll ? [] : [ALL_OPTION]), ...list].map(item => (
              <div
                key={item.id}
                className={listStyles.Item}
                onClick={() => {
                  helper.setValue(item as Option)
                  setOpen(false)
                  submitForm()
                }}
              >
                <Text bodySize="m">
                  <DynamicTranslations id={item.label} />
                </Text>
                <Radio id={item.id} value={item.id} checked={field.value?.id === item.id} readOnly />
              </div>
            ))}
          </ListWrap>
        </Show>
      </div>
    </FormField>
  )
}

const ListWrap: React.FC<React.PropsWithChildren> = props => {
  const { children } = props

  return (
    <div className={listStyles.DropdownList}>
      <div className={listStyles.DropdownListWrapper}>{children}</div>
    </div>
  )
}
