import React, { CSSProperties } from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const NumberPeopleSupported: React.FC = () => {
  const gridColumns: CSSProperties = { gridTemplateColumns: '1fr 1fr 2fr' }
  const subRow: CSSProperties = { gridTemplateColumns: '1fr 1fr 2fr', border: 'none' }

  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.people_supported.title" />
      </Text>
      <Text bodySize="l">
        <DynamicTranslations id="guide.people_supported.p1" />
      </Text>
      <br />
      <Text bodySize="l" style={{ fontWeight: 'bold' }}>
        <DynamicTranslations id="guide.people_supported.list_title" />
      </Text>
      <DynamicTranslations id="guide.people_supported.list" withTags />
      <div className={styles.Table}>
        <DynamicTranslations
          id="guide.people_supported.table_head"
          className={styles.TableRowHead}
          withTags
          style={gridColumns}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row1"
          className={styles.TableRow}
          withTags
          style={subRow}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row1.2"
          className={styles.TableRow}
          withTags
          style={subRow}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row2"
          className={styles.TableRow}
          withTags
          style={gridColumns}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row3"
          className={styles.TableRow}
          withTags
          style={gridColumns}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row4"
          className={styles.TableRow}
          withTags
          style={gridColumns}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row5"
          className={styles.TableRow}
          withTags
          style={gridColumns}
        />
        <DynamicTranslations
          id="guide.people_supported.table_row6"
          className={styles.TableRow}
          withTags
          style={gridColumns}
        />
      </div>
    </>
  )
}
