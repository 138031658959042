import DocumentIcon from '@ingka/ssr-icon/paths/document'
import ImageIcon from '@ingka/ssr-icon/paths/image'

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (bytes == 0) return '0 Bytes'
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getIcon = (type: string) => {
  switch (type) {
    case 'application/pdf':
      return DocumentIcon

    default:
      return ImageIcon
  }
}
