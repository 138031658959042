import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { isEmpty, isNumber } from 'lodash'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { updateInitiativeStatus } from '@/pages/Initiatives/services'
import { STATUSES, TOAST_STATUS_CHANGED } from '@/constants/statuses'
import { store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { trackActivity } from '@/pages/Activity/utils/trackActivity'
import { ACTIVITY_TYPES } from '@/constants/common'
import { loadActivities } from '@/pages/Activity/providers/ActivityProvider'
import { showToast } from '@/components/toast/ToastContainer'

export const submitForReview = (initiative: InitiativesListItemType, navigate: NavigateFunction) => async () => {
  const user = store.getState().auth.user
  if (!user) return
  const isValid = Object.keys(FIELD_NAME).every(key => checkIsValidField(key, initiative))

  if (!isValid) {
    navigate(`/edit/${initiative.id}`)
    return
  }

  try {
    await updateInitiativeStatus(initiative.id, STATUSES[1], user.id)
    await trackActivity({
      type: ACTIVITY_TYPES.status,
      user_id: user.id,
      initiative_id: initiative.id,
      status: STATUSES[1]
    })

    loadActivities(initiative.id)

    store.dispatch(
      initiativesSlice.actions.setInitiativeStatus({
        id: initiative.id,
        status: STATUSES[1]
      })
    )
    showToast(`${TOAST_STATUS_CHANGED} Country Review`)
  } catch (e) {
    console.error(e)
  }
}

const checkIsValidField = (key: string, data: InitiativesListItemType): boolean => {
  switch (key) {
    case 'siteOrCountry':
      return Boolean(data.country_code || data.site)

    case 'country':
    case 'people_impacted_comment':
    case 'type_of_initiative_comment':
    case 'comment':
      return true
  }

  if (isNumber(data[key as keyof InitiativesListItemType])) return Boolean(data[key as keyof InitiativesListItemType])

  return !isEmpty(data[key as keyof InitiativesListItemType])
}
