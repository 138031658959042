import React from 'react'

import styles from '../styles/Analytics.module.scss'
import { ContentTabs } from '@/pages/Analytics/components/ContentTabs'
import { DataReportingProvider } from '@/pages/Analytics/providers/DataReportingProvider'
import { DetailsModal } from '@/pages/Analytics/components/DetailsModal'
import { QuickInfoBlock } from '@/pages/Analytics/components/QuickInfoBlock'

export const AnalyticsPage = () => {
  return (
    <DataReportingProvider>
      <DetailsModal />
      <AnalyticsPageContent />
    </DataReportingProvider>
  )
}

export const AnalyticsPageContent = () => {
  return (
    <div className={styles.AnalyticsPageWrap}>
      <QuickInfoBlock />
      <ContentTabs />
    </div>
  )
}
