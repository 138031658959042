import React, { CSSProperties } from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const Level: React.FC = () => {
  const gridColumns: CSSProperties = { gridTemplateColumns: '1fr 2fr 2fr' }

  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.level.title" />
      </Text>
      <div className={styles.Table}>
        <DynamicTranslations id="guide.level.table_head" className={styles.TableRowHead} style={gridColumns} withTags />
        <DynamicTranslations id="guide.level.table_row1" className={styles.TableRow} style={gridColumns} withTags />
        <DynamicTranslations id="guide.level.table_row2" className={styles.TableRow} style={gridColumns} withTags />
      </div>
    </>
  )
}
