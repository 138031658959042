import React from 'react'
import { Field } from 'formik'
import Search from '@ingka/search'
import { FieldProps } from 'formik/dist/Field'
import styles from '../styles/Admin.module.scss'

export const UsersSearch: React.FC = () => {
  return (
    <Field name="search">
      {({ field, form }: FieldProps) => (
        <Search
          id="search"
          className={styles.Search}
          onClear={() => {
            form.setFieldValue('search', '')
            form.submitForm()
          }}
          {...field}
          onBlur={() => form.submitForm()}
        />
      )}
    </Field>
  )
}
