import { store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { editInitiative } from '@/pages/Initiatives/services'
import { showToast } from '@/components/toast/ToastContainer'

export const handleToggleDeleteConfirmation = (id: string | null) => () => {
  store.dispatch(initiativesSlice.actions.setToDelete(id))
}

export const handleDeleteInitiative = async () => {
  const id = store.getState().initiatives.toDeleteId

  if (!id) return

  await editInitiative(id, { deleted: true })

  handleToggleDeleteConfirmation(null)()
  showToast('Initiative deleted')
}
