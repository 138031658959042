import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import clx from 'classnames'

import { Show } from '@/components/conditions/Show'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { PEOPLE_IMPACTED_OPTIONS, TYPE_OF_INITIATIVE_OPTIONS } from '@/pages/ReportForm/constants/options'
import { Option } from '@/types/common'
import FormField, { FormFieldProps } from '@ingka/form-field'
import TextAreaIngka, { TextAreaProps } from '@ingka/text-area'
import style from '@/components/form/textarea/TextArea.module.scss'
import formStyles from '../../styles/NewReport.module.scss'

type Props = FieldHookConfig<string> & TextAreaProps & FormFieldProps

const parent = {
  [FIELD_NAME.type_of_initiative_comment]: FIELD_NAME.type_of_initiative,
  [FIELD_NAME.people_impacted_comment]: FIELD_NAME.people_impacted
}

const otherOption = {
  [FIELD_NAME.type_of_initiative_comment]: TYPE_OF_INITIATIVE_OPTIONS[TYPE_OF_INITIATIVE_OPTIONS.length - 1],
  [FIELD_NAME.people_impacted_comment]: PEOPLE_IMPACTED_OPTIONS[PEOPLE_IMPACTED_OPTIONS.length - 1]
}

export const OtherComment: React.FC<Props> = props => {
  const { id, label, fieldHelper, characterLimit } = props
  const [parentField] = useField({ name: parent[props.name] })
  const [field, meta] = useField(props)

  const isOther = parentField.value.some((item: Option) => item.id === otherOption[props.name].id)

  return (
    <Show when={isOther}>
      <FormField
        fieldHelper={fieldHelper}
        className={clx(formStyles.OtherComment, style.Field)}
        characterLimit={isOther ? characterLimit : undefined}
        valid={!meta.error}
        shouldValidate={Boolean(meta.touched && meta.error)}
        validation={{
          id: `validation-${field.name}`,
          msg: meta.error,
          type: 'error'
        }}
      >
        <TextAreaIngka {...field} className={style.TextArea} id={id} label={label} />
      </FormField>
    </Show>
  )
}
