import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { DynamicTranslations } from '@/components/translations/Translations'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { formatNumber } from '@/utils/format'
import { Show } from '@/components/conditions/Show'

type Props = {
  title: string
  subTitle?: string
  handler: (data: InitiativesListItemType[]) => number
}

export const QuickDataCard: React.FC<Props> = props => {
  const { title, subTitle, handler } = props
  const data = useSelector((state: RootState) => state.analytics.initiatives)
  const value = formatNumber(handler(data))

  return (
    <div className={styles.QuickInfoCard}>
      <div className={styles.TitleBlock}>
        <Text headingSize="s">
          <DynamicTranslations id={title} />
        </Text>
        <div className={styles.Title}>
          <Show when={Boolean(subTitle)}>
            <DynamicTranslations id={subTitle || ''} />
          </Show>
        </div>
      </div>
      <Text headingSize="l">{value}</Text>
    </div>
  )
}

export const QuickDataCardFY24: React.FC<Props> = props => {
  const { title, subTitle, handler } = props
  const data = useSelector((state: RootState) => state.analytics.initiatives)
  const value = formatNumber(handler(data))

  return (
    <div className={styles.QuickInfoCardFY24}>
      <div className={styles.TitleBlock}>
        <div className={styles.Title}>
          <DynamicTranslations id={title} />
        </div>
        <Text headingSize="s">
          <Show when={Boolean(subTitle)}>
            <DynamicTranslations id={subTitle || ''} />
          </Show>
        </Text>
      </div>
      <div className={styles.RightSide}>
        <span>{value}</span>
      </div>
    </div>
  )
}
