import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import clx from 'classnames'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { getTableDataFY24 } from '@/pages/Analytics/utils/table'
import { IMPACT_TYPES_FY24 } from '@/constants/common'
import { RowItem } from '@/pages/Analytics/components/FY24Logic/Table/RowItem'
import { DynamicTranslations } from '@/components/translations/Translations'
import { formatNumber } from '@/utils/format'

export const PSTable = () => {
  const data = useSelector((state: RootState) => state.analytics.initiatives)
  const countries = useSelector((state: RootState) => state.sites.countries)
  const { country_code } = useSelector((state: RootState) => state.filters.filters)
  const isFilteredByCountry = country_code?.value !== 'all'

  const improveData = getTableDataFY24(data, IMPACT_TYPES_FY24.improve, countries, isFilteredByCountry)
  const transformData = getTableDataFY24(data, IMPACT_TYPES_FY24.transform, countries, isFilteredByCountry)
  const connectData = getTableDataFY24(data, IMPACT_TYPES_FY24.connect, countries, isFilteredByCountry)

  return (
    <div className={styles.ContentTable}>
      <div className={styles.TableHead}>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.table.head.initiative_type" />
        </div>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.table.head.country_unit" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.people_supported" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.coworkers" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.customers" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.hours" />
        </div>
        <div className={clx(styles.HeadItem, styles.Right)}>
          <DynamicTranslations id="data_reporting.table.head.spent" />
        </div>
      </div>
      <RowItem title="Connect" tableData={connectData} />
      <RowItem title="Improve" tableData={improveData} />
      <RowItem title="Transform" tableData={transformData} />
      <div className={styles.TableTotal}>
        <div className={styles.TableRow}>
          <Text headingSize="s" className={styles.TotalLabel}>
            Grand Total
          </Text>
          <div />
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(
              improveData.total.participants_count +
                transformData.total.participants_count +
                connectData.total.participants_count
            )}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(
              improveData.total.coworkers_engaged_count +
                transformData.total.coworkers_engaged_count +
                connectData.total.coworkers_engaged_count
            )}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(
              improveData.total.customers_engaged_count +
                transformData.total.customers_engaged_count +
                connectData.total.customers_engaged_count
            )}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(improveData.total.hours + transformData.total.hours + connectData.total.hours)}
          </Text>
          <Text headingSize="s" className={styles.Right}>
            {formatNumber(improveData.total.spent + transformData.total.spent + connectData.total.spent)}
          </Text>
        </div>
      </div>
    </div>
  )
}
