import React from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const Spent: React.FC = () => {
  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.budget.title" />
      </Text>
      <div className={styles.Table}>
        <DynamicTranslations id="guide.budget.table_head" className={styles.TableRowHead} withTags />
        <DynamicTranslations id="guide.budget.table_row1" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row2" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row3" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row4" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row5" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row6" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row7" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row8" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.budget.table_row9" className={styles.TableRow} withTags />
      </div>
    </>
  )
}
