import { USER_ROLES } from '@/constants/common'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { UserData } from '@/types/user'
import React from 'react'

export const canCreateInitiative = (user?: UserData | null): boolean => {
  const role = user?.role

  return role === USER_ROLES.user || role === USER_ROLES.countryManager
}

export const useCanCreateInitiative = (): boolean => {
  const user = useSelector((state: RootState) => state.auth.user)

  return React.useMemo(() => canCreateInitiative(user), [user])
}
