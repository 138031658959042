import { collection, doc, query } from 'firebase/firestore'
import { db } from '@/store'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { UserData } from '@/types/user'
import { onSnapshot, updateDoc } from '@firebase/firestore'

export const getUsers = async (callback: (users: UserData[]) => void) => {
  return onSnapshot(query(collection(db, 'users')), async querySnapshot => {
    const result: Promise<UserData>[] = []

    querySnapshot.forEach(item => {
      result.push(getLatestVersion<UserData>(item))
    })

    const items = await Promise.all(result)
    callback(items)
  })
}

export const updateUser = async (userId: string, data: Partial<UserData>) => {
  const docRef = doc(db, 'users', userId)
  await updateDoc(docRef, data)
}
