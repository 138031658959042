import React from 'react'
import { useDropzone } from 'react-dropzone'
import styles from '../../styles/Attachments.module.scss'
import Text from '@ingka/text'
import { Show } from '@/components/conditions/Show'
import Button from '@ingka/button'
import SSRIcon from '@ingka/ssr-icon'
import TrashIcon from '@ingka/ssr-icon/paths/trash-can'
import { uploadFile } from '@/pages/ReportForm/services'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { isEmpty } from 'lodash'
import { AttachmentFileType, formSlice } from '@/pages/ReportForm/slices/form'
import { formatFileSize, getIcon } from '@/utils/files'
import HelperText from '@ingka/helper-text'
import clx from 'classnames'
import { MAXIMUM_FILE_SIZE } from '@/pages/ReportForm/constants/size'
import { showToast } from '@/components/toast/ToastContainer'
import { DynamicTranslations, StaticTranslation } from '@/components/translations/Translations'

const AttachmentsImpl = () => {
  const attachments = useSelector((state: RootState) => state.form.attachments)

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      if (isEmpty(acceptedFiles)) return

      acceptedFiles
        .filter(item => {
          if (item.size >= MAXIMUM_FILE_SIZE) {
            showToast(StaticTranslation('form.attachment.toast_error', { fileName: item.name }))
            return false
          }
          return true
        })
        .forEach((file, index) => uploadFile(file, index + attachments.length))
    },
    [attachments.length]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/pdf': ['.pdf']
    }
  })

  const progress = React.useMemo(() => {
    if (isEmpty(attachments)) return 100

    return (
      attachments.reduce((result, current) => {
        return result + current.progress
      }, 0) / attachments.length
    )
  }, [attachments])

  const size = React.useMemo(() => {
    return attachments.reduce((result, current) => {
      return result + current.size
    }, 0)
  }, [attachments])

  const sizeToUpload = React.useMemo(() => {
    return attachments.reduce((result, current) => result + current.fileSize, 0)
  }, [attachments])

  return (
    <div>
      <div {...getRootProps()} className={styles.DropZone}>
        <ProgressBar progress={progress} size={size} sizeToUpload={sizeToUpload} />
        <Show when={progress === 100}>
          <Text headingSize="s" className={styles.HeadText}>
            <DynamicTranslations id="form.attachment.title" />
          </Text>
          <Text bodySize="s" className={styles.ContentText}>
            <DynamicTranslations id="form.attachment.sub_title" withTags />
          </Text>
          <Text bodySize="s" className={styles.HelperText}>
            <br />
            <DynamicTranslations id="form.attachment.size" />
          </Text>
          <input {...getInputProps()} />
        </Show>
      </div>
      <Files attachments={attachments} />
    </div>
  )
}

const Files: React.FC<{ attachments: AttachmentFileType[] }> = props => {
  const { attachments } = props

  if (isEmpty(attachments)) return null

  const handleRemove = (index: number) => () => {
    store.dispatch(formSlice.actions.setAttachmentToDelete(index))
  }

  return (
    <div className={styles.Attachments}>
      <Text headingSize="xs">Attached files</Text>
      {attachments.map((item, index) => {
        return (
          <div key={`file-${item.id}`} className={styles.AttachmentItemWrap}>
            <div className={clx(styles.AttachmentItem, item.error ? styles.Error : null)}>
              <SSRIcon paths={getIcon(item.fileType)} />
              <div className={styles.Content}>
                <Text bodySize="m">{item.fileName}</Text>
                <Text bodySize="s" className={styles.AddInfo}>
                  {item.fileType.split('/')[1]} | {formatFileSize(item.fileSize)}
                </Text>
              </div>
              <Button iconOnly ssrIcon={TrashIcon} small onClick={handleRemove(index)} />
            </div>
            <Show when={Boolean(item.error)}>
              <HelperText shouldValidate>{item.error}</HelperText>
            </Show>
          </div>
        )
      })}
    </div>
  )
}

const ProgressBar: React.FC<{ progress: number; size: number; sizeToUpload: number }> = props => {
  const { progress, size, sizeToUpload } = props
  if (progress === 100) return null

  return (
    <>
      <div className={styles.Progress}>
        <div className={styles.Indicator} style={{ width: `${progress}%` }} />
      </div>
      <Text bodySize="s">
        {formatFileSize(size)} of {formatFileSize(sizeToUpload)}
      </Text>
    </>
  )
}

export const Attachments = React.memo(AttachmentsImpl)
