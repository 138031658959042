import React from 'react'
import { Form, Formik, Field } from 'formik'
import Search from '@ingka/search'
import { FieldProps } from 'formik/dist/Field'
import { store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import styles from '../../styles/Initiatives.module.scss'
import { useTranslation } from '@/components/translations/Translations'

export const ListSearch: React.FC = () => {
  const searchTranslation = useTranslation('initiatives.search')

  return (
    <Formik
      initialValues={{ search: '' }}
      onSubmit={({ search }) => {
        store.dispatch(initiativesSlice.actions.setSearch(search))
      }}
    >
      <Form>
        <Field name="search">
          {({ field, form }: FieldProps) => (
            <Search
              id="search"
              className={styles.Search}
              onClear={() => {
                form.setFieldValue('search', '')
                form.submitForm()
              }}
              {...field}
              placeholder={searchTranslation}
              onBlur={() => form.submitForm()}
            />
          )}
        </Field>
      </Form>
    </Formik>
  )
}
