import React from 'react'
import { useFormikContext } from 'formik'

export const ScrollToError = () => {
  const { submitCount, isValid } = useFormikContext()

  React.useEffect(() => {
    // Wrap the code in setTimeout to make sure it runs after the DOM has been
    // updated and has the error message elements.
    // Can maybe use useLayoutEffect instead.
    setTimeout(() => {
      // Only run on submit
      if (submitCount === 0) {
        return
      }

      // Find the first error message
      const errorMessageSelector = '[data-field-error="true"]'
      const firstErrorMessage = document.querySelector(errorMessageSelector)
      if (!firstErrorMessage) {
        console.warn(`Form failed validation but no error field was found with selector: ${errorMessageSelector}`)
        return
      }

      firstErrorMessage.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }, [submitCount, isValid])

  return null
}
