import { SiteType } from '@/types/siteList'
import { Option } from '@/types/common'
import { sortBy } from 'lodash'

export const mapFromSitesToFunctionCode = (sites: SiteType[]): Option[] => {
  return sortBy(
    sites.reduce<Option[]>((result, current) => {
      const haveCountry = result.find(item => item.value === current.function_code)

      if (haveCountry) return result

      return [
        ...result,
        {
          id: current.function_code,
          label: current.function_code,
          value: current.function_code
        }
      ]
    }, []),
    'id'
  )
}
