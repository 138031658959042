import React from 'react'
import { Unsubscribe } from '@firebase/firestore'
import { subscribeForInitiatives } from '@/pages/Explore/services'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { filterData, mapToFilterBE } from '@/pages/Explore/utils/mapper'
import { mapToList } from '@/pages/Initiatives/utils/mapper'
import { exploreSlice } from '@/pages/Explore/slices/explore'
import { loadingSlice } from '@/storage/loading'
import { EXPLORE_LOADING } from '@/pages/Explore/constants/filters'

export const ExploreProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props
  const unsub = React.useRef<Unsubscribe | null>(null)
  const filters = useSelector((state: RootState) => state.explore.filters)

  React.useEffect(() => {
    return () => {
      unsub.current && unsub.current()
      store.dispatch(exploreSlice.actions.setInitiatives([]))
    }
  }, [])

  React.useEffect(() => {
    const mappedFilters = mapToFilterBE(filters)
    unsub.current = subscribeForInitiatives(mappedFilters, initiatives => {
      const mappedInitiatives = mapToList(initiatives)
      const filtered = filterData(mappedInitiatives, mappedFilters)

      store.dispatch(exploreSlice.actions.setInitiatives(filtered))
      store.dispatch(loadingSlice.actions.setLoading({ name: EXPLORE_LOADING, value: false }))
    })
  }, [filters])

  return <>{children}</>
}
