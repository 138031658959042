import React from 'react'
import Text from '@ingka/text'
import Button from '@ingka/button'

import styles from '../styles/Initiatives.module.scss'
import { InitiativesList } from '@/pages/Initiatives/components/List/InitiativesList'
import { INITIATIVES_LOADER, InitiativesProvider } from '@/pages/Initiatives/providers/Initiatives'
import { useNavigate } from 'react-router-dom'
import { InitiativeDetails } from '@/pages/Initiatives/components/Details/InitiativeDetails'
import { ListSearch } from '@/pages/Initiatives/components/List/ListSearch'
import { Loader } from '@/components/loader/Loader'
import { DeleteConfirmation } from '@/pages/Initiatives/components/Details/DeleteConfirmation'
import { DynamicTranslations } from '@/components/translations/Translations'

export const UserInitiatives = () => {
  const navigate = useNavigate()

  return (
    <InitiativesProvider>
      <div className={styles.InitiativesPage}>
        <div className={styles.InitiativeList}>
          <div className={styles.Head}>
            <Text headingSize="l">
              <DynamicTranslations id="initiatives" />
            </Text>
            <Button type="primary" small onClick={() => navigate('/edit')}>
              <DynamicTranslations id="initiatives.add_new_initiative" />
            </Button>
          </div>
          <ListSearch />
          <Loader name={INITIATIVES_LOADER}>
            <InitiativesList />
          </Loader>
        </div>
        <div className={styles.Divider} />
        <InitiativeDetails />
      </div>
      <DeleteConfirmation />
    </InitiativesProvider>
  )
}
