import React from 'react'
import { FieldHookConfig, useField } from 'formik'
import SelectIngka, { Option as OptionIngka, SelectProps } from '@ingka/select'
import FormField, { FormFieldProps } from '@ingka/form-field'

import { Option } from '@/types/common'

type Props = FieldHookConfig<string> &
  FormFieldProps &
  Omit<SelectProps, 'list'> & {
    id: string
    list: Option[]
  }

export const Select: React.FC<Props> = props => {
  const { id, label, fieldHelper, list } = props
  const [field, meta] = useField(props)

  return (
    <FormField
      data-field-error={Boolean(meta.error)}
      fieldHelper={fieldHelper}
      valid={!meta.error}
      shouldValidate={Boolean(meta.touched && meta.error)}
      validation={{
        id: `validation-${field.name}`,
        msg: meta.error,
        type: 'error'
      }}
    >
      <SelectIngka {...field} id={id} label={label}>
        {list.map(item => (
          <OptionIngka key={item.id} name={item.label} value={item.value} />
        ))}
      </SelectIngka>
    </FormField>
  )
}
