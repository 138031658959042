import React from 'react'
import clx from 'classnames'
import styles from '@/pages/Initiatives/styles/Initiatives.module.scss'
import { StatusTag } from '@/pages/Initiatives/components/Status'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'
import { useSearchParams } from 'react-router-dom'

type Props = {
  initiative: InitiativesListItemType
}

export const InitiativeItem: React.FC<Props> = props => {
  const { initiative } = props
  const { details } = useSelector((state: RootState) => state.initiatives)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleClickItem = () => {
    store.dispatch(initiativesSlice.actions.changeDetails(initiative))

    if (searchParams.get('preview_id')) {
      setSearchParams({})
    }
  }

  return (
    <div
      className={clx(styles.InitiativeItem, details?.id === initiative.id ? styles.active : null)}
      onClick={handleClickItem}
    >
      <StatusTag status={initiative.status} />
      <div className={styles.Date}>{initiative.report_period}</div>
      <Text className={styles.Title} headingSize="m">
        {initiative.title}
      </Text>
    </div>
  )
}
