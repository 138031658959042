import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { NewReport } from '@/pages/ReportForm/components/NewReport'
import { Header } from '@/components/header/Header'
import { UserInitiatives } from '@/pages/Initiatives/components/UserInitiatives'
import { EditReport } from '@/pages/ReportForm/components/EditReport'
import { SitesProvider } from '@/providers/Sites'
import { CountryInitiatives } from '@/pages/Initiatives/components/CountryInitiatives'
import { USER_ROLES } from '@/constants/common'
import { GlobalInitiatives } from '@/pages/Initiatives/components/GlobalInitiatives'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { AnalyticsPage } from '@/pages/Analytics/components/AnalyticsPage'
import { PrivacyPolicy } from '@/pages/PrivacyPolicy/components/PrivacyPolicy'
import { AdminPage } from '@/pages/Admin/components/AdminPage'
import { Explore } from '@/pages/Explore/components/Explore'

export const Router: React.FC = () => {
  const { user, error } = useSelector((state: RootState) => state.auth)

  if (error) return <p>{error}</p>
  if (!user) return null

  const countryManagerRoutes = (
    <>
      <Route path="edit/:initiativeId" element={<EditReport />} />
      <Route path="edit" element={<NewReport />} />
      <Route path="/" element={<CountryInitiatives />} />
    </>
  )

  const globalManagerRoutes = (
    <>
      <Route path="/" element={<GlobalInitiatives />} />
    </>
  )

  const userRoutes = (
    <>
      <Route path="edit/:initiativeId" element={<EditReport />} />
      <Route path="edit" element={<NewReport />} />
      <Route path="/" element={<UserInitiatives />} />
    </>
  )

  return (
    <SitesProvider>
      <Header>
        <Routes>
          {!user.role || user.role === USER_ROLES.user ? userRoutes : null}
          {user.role === USER_ROLES.countryManager ? countryManagerRoutes : null}
          {user.role === USER_ROLES.globalManager ? globalManagerRoutes : null}
          <Route path="/explore" element={<Explore />} />
          <Route path="/data-reporting" element={<AnalyticsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {user.admin ? <Route path="/users" element={<AdminPage />} /> : null}
        </Routes>
      </Header>
    </SitesProvider>
  )
}
