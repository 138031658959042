import React, { CSSProperties } from 'react'
import Text from '@ingka/text'

import { DynamicTranslations } from '@/components/translations/Translations'

export const MovementSupported: React.FC = () => {
  const bold: CSSProperties = { fontWeight: 'bold' }

  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.movement.title" />
      </Text>
      <Text bodySize="l">
        <DynamicTranslations id="guide.movement.p1" />
      </Text>

      <br />
      <Text bodySize="l" style={bold}>
        <DynamicTranslations id="guide.movement.list1_title" />
      </Text>
      <DynamicTranslations id="guide.movement.list1" withTags />

      <br />
      <Text bodySize="l" style={bold}>
        <DynamicTranslations id="guide.movement.list2_title" />
      </Text>
      <DynamicTranslations id="guide.movement.list2" withTags />

      <br />
      <Text bodySize="l" style={bold}>
        <DynamicTranslations id="guide.movement.list3_title" />
      </Text>
      <DynamicTranslations id="guide.movement.list3" withTags />

      <br />
      <Text bodySize="l" style={bold}>
        <DynamicTranslations id="guide.movement.list4_title" />
      </Text>
      <DynamicTranslations id="guide.movement.list4" withTags />

      <br />
      <Text bodySize="l" style={bold}>
        <DynamicTranslations id="guide.movement.list5_title" />
      </Text>
      <DynamicTranslations id="guide.movement.list5" withTags />

      <br />
      <Text bodySize="l" style={bold}>
        <DynamicTranslations id="guide.movement.list6_title" />
      </Text>
      <DynamicTranslations id="guide.movement.list6" withTags />
    </>
  )
}
