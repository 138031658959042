import React from 'react'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { FormLabel } from '@/pages/ReportForm/components/Labels'
import { MAIN_MOVEMENT_OPTIONS, MOVEMENT_OPTIONS } from '@/pages/ReportForm/constants/options'
import { DynamicTranslations } from '@/components/translations/Translations'
import { CheckboxGroup } from '@/components/form/checkbox/CheckboxGroup'
import { Show } from '@/components/conditions/Show'
import { RadioGroup } from '@/components/form/radio/RadioGroup'
import { useFormikContext } from 'formik'
import { InitiativeFormType } from '@/types/initiative'
import { isEmpty } from 'lodash'

export const Movement = () => {
  const { values } = useFormikContext<InitiativeFormType>()

  const movementOptions = React.useMemo(() => {
    return MOVEMENT_OPTIONS.filter(item => item.value !== values.main_movement_supported?.value)
  }, [values.main_movement_supported])

  return (
    <>
      <RadioGroup
        name={FIELD_NAME.main_movement_supported}
        label={<FormLabel id={FIELD_NAME.main_movement_supported} />}
        list={MAIN_MOVEMENT_OPTIONS}
        fieldHelper={{
          id: 'main-movement-hlp',
          msg: <DynamicTranslations id="form.field.main_movement_supported.helper" />
        }}
      />
      <Show when={!isEmpty(values.main_movement_supported)}>
        <CheckboxGroup
          name={FIELD_NAME.movement_supported}
          label={<FormLabel id={FIELD_NAME.movement_supported} />}
          list={movementOptions}
          fieldHelper={{
            id: 'movement-hlp',
            msg: <DynamicTranslations id="form.field.movement_supported.helper" />
          }}
        />
      </Show>
    </>
  )
}
