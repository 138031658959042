import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import Text from '@ingka/text'
import { DynamicTranslations } from '@/components/translations/Translations'
import { formatNumber } from '@/utils/format'

type Props = {
  values: {
    name: string
    color: string
    value: number
  }[]
  title: string
}

export const DonutChart: React.FC<Props> = props => {
  const { values, title } = props

  const radius = 32
  const strokeWidth = 24
  const circumference = radius * 2 * Math.PI

  let used = 0
  const total = values.reduce((result, current) => result + current.value, 0)

  return (
    <div className={styles.ChartQuickInfoWrap}>
      <div className={styles.ChartQuickInfo}>
        <Text className={styles.QuickInfoTitle} headingSize="xs">
          <DynamicTranslations id={title} />
        </Text>
        <Text headingSize="m">{formatNumber(total)}</Text>
      </div>
      <svg width="100%" height="100%" viewBox="0 0 100 100" className="SunburstGraph">
        {values.map(({ name, value, color }) => {
          const element = (
            <circle
              data-index={name}
              key={name}
              className="SunburstGraphSegment"
              cx="50"
              cy="50"
              r={radius}
              fill="transparent"
              stroke={color}
              strokeWidth={strokeWidth}
              strokeDasharray={`${circumference * (value / total)} ${circumference}`}
              strokeDashoffset={`${circumference * (used / total) * -1}`}
              // SVG draws segments clockwise, that's wanted for the inner circle.
              // But the design draws the outer circle counter-clockwise, so we mirror it vertically in place.
            />
          )
          used += value
          return element
        })}
      </svg>
    </div>
  )
}
