import React from 'react'

import styles from '../styles/Initiatives.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { applyQuickFilter, resetQuickFilter } from '@/pages/Initiatives/utils/review'
import { Show } from '@/components/conditions/Show'
import { STATUSES } from '@/constants/statuses'
import { ALL_OPTION, USER_ROLES } from '@/constants/common'
import { DynamicTranslations } from '@/components/translations/Translations'

const useIsReviewing = () => {
  const filters = useSelector((state: RootState) => state.filters.filters)
  const role = useSelector((state: RootState) => state.auth.user?.role)

  if (
    filters.country_code?.id !== ALL_OPTION.id ||
    filters.function_code?.id !== ALL_OPTION.id ||
    filters.report_period?.id !== ALL_OPTION.id
  )
    return { isReviewing: false, isGlobal: role === USER_ROLES.globalManager }

  return {
    isReviewing:
      role === USER_ROLES.countryManager ? filters.status?.id === STATUSES[1] : filters.status?.id === STATUSES[2],
    isGlobal: role === USER_ROLES.globalManager
  }
}

const InitiativesLeftImpl = () => {
  const count = useSelector((state: RootState) => state.initiatives.initiativesLeftCount)
  const { isReviewing, isGlobal } = useIsReviewing()
  if (!count) return null

  return (
    <div className={styles.InitiativesLeft}>
      <div className={styles.Indicator} />
      <div className={styles.Title}>
        {count} <DynamicTranslations id="initiatives.left_for" />{' '}
        {isGlobal ? <DynamicTranslations id="status.global_review" /> : <DynamicTranslations id="status.submitted" />}
      </div>
      <Show when={!isReviewing}>
        <div className={styles.Review} onClick={applyQuickFilter}>
          <DynamicTranslations id="initiatives.left_for.review" />
        </div>
      </Show>
      <Show when={isReviewing}>
        <div className={styles.Review} onClick={resetQuickFilter}>
          <DynamicTranslations id="initiatives.left_for.view_all" />
        </div>
      </Show>
    </div>
  )
}

export const InitiativesLeft = React.memo(InitiativesLeftImpl)
