import React from 'react'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'
import Button from '@ingka/button'
import Text from '@ingka/text'
import styles from '@/pages/Initiatives/styles/Confirmation.module.scss'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { removeFileFromStorage } from '@/pages/ReportForm/services'
import { applyAttachmentDelete } from '@/pages/ReportForm/utils/submit'
import { useParams } from 'react-router-dom'
import { DynamicTranslations } from '@/components/translations/Translations'

export const ConfirmDelete: React.FC = () => {
  const attachmentToDelete = useSelector((state: RootState) => state.form.attachmentToDelete)
  const attachments = useSelector((state: RootState) => state.form.attachments)
  const { initiativeId } = useParams()

  const header = <ModalHeader ariaCloseTxt="Close confirmation" />

  const handleClose = () => store.dispatch(formSlice.actions.setAttachmentToDelete(null))

  const handleDeleteFile = async () => {
    if (attachmentToDelete === null) return

    const toDelete = attachments[attachmentToDelete]
    await removeFileFromStorage(toDelete.id, toDelete.fileName)
    store.dispatch(formSlice.actions.removeAttachment(attachmentToDelete))

    if (initiativeId) await applyAttachmentDelete(initiativeId)

    handleClose()
  }

  return (
    <Modal visible={attachmentToDelete !== null} handleCloseBtn={handleClose}>
      <Prompt className={styles.ConfirmationModal} title="Delete attachment" footer={null} header={header}>
        <Text className={styles.ModalText} bodySize="l">
          <DynamicTranslations id="form.attachment.delete.title" />
        </Text>
        <div className={styles.ButtonHolder}>
          <Button onClick={handleClose}>
            <DynamicTranslations id="cancel" />
          </Button>
          <Button type="primary" onClick={handleDeleteFile}>
            <DynamicTranslations id="delete" />
          </Button>
        </div>
      </Prompt>
    </Modal>
  )
}
