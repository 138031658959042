import { Option } from '@/types/common'
import React from 'react'

import styles from '../../styles/Initiatives.module.scss'
import Text from '@ingka/text'
import Radio from '@ingka/radio-button'
import { Show } from '@/components/conditions/Show'
import { useOnClickOutside } from '@/components/hooks/outside'
import ChevronUpIcon from '@ingka/ssr-icon/paths/chevron-up'
import ChevronDownIcon from '@ingka/ssr-icon/paths/chevron-down'
import SSRIcon from '@ingka/ssr-icon'

type Props = {
  value: string
  list: Option[]
  onChange: (value: string) => void
}

export const Select: React.FC<Props> = props => {
  const { value, list, onChange } = props
  const [opened, setOpened] = React.useState(false)
  const ref = React.useRef(null)

  useOnClickOutside(
    ref,
    () => {
      if (opened) setOpened(false)
    },
    opened
  )

  const handleChange = (value: string) => {
    setOpened(false)
    onChange(value)
  }

  const optionValue = list.find(item => item.value === value)

  return (
    <div ref={ref} className={styles.DetailsSelect}>
      <div className={styles.Field} onClick={() => setOpened(true)}>
        <Text className={styles.FieldValue} bodySize="l">
          {value}
        </Text>
        <SSRIcon paths={opened ? ChevronUpIcon : ChevronDownIcon} />
      </div>
      <Show when={opened}>
        <List list={list} value={optionValue} onChange={handleChange} />
      </Show>
    </div>
  )
}

const List: React.FC<{ list: Option[]; value?: Option; onChange: (value: string) => void }> = props => {
  return (
    <div className={styles.List}>
      {props.list.map(item => {
        return (
          <div className={styles.Item} key={item.id} onClick={() => props.onChange(item.value)}>
            <Radio id={item.id} value={item.id} checked={props.value?.id === item.id} readOnly />
            <Text bodySize="m">{item.label}</Text>
          </div>
        )
      })}
    </div>
  )
}
