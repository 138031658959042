import React from 'react'
import NoNotificationsIcon from '@/images/NoNotifications.svg'
import styles from '../styles/Notification.module.scss'
import Text from '@ingka/text'

export const Placeholder = () => {
  return (
    <div className={styles.NoNotifications}>
      <img src={NoNotificationsIcon} alt="no-notifications" />
      <Text headingSize="m">No notifications yet</Text>
      <Text bodySize="l">Your notifications will appear here soon</Text>
    </div>
  )
}
