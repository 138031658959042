import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { LANGUAGES_OPTIONS } from '@/constants/common'
import Button from '@ingka/button'
import GlobeIcon from '@ingka/ssr-icon/paths/globe'
import { Option } from '@/types/common'
import styles from '@/pages/Initiatives/styles/Initiatives.module.scss'
import Radio from '@ingka/radio-button'
import Text from '@ingka/text'
import { localeSlice } from '@/storage/locale'
import { LanguagesType } from '@/types/translations'
import { useOnClickOutside } from '@/components/hooks/outside'
import { Show } from '@/components/conditions/Show'

export const LanguageSelector = () => {
  const language = useSelector((store: RootState) => store.locale.language)
  const [opened, setOpened] = React.useState(false)

  const ref = React.useRef(null)

  useOnClickOutside(
    ref,
    () => {
      if (opened) setOpened(false)
    },
    opened
  )

  const value = LANGUAGES_OPTIONS.find(item => item.value === language)

  const handleChange = (id: string) => {
    store.dispatch(localeSlice.actions.setLanguage(id as LanguagesType))
    localStorage.setItem('language', id)
    setOpened(false)
  }

  return (
    <div ref={ref} className={styles.DetailsSelect}>
      <Button ssrIcon={GlobeIcon} iconOnly type="tertiary" onClick={() => setOpened(true)} />
      <Show when={opened}>
        <List list={LANGUAGES_OPTIONS} value={value} onChange={handleChange} />
      </Show>
    </div>
  )
}

const List: React.FC<{ list: Option[]; value?: Option; onChange: (value: string) => void }> = props => {
  return (
    <div className={styles.List} style={{ width: 200 }}>
      {props.list.map(item => {
        return (
          <div className={styles.Item} key={item.id} onClick={() => props.onChange(item.value)}>
            <Radio id={item.id} value={item.id} checked={props.value?.id === item.id} readOnly />
            <Text bodySize="m">{item.label}</Text>
          </div>
        )
      })}
    </div>
  )
}
