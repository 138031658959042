import { InitiativesListItemType } from '@/pages/Initiatives/types/initiatives'

type Handler = (data: InitiativesListItemType[]) => number

export const getChangeMakers: Handler = data => {
  // Pre-aggregated variant
  // -------
  // return data
  //   .reduce((result, current) => {
  //     return result + current.change_makers_count
  //   }, 0)
  //   .toString()

  return data.reduce((result, current) => {
    return result + (current.coworkers_count || 0) + (current.customers_count || 0)
  }, 0)
}

export const getPositivelyImpacted: Handler = data => {
  // Pre-aggregated variant
  // -------
  // const improveCount = data
  //   .filter(item => item.impact_type === IMPACT_TYPES_FY24.improve)
  //   .reduce((result, current) => (result += current.participants_count), 0)
  // const transformCount = data
  //   .filter(item => item.impact_type === IMPACT_TYPES_FY24.transform)
  //   .reduce((result, current) => (result += current.participants_count), 0)
  //
  // return (improveCount + transformCount).toString()

  return data.reduce((result, current) => {
    return result + (current.people_supported_count || 0)
  }, 0)
}

export const getHoursSpent: Handler = data => {
  return data.reduce((result, current) => {
    return result + (current.coworkers_count || 0) * (current.coworker_hours || 0)
  }, 0)
}

export const getBudgetSpent: Handler = data => {
  return data.reduce((result, current) => {
    return result + (current.spent || 0)
  }, 0)
}
